<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <h1>Chong Hua Hospital Human Resources:</h1>
            </div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            href="../../../../../assets/img/mas-rise/DACertificationCourseAnnouncement.jpg"
                            target="_blank"
                            class="pointHand"
                            src="../../../../../assets/img/mas-rise/DACertificationCourseAnnouncement.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            class="pointHand"
                            src="../../../../../assets/img/mas-rise/MicrosoftTeams-image (1) (1).png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            class="pointHand"
                            src="../../../../../assets/img/mas-rise/MicrosoftTeams-image (3) (1).png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            class="pointHand"
                            src="../../../../../assets/img/mas-rise/MicrosoftTeams-image (53).png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            class="pointHand"
                            src="../../../../../assets/img/mas-rise/MicrosoftTeams-image (2) (1).png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1nhF70K6JD2cgTsKM3JnEI4zgrkOjek13/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-6" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1fE0y_bybSlwy_-5mO825Pmc_RyRlvOvY/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
