<app-page-title1 pageTitle="Home Page Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3">
                <div class="form-group">
                    Intro title
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="introData.data.title"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    Intro Icon
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="introData.data.icon"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    Counter
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="introData.data.counter"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </div>
            </div>
            <div class="col-lg-1">
                <div class="form-group">
                    Sort
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="introData.data.sort"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <br />
                    <button class="btn btn-success" (click)="saveIntroData()">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12"><br /></div>
        </div>
        <div class="row">
            <div
                class="col-sm-6 col-md-3 col-lg-3"
                *ngFor="let x1 of getIntroDataValue"
            >
                <div class="counter-item">
                    <i class="{{ x1.attributes.icon }}"></i>
                    <h3 class="counter">{{ x1.attributes.counter }}</h3>
                    <p>{{ x1.attributes.title }}</p>
                    <span
                        (click)="deleteIntro(x1)"
                        class="pointHand"
                        style="color: red"
                        >Delete</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
