import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "dept-helper",
    templateUrl: "./dept-helper.component.html",
    styleUrls: ["./dept-helper.component.scss"],
})
export class DeptHelperComponent implements OnInit {
    @Input() specialization: string = "";
    @Input() reCeiver: string;
    @Input() site: string;
    @Input() custom: string;

    @Input() bigFont: boolean = false;
    specialty;
    constructor() {}

    ngOnInit(): void {
        if (this.reCeiver == "OB") {
            this.specialty = "Obstetrics & Gynecology";
        } else if (this.reCeiver == "GYN") {
            this.specialty = "Obstetrics & Gynecology";
        } else if (this.reCeiver == "SURA" || this.reCeiver == "SURP") {
            this.specialty = "Surgery";
        } else if (this.reCeiver == "MED") {
            this.specialty = "Internal Medicine";
        } else if (this.reCeiver == "FMED") {
            this.specialty = "Family and Community Medicine";
        } else if (this.reCeiver == "RAD") {
            this.specialty = "Radiology";
        } else if (this.reCeiver == "ORTH") {
            this.specialty = "Orthopaedics";
        } else if (this.reCeiver == "ANES") {
            this.specialty = "Anesthesia";
        } else if (this.reCeiver == "PED") {
            this.specialty = "Pediatrics";
        } else if (this.reCeiver == "PATH") {
            this.specialty = "Pathology";
        } else if (this.site == "C") {
            this.specialty = "Chong Hua Hospital (Cebu City)";
        } else if (this.site == "E") {
            this.specialty = "Chong Hua Medical Mall (Cebu City)";
        } else if (this.site == "M") {
            this.specialty = "Chong Hua Hospital Mandaue";
        } else if (this.reCeiver == "Ophthalmology") {
            this.specialty = "Ophthalmology";
        }
    }
}
