<app-page-title1 pageTitle="About CHHM Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Title
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Page Title"
                                        [(ngModel)]="addSlider.data.Title"
                                        [ngModelOptions]="{ standalone: true }"
                                        (keyup)="forJobTItleLink()"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Service Link
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Service Link"
                                        [(ngModel)]="addSlider.data.link"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                Content
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="addSlider.data.Details"
                                        [ngModelOptions]="{ standalone: true }"
                                    ></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    small Title
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Page Title"
                                        [(ngModel)]="addSlider.data.SmallTitle"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                small Content
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="addSlider.data.SmallData"
                                        [ngModelOptions]="{ standalone: true }"
                                    ></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                        Image:<br />
                                        <input
                                            type="file"
                                            name="files"
                                            accept="image/*"
                                            #file
                                            placeholder="Choose file"
                                            (change)="selectbigImg($event)"
                                            style="display: none"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-warning"
                                            (click)="file.click()"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                    <div class="col-lg-12">
                                        <span
                                            class="upload"
                                            *ngIf="progress > 0"
                                        >
                                            {{ progress }}%
                                        </span>
                                        <span class="upload" *ngIf="message">
                                            {{ message }}
                                        </span>
                                    </div>
                                    <div class="col-lg-12">
                                        <img
                                            src=""
                                            alt="Big Image"
                                            id="img_big"
                                        />

                                        <img
                                            [src]="url + imglinkBig"
                                            alt="Big Image"
                                            id="img_big"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                        Image small:<br />
                                        <input
                                            type="file"
                                            name="files"
                                            accept="image/*"
                                            #file2
                                            placeholder="Choose file"
                                            (change)="selectsmallImg($event)"
                                            style="display: none"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-warning"
                                            (click)="file2.click()"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                    <div class="col-lg-12">
                                        <span
                                            class="upload"
                                            *ngIf="progress > 0"
                                        >
                                            {{ progress }}%
                                        </span>
                                        <span class="upload" *ngIf="message">
                                            {{ message }}
                                        </span>
                                    </div>
                                    <div class="col-lg-12">
                                        <img
                                            src=""
                                            alt="Big Image"
                                            id="img_small"
                                        />

                                        <img
                                            [src]="
                                                url + addSlider.data.TitleImg
                                            "
                                            alt="Big Image"
                                            id="img_small"
                                        />
                                    </div>
                                    <div class="col-lg-12">
                                        <!--button
                                            type="button"
                                            class="btn btn-danger"
                                            (click)="removesmall()"
                                        >
                                            Remove
                                        </button-->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12"><br /><br /><br /></div>
                            <div class="col-lg-12" *ngIf="selectedId == ''">
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        (click)="startUpload()"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="selectedId != ''">
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        (click)="update()"
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="container-fluid">
                    <div class="row">
                        <div
                            class="col-lg-12 pointHand"
                            *ngFor="let x of serviceList"
                            (click)="Choose(x)"
                            [ngClass]="testsss(x.attributes.link)"
                        >
                            {{ x.attributes.link }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
