<app-page-title1 pageTitle="Promos & Packages Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4" style="top: 0">
                add Promos and Packages

                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Page title
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Page Title"
                                        [(ngModel)]="pageTitle"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12">
                                Description
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="description"
                                        [ngModelOptions]="{ standalone: true }"
                                    ></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                Expiry
                                <div class="form-group">
                                    <input
                                        type="text"
                                        placeholder="Datepicker"
                                        class="form-control"
                                        bsDatepicker
                                        [(ngModel)]="expiryDate"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-3">
                                        Large Image:
                                        <input
                                            type="file"
                                            name="files"
                                            accept="image/*"
                                            #file
                                            placeholder="Choose file"
                                            (change)="selectbigImg($event)"
                                            style="display: none"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-warning"
                                            (click)="file.click()"
                                        >
                                            Upload
                                        </button>
                                        <span
                                            class="upload"
                                            *ngIf="progress > 0"
                                        >
                                            {{ progress }}%
                                        </span>
                                        <span class="upload" *ngIf="message">
                                            {{ message }}
                                        </span>
                                    </div>
                                    <div class="col-lg-9">
                                        <img
                                            src=""
                                            alt="Big Image"
                                            id="img_big"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        (click)="startUpload()"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-area pt-100 pb-70" *ngFor="let x of promos">
                    <div class="container">
                        <button
                            type="button"
                            class="btn btn-danger"
                            (click)="delete(x.id, x)"
                        >
                            Delete
                        </button>
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <img
                                    [src]="
                                        url +
                                        '/uploads/' +
                                        'small_' +
                                        x.attributes.img_hash +
                                        x.attributes.img_ext
                                    "
                                    alt="About"
                                />
                            </div>

                            <div class="col-lg-6">
                                <div class="about-item about-right">
                                    <!--img src="assets/img/home-one/home-bg5.png" alt="About"-->
                                    <h2>{{ x.attributes.title }}</h2>
                                    <div
                                        [innerHtml]="x.attributes.description"
                                    ></div>
                                    <div>{{ x.attributes.expiry }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div
                class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
            >
                <br />
            </div>
        </div>
    </div>
</section>
