<div class="container-fluid">
    <div class="row">
        <div class="col-12"><br /></div>
        <div class="col">
            <a
                (click)="goto('home-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Home</a
            >
        </div>
        <div class="col">
            <a
                (click)="goto('residency-approver')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Residency Approver</a
            >
        </div>
    </div>
</div>

<div class="navbar-area sticky-top">
    <div class="main-na">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <div class="col"></div>
                        <div class="col"></div>

                        <div class="col"></div>
                        <div class="col"></div>
                    </ul>

                    <!--div class="nav-srh">
                        <div class="search-toggle">
                            <button class="search-icon icon-search"><i class="icofont-search-1"></i></button>
                            <button class="search-icon icon-close"><i class="icofont-close"></i></button>
                        </div>

                        <div class="search-area">
                            <form>
                                <input type="text" class="src-input" id="search-terms" placeholder="Search here..." />
                                <button type="submit" name="submit" value="Go" class="search-icon"><i
                                        class="icofont-search-1"></i></button>
                            </form>
                        </div>
                    </div-->
                </div>
            </nav>
        </div>
    </div>
</div>
