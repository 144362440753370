import { Component, NgZone, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { Title } from "@angular/platform-browser";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { SuccessComponent } from "./modal/success/success.component";
import { NgForm } from "@angular/forms";

@Component({
    selector: "app-careers",
    templateUrl: "./careers.component.html",
    styleUrls: ["./careers.component.scss"],
})
export class CareersComponent implements OnInit {
    bsModalRef?: BsModalRef;
    constructor(
        private activatedRoute: ActivatedRoute,
        private general: GeneralService,
        private sanitizer: DomSanitizer,
        private ngZone: NgZone,
        private router: Router,
        private titleService: Title,
        private modalService: BsModalService
    ) {}
    isRouteActivated: boolean = false;
    routeData;
    bacg;
    division;
    name;
    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const url = event.urlAfterRedirects;

                // Check if the URL matches the one you want to scroll to the div
                if (url.startsWith("/careers/")) {
                    // Scroll to the target div
                    const element = document.getElementById("target-div");
                    if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                    }
                }
            }
        });

        this.division = this.activatedRoute.snapshot.params.division;
        this.name = this.activatedRoute.snapshot.params.name;
        if (this.activatedRoute.snapshot.params.id == undefined) {
            this.isRouteActivated = false;
            this.routeData = this.activatedRoute.snapshot.params.id;
        }
        this.getCareer();
        this.getBanner();
    }
    bannerImage;
    getBanner() {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data.data);
                this.bannerImage = data.data;
                this.bannerImage.filter((x) => x.attributes.owner == "Careers");

                let asdasdasd;
                this.bannerImage = data.data;
                asdasdasd = this.bannerImage.filter(
                    (x) => x.attributes.owner == "Careers"
                );
                //////console.log(this.bannerImage);
                this.ngZone.run(() => {
                    this.bacg =
                        "https://strapi.chonghua.com.ph" +
                        asdasdasd[0].attributes.images;

                    //////console.log(this.bacg);
                });
            },
        });
    }
    //https://strapi.chonghua.com.ph/api/patient-educations?filters[approver_id1_status][$eq]=true&sort=page_title:ASC&id:DESC

    careerList;
    careerListFull;
    uniqueNames;
    getCareer() {
        let careerListDraft;
        let careerListFullDraft;
        this.general
            .get(
                "careers?filters[approver_id1_status][$eq]=true&pagination[pageSize]=99999&sort=job_title:ASC"
            )
            .subscribe({
                complete: () => {
                    this.careerList = [];
                    this.careerListFull = [];
                    careerListFullDraft.forEach((element) => {
                        element.attributes.contact =
                            this.sanitizer.bypassSecurityTrustHtml(
                                element.attributes.contact
                            );
                        element.attributes.qualification =
                            this.sanitizer.bypassSecurityTrustHtml(
                                element.attributes.qualification
                            );
                        this.careerList.push(element);
                        this.careerListFull.push(element);
                    });
                    this.uniqueNames = [];
                    for (let i = 0; i < this.careerListFull.length; i++) {
                        if (
                            this.uniqueNames.indexOf(
                                this.careerListFull[i].attributes.category
                            ) === -1
                        ) {
                            this.uniqueNames.push(
                                this.careerListFull[i].attributes.category
                            );
                        }
                    }
                    this.division =
                        this.activatedRoute.snapshot.params.division;
                    this.name = this.activatedRoute.snapshot.params.name;
                    if (this.division == "" || this.division == undefined) {
                        this.change("Ancillary Services");
                        this.colorBG("Ancillary Services");
                    } else {
                        this.change(this.division);
                        this.colorBG(this.division);
                        //https://strapi.chonghua.com.ph/api/
                        this.general
                            .get(
                                "careers?filters[link_title][$eq]=" + this.name
                            )
                            .subscribe({
                                complete: () => {},
                                error: (error) => {},
                                next: (x: any) => {
                                    this.titleService.setTitle(
                                        "Chong Hua Hospital - " +
                                            x.data[0].attributes.job_title
                                    );
                                    this.selectedData =
                                        x.data[0].attributes.link_title;
                                    this.jobTitle1 =
                                        x.data[0].attributes.job_title;
                                    this.jobTitleLink =
                                        x.data[0].attributes.link_title;
                                    this.category =
                                        x.data[0].attributes.category;
                                    this.type = x.data[0].attributes.type;
                                    this.qualifications =
                                        x.data[0].attributes.qualification;
                                    this.contact = x.data[0].attributes.contact;
                                    this.short_Desc =
                                        x.data[0].attributes.short_Desc;
                                },
                            });
                    }
                    //console.log(this.uniqueNames.sort());
                    this.uniqueNames = this.uniqueNames.sort();
                },
                error: (error) => {},
                next: (data: any) => {
                    //////console.log(data.data);

                    careerListDraft = data.data;
                    careerListFullDraft = data.data;
                    //////console.log(this.careerList);
                },
            });
    }
    jobTitle1 = "";
    jobTitleLink;
    category;
    type;
    qualifications;
    contact;
    short_Desc;
    selected;
    hsselected = false;
    change(data) {
        this.selected = data;
        console.log(data);

        if (data == "All") {
            this.careerList = [];
            this.careerList = this.careerListFull;
            //////console.log(this.careerList);
        } else {
            this.careerList = [];
            this.careerListFull.forEach((el) => {
                if (el.attributes.category == data) {
                    this.careerList.push(el);
                    this.showJob(el);
                }
            });
        }
    }
    selectedData;
    showJob(x) {
        console.log("!!!!");
        this.hsselected = true;
        this.titleService.setTitle(
            "Chong Hua Hospital - " + x.attributes.job_title
        );
        this.router.navigate([
            "/careers/" + x.attributes.category + "/" + x.attributes.link_title,
        ]);
        this.selectedData = x.attributes.link_title;
        this.jobTitle1 = x.attributes.job_title;
        this.jobTitleLink = x.attributes.link_title;
        this.category = x.attributes.category;
        this.type = x.attributes.type;
        this.qualifications = x.attributes.qualification;
        this.contact = x.attributes.contact;
        this.short_Desc = x.attributes.short_Desc;
    }
    colorBG(x) {
        if (x == this.selected) {
            return "underline";
        }
    }
    color(x) {
        if (x == this.selectedData) {
            return "isSelected";
        }
    }
    selectedFile: File | null = null;
    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.selectedFile = input.files[0];
        }
    }
    newFileName: string = "hehehe";
    applicantname = "";
    applicantbday;
    isLoading: boolean = false;
    lastSegment;
    onSubmit(form: NgForm) {
        // Alternatively, using the Router
        const url = this.router.url;
        const segments = url.split("/");
        this.lastSegment = segments[segments.length - 1];
        console.log(this.lastSegment);

        if (this.selectedFile) {
            const timestamp = new Date().toISOString();
            this.isLoading = true;
            this.newFileName =
                this.lastSegment +
                " - " +
                this.applicantname +
                " - " +
                timestamp;
            const formData = new FormData();
            const fileExtension = this.selectedFile.name.split(".").pop();
            const newFileNameWithExtension = this.newFileName
                ? `${this.newFileName}.${fileExtension}`
                : this.selectedFile.name;
            const newFileNameWithoutExtension = this.newFileName;
            formData.append("cv", this.selectedFile);
            formData.append("newFileName", newFileNameWithoutExtension);
            formData.append("position", this.formatString(this.lastSegment));
            this.general.postResume(formData).subscribe({
                complete: () => {
                    form.resetForm();
                    this.isLoading = false;
                    const initialState: ModalOptions = {
                        initialState: {
                            siteFrom: "c",
                        },
                    };
                    this.bsModalRef = this.modalService.show(
                        SuccessComponent,
                        initialState
                    );
                    this.bsModalRef.setClass("modal-md");
                    this.bsModalRef.content.closeBtnName = "Close";
                    this.bsModalRef.onHide.subscribe((reason: string | any) => {
                        console.log(reason);
                    });
                },
                error: (error) => {
                    this.isLoading = false;
                    console.error("Upload failed", error);
                },
                next: (data: any) => {
                    //console.log(data);
                },
            });
        }
    }
    formatString(str: string): string {
        if (!str) {
            return "";
        }
        return str
            .split("-") // Split the string by hyphens
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(" "); // Join the words with a space in between
    }
    isSuccess = false;
    handleSuccess(e) {
        this.isSuccess = true;
    }
}
