import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-irb",
    templateUrl: "./irb.component.html",
    styleUrls: ["./irb.component.scss"],
})
export class IrbComponent implements OnInit {
    constructor() {}
    clickedData = "sop";
    ngOnInit(): void {}
    show(data) {
        this.clickedData = data;
        console.log("clickedData", this.clickedData);
    }
}
