import {
    Component,
    HostListener,
    OnInit,
    NgZone,
    ElementRef,
} from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "src/environments/environment";
import { ScreenSizeService } from "./services/screen-size/screen-size.service";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit {
    location: any = "";
    routerSubscription: any;
    isDesktop: boolean;
    isPortrait: boolean;
    constructor(
        private router: Router,
        private update: SwUpdate,
        private screensizeService: ScreenSizeService
    ) {
        this.screensizeService.isDesktopView().subscribe((isDesktop) => {
            this.isDesktop = isDesktop;
        });
        this.screensizeService.isPortraitView().subscribe((isPortrait) => {
            this.isPortrait = isPortrait;
        });
        ////////console.log("constructor");
        const mySentence = "EMERITO D. CALDERON JR., M.D.";
        const words = mySentence.split(" ");
        let name = "";
        words.forEach((element: any) => {
            if (element.length > 2) {
                if (element == "JR.," || element == "M.D.") {
                } else {
                    //console.log(element);
                }
            }
            name += element + " ";
        });
        //console.log(name);

        //console.log(words);

        words.join(" ");
        //this.updateClient();
        this.initializeApp();
    }
    updateClient() {
        /* this.update.available.subscribe((event) => {
            ////console.log(event);
            this.update.activateUpdate().then(() => {
                //location.reload()
            });
        });*/
        //////console.log("updateClient");
        ////console.log(this.update.checkForUpdate());
        if (this.update.checkForUpdate()) {
            //////console.log("ajsdhlaskjdhlasd");

            this.update.activateUpdate().then(() => {
                document.location.reload();
            });
        }
    }
    ngOnInit() {
        //////console.log("test 1");
        ////console.log("App Component");

        console.log(environment.API_URL);

        this.recallJsFuntions();
    }
    initializeApp() {
        //////console.log("initializeApp");

        this.screensizeService.onResize(screen.width);
        if (screen.width > screen.height) {
            this.screensizeService.onPortrait(90);
        } else {
            this.screensizeService.onPortrait(0);
        }
    }
    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                $(".loader").fadeIn("slow");
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript("../assets/js/custom.js");
                $(".loader").fadeOut("slow");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
    @HostListener("window:orientationchange", ["$event"])
    @HostListener("window:resize", ["$event"])
    private onResize(event) {
        //////console.log("onResize - app component");

        this.screensizeService.onPortrait(window.orientation);
        /*if (screen.width > screen.height) {
      this.screensizeService.onPortrait(90);
    } else {
      this.screensizeService.onPortrait(0);
    }*/
        this.screensizeService.onResize(event.target.innerWidth);
    }
}
