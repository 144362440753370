import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-admission-procedure",
    templateUrl: "./admission-procedure.component.html",
    styleUrls: ["./admission-procedure.component.scss"],
})
export class AdmissionProcedureComponent implements OnInit {
    constructor(
        private genServ: GeneralService,
        private sanitizer: DomSanitizer
    ) {}
    description;
    id;
    ngOnInit(): void {
        this.get();
    }
    get() {
        this.genServ.get("admission-procedures").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.id = data.data[0].id;
                this.description = this.sanitizer.bypassSecurityTrustHtml(
                    data.data[0].attributes.column1
                );
            },
        });
    }
}
