<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>
                    2nd Medical Ancillary Services Division Postgraduate Course
                </h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Whats New</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/events/2ndPostGrad.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <!--img src="assets/img/home-one/home-bg5.png" alt="About"-->
                    <h2>
                        2nd Medical Ancillary Services Division Postgraduate
                        Course
                    </h2>
                    <div>
                        Each year, Chong Hua Hospital observes Medical Ancillary
                        Services Week during the second week of August. In order
                        to celebrate and recognize the crucial roles that
                        medical ancillary professionals play in improving
                        patient care, cultivating a respectful and appreciative
                        culture within the healthcare community, and inspiring
                        the next generation of healthcare leaders, the 2024
                        theme,
                        <b
                            >MAS SHINE: Shine, Honor, Inspire, Nature, and
                            Empower the Next Generation of Healthcare Leaders</b
                        >, has been chosen. <br /><br />
                        The one-week-long celebration includes another round of
                        continuing professional development. Join us for the 2nd
                        Medical Ancillary Services Postgraduate Course, themed

                        <b
                            >"Building Best Practices for Health: Empowering
                            Healthcare Professionals to Promote Optimal Health
                            Outcomes."</b
                        >
                        <br /><br />
                        Discover cutting-edge practices, gain valuable insights,
                        and connect with industry leaders dedicated to advancing
                        healthcare. This course is an excellent opportunity for
                        professional development, networking, and fostering a
                        culture of excellence in patient care.
                        <br /><br />
                        Don’t miss out on this chance to enhance your skills and
                        contribute to the future of healthcare. Reserve your
                        spot today and be part of a transformative experience!
                    </div>
                    <br /><br />
                </div>
                <table class="table">
                    <tbody>
                        <tr>
                            <td>Program</td>
                            <td>
                                <a
                                    href="https://drive.google.com/file/d/14Vn0u5Ey4Via6ol22SUks3xxGbUmyDZ3/view?usp=sharing"
                                    target="_BLANK"
                                    >Link</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>Registration</td>
                            <td>
                                <a
                                    href="https://forms.gle/yxECGbtCvSMc23zH8"
                                    target="_BLANK"
                                    >Link</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>Pre-Test</td>
                            <td>
                                <a
                                    href="https://forms.gle/arDBMHok7vzDCuvFA"
                                    target="_BLANK"
                                    >Link</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>Post-Test</td>
                            <td>
                                <a
                                    href="https://forms.gle/Lt4CRHY6HUAKUgwR6"
                                    target="_BLANK"
                                    >Link</a
                                >
                            </td>
                        </tr>

                        <tr>
                            <td>Evaluation</td>
                            <td>
                                <a
                                    href="https://forms.gle/MfXeYwnny5jmPrzA9"
                                    target="_BLANK"
                                    >Link</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>Sponsors</td>
                            <td>
                                <a
                                    [routerLink]="[
                                        '/medical-ancillary-services/sponsors'
                                    ]"
                                    >Link</a
                                >
                            </td>
                        </tr>

                        <!--tr>
                            <td>Post Test</td>
                            <td>
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSeghAbhxrElA5Gavk5t5QkKSrdjRQr55XTvl1fypxojVmwVOQ/viewform?usp=sharing"
                                    target="_BLANK"
                                    >Link</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>Evaluation Day 1</td>
                            <td>
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLScNY8AOIZ1lmH9P7aWlXHJQnKiUahMBrEeBWKmAkpbnl8Hm7g/viewform?usp=sharing"
                                    target="_BLANK"
                                    >Link</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>Evaluation Day 2</td>
                            <td>
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSeyR1aiVwY2FRPi5yKTsoRD2DefXx235FYApBBABAXSs6ByVg/viewform?usp=sharing"
                                    target="_BLANK"
                                    >Link</a
                                >
                            </td>
                        </tr-->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!--div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/FAS LOGO.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="col-lg-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="about-item">
                                    <div class="about-lef">
                                        <img
                                            src="../../../../../assets/img/mas-rise/FAS Brochures QR Code.jpg"
                                            alt="About"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="about-item">
                                    <div class="about-lef">
                                        <img
                                            src="../../../../../assets/img/mas-rise/FAS Instrumens QR Code.jpg"
                                            alt="About"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1wzL960WwymUyI0HGjCCCbq0EgZOXEjSq/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1k9k2fkdFdId7m812qrHi9-QRxcWyx7Zz/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><br /></div>
        </div>
    </div>
</div>
