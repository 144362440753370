import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";
@Component({
    selector: "app-prices",
    templateUrl: "./prices.component.html",
    styleUrls: ["./prices.component.scss"],
})
export class PricesComponent implements OnInit {
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService
    ) {}
    img: any;
    ngOnInit(): void {
        this.getSlider();
    }
    url = "https://strapi.chonghua.com.ph";
    selected;
    callme(data) {
        this.selected = data;
        this.img = this.url + data;
    }
    promos;
    imgCount;
    getSlider() {
        let xyz = [];
        function compareStrings(a, b) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase();
            b = b.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
        }
        this.general.get("price-lists?sort=title:ASC").subscribe({
            complete: () => {
                this.promos = [];
                this.promos = xyz.sort(function (a, b) {
                    return compareStrings(a.link, b.link);
                });

                this.callme(this.promos[0].image);
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);

                this.promos = data.data;
                this.promos.forEach((element) => {
                    xyz.push(element.attributes);
                });
            },
        });
    }
    getType(d) {
        // //console.log(d);
    }
}
