import { Component, NgZone, OnInit } from "@angular/core";
import * as EmailValidator from "email-validator";

import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { GeneralService } from "src/app/services/general.service";
import { AfterFeedbackComponent } from "src/app/components/modal/after-feedback/after-feedback.component";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
    selector: "app-contact-us",
    templateUrl: "./contact-us.component.html",
    styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
    name;
    phone;
    subject;
    eemail;
    message;
    email = {
        subject: "",
        email: "",
        message: "",
        key: "Dbiqw@drJjpbpt2dqELp8P7",
    };
    error = "";
    isEmail = true;
    bsModalRef?: BsModalRef;
    constructor(
        private genServ: GeneralService,
        private general: GeneralService,
        private modalService: BsModalService,
        private sanitizer: DomSanitizer,
        private ngZone: NgZone
    ) {}
    nameError;
    emailError;
    phoneError;
    subjectError;
    messageError;
    send() {
        if (
            this.name == "" ||
            this.name == null ||
            this.name == "null" ||
            this.name == undefined ||
            this.name == "undefined"
        ) {
            this.nameError = true;
        } else {
            this.nameError = false;
        }
        if (
            this.eemail == "" ||
            this.eemail == null ||
            this.eemail == "null" ||
            this.eemail == undefined ||
            this.eemail == "undefined"
        ) {
            this.emailError = true;
        } else {
            this.emailError = false;
        }
        if (
            this.phone == "" ||
            this.phone == null ||
            this.phone == "null" ||
            this.phone == undefined ||
            this.phone == "undefined"
        ) {
            this.phoneError = true;
        } else {
            this.phoneError = false;
        }
        if (
            this.subject == "" ||
            this.subject == null ||
            this.subject == "null" ||
            this.subject == undefined ||
            this.subject == "undefined"
        ) {
            this.subjectError = true;
        } else {
            this.subjectError = false;
        }
        if (
            this.message == "" ||
            this.message == null ||
            this.message == "null" ||
            this.message == undefined ||
            this.message == "undefined"
        ) {
            this.messageError = true;
        } else {
            this.messageError = false;
        }
        if (
            this.nameError == false &&
            this.subjectError == false &&
            this.emailError == false &&
            this.phoneError == false &&
            this.messageError == false
        ) {
            this.error = "";
            EmailValidator.validate(this.eemail);
            this.isEmail = EmailValidator.validate(this.eemail);

            //this.viewPending();
            if (this.isEmail) {
                // this.sendFeedback("jessiejayr@gmail.com");
                this.sendFeedback("info@chonghua.com.ph");
                this.viewPending();
            } else {
                this.error = "Email is Invalid";
            }
        } else {
        }
    }
    sendFeedback(email) {
        this.email.email = email;
        this.email.subject = this.subject;
        let nn =
            "Name : " +
            this.name +
            "<br />Phone : " +
            this.phone +
            "<br />Email : " +
            this.eemail +
            "<br /><br />";
        this.email.message = nn + this.message;
        this.genServ.sendEmail(this.email).subscribe({
            complete: () => {
                this.name = "";
                this.eemail = "";
                this.subject = "";
                this.message = "";
                this.phone = "";
                console.log("deleteall");
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });
    }

    viewPending() {
        const initialState: ModalOptions = {
            initialState: {
                site: "",
            },
        };
        this.bsModalRef = this.modalService.show(
            AfterFeedbackComponent,
            initialState
        );
        this.bsModalRef.setClass("modal-md");
        this.bsModalRef.content.closeBtnName = "Close";
        //this.bsModalRef.content.event.subscribe((res) => {});
    }
    ngOnInit(): void {
        this.getBanner();
    }
    isSuccess: boolean = false;
    handleSuccess(e) {
        this.isSuccess = true;
    }
    bannerImage;
    bacg;
    getBanner() {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.bannerImage = data.data;
                let asdasdasd;
                this.bannerImage = data.data;
                asdasdasd = this.bannerImage.filter(
                    (x) => x.attributes.owner == "Contact Us"
                );
                this.ngZone.run(() => {
                    this.bacg =
                        "https://strapi.chonghua.com.ph" +
                        asdasdasd[0].attributes.images;
                });
            },
        });
    }
}
