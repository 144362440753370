<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Promos & Packages</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Whats New</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70" *ngFor="let x of promos">
    <div class="container">
        <div class="row align-items-center">
            <ng-container *ngIf="x.attributes.images != ''">
                <div class="col-lg-6">
                    <div class="about-item">
                        <div class="about-left">
                            <img
                                [src]="url + x.attributes.images"
                                alt="About"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="about-item about-right">
                        <h2>{{ x.attributes.title }}</h2>
                        <div [innerHtml]="x.attributes.description"></div>

                        <a
                            (click)="downloadme(x.attributes.images)"
                            class="cursor:pointer"
                            >Download</a
                        >
                    </div>
                </div></ng-container
            >
            <ng-container *ngIf="x.attributes.images == ''">
                <div class="col-lg-8 offset-lg-2">
                    <div class="about-item about-right">
                        <h2>{{ x.attributes.title }}</h2>
                        <div [innerHtml]="x.attributes.description"></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><br /></div>
        </div>
    </div>
</div>
