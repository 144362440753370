import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-ecueditor",
    templateUrl: "./ecueditor.component.html",
    styleUrls: ["./ecueditor.component.scss"],
})
export class EcueditorComponent implements OnInit {
    plan;
    details;
    specialexam;
    progress;
    message;
    toUploadBigFile;
    formData;
    isUploadStatus;
    contact = "";
    name = "";
    img_idBig;
    img_hashBig;
    addDoc = {
        data: {
            plan: "",
            details: "",
            specialexam: "",
            contact: "",
            name: "",
        },
    };
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.getSlider();
    }
    promos;

    getSlider() {
        this.general.get("ecu-catalogs").subscribe({
            complete: () => {
                console.log(this.promos);
            },
            error: (error) => {},
            next: (data: any) => {
                this.promos = data.data;
            },
        });
    }

    id;
    addFile() {
        this.addDoc.data.plan = this.plan;
        this.addDoc.data.name = this.name;
        this.addDoc.data.details = this.details;
        this.addDoc.data.specialexam = this.specialexam;
        this.addDoc.data.contact = this.contact;
        /*this.general.put("ecu-catalogs", this.addDoc, this.id).subscribe({
            complete: () => {},
            error: (error) => {
                console.log(error);
            },
            next: (data: any) => {
                console.log(data);
            },
        });*/
        this.general.add("ecu-catalogs", this.addDoc).subscribe({
            complete: () => {
                this.getSlider();
            },
            error: (error) => {
                console.log(error);
            },
            next: (data: any) => {
                console.log(data);
            },
        });
        //add
    }
    isModify: boolean = false;
    modify(x) {
        this.isModify = true;
        this.plan = x.attributes.plan;
        this.name = x.attributes.name;
        this.details = x.attributes.details;
        this.specialexam = x.attributes.specialexam;
        this.contact = x.attributes.contact;
        this.id = x.id;
    }
    clear() {
        this.isModify = false;
        this.plan = "";
        this.name = "";
        this.details = "";
        this.specialexam = "";
        this.contact = "";
        this.id = "";
    }
    update() {
        this.addDoc.data.name = this.name;
        this.addDoc.data.plan = this.plan;
        this.addDoc.data.details = this.details;
        this.addDoc.data.specialexam = this.specialexam;
        this.addDoc.data.contact = this.contact;
        console.log(this.addDoc, this.id);

        this.general.put("ecu-catalogs", this.addDoc, this.id).subscribe({
            complete: () => {
                this.clear();
                this.getSlider();
            },
            error: (error) => {
                console.log(error);
            },
            next: (data: any) => {
                console.log(data);
            },
        });
    }
}
