// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiRouterUrl: "https://strapi.chonghua.com.ph/",
    oldUrl: "http://10.151.12.120:7223/api/",
    //API_URL: window["env"]["API_URL"] + "/",
    API_URL: "https://api.chonghua.com.ph/",
    API_URL1: "https://dev-api02.chonghua.com.ph/",
    api01: "https://api01.chonghua.com.ph",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
