<ul class="dropdown-menu">
    <li class="nav-item" *ngFor="let parent of Parents">
        <ng-container *ngIf="parent.attributes.haschild"
            ><a
                class="nav-link pointHand testsubmeter"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                style="font-size: 18px !important"
                >{{ parent.attributes.name }}

                <app-grandchild-nav
                    [ParentId]="parent.id"
                ></app-grandchild-nav> </a></ng-container
        ><ng-container *ngIf="!parent.attributes.haschild"
            ><a
                routerLink="/irb/{{ parent.attributes.link }}"
                class="nav-link pointHand testsubmeter"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                style="font-size: 18px !important"
                >{{ parent.attributes.name }}</a
            ></ng-container
        >
    </li>
</ul>
