import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-success",
    templateUrl: "./success.component.html",
    styleUrls: ["./success.component.scss"],
})
export class SuccessComponent implements OnInit {
    constructor(private modalService: BsModalService, private router: Router) {}

    ngOnInit(): void {}
    onClick() {
        this.modalService.hide();
    }
}
