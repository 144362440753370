import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class PromosService {
    constructor(private http: HttpClient) {}

    addPromo(data) {
        //console.log(data);

        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + "api/promos-packages";
        return this.http.post(url, data, options);
    }
    updatePromo(data, id) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: false };
        const url = environment.apiRouterUrl + "api/promos-packages/" + id;
        return this.http.put(url, data, options);
    }
    deletePormo(id) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + "api/promos-packages/" + id;
        return this.http.delete(url, options);
    }

    delete(collection, id) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + "api/" + collection + "/" + id;
        return this.http.delete(url, options);
    }

    getPromoView() {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url = environment.apiRouterUrl + "api/promos-packages";
        return this.http.get(url, options);
    }
    getPromoEditor() {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url =
            environment.apiRouterUrl +
            "api/promos-packages?filters[approver_id1_status][$eq]=false";
        return this.http.get(url, options);
    }
    getPromo() {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url =
            environment.apiRouterUrl +
            "api/promos-packages?filters[approver_id1_status][$eq]=true&sort=id:DESC";
        return this.http.get(url, options);
    }
    getPromoDetails(id) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url =
            environment.apiRouterUrl +
            "api/promos-packages?filters[approver_id1_status][$eq]=true&filters[img_id][$eq]=" +
            id +
            "&sort=id:DESC";
        return this.http.get(url, options);
    }
    getCount(collection, id) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url =
            environment.apiRouterUrl +
            "api/" +
            collection +
            "?filters[approver_id1][$eq]=" +
            id +
            "&filters[approver_id1_status][$eq]=false";
        return this.http.get(url, options);
    }
}
