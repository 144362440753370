import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";

@Component({
    selector: "app-admin-nav",
    templateUrl: "./admin-nav.component.html",
    styleUrls: ["./admin-nav.component.scss"],
})
export class AdminNavComponent implements OnInit {
    constructor(
        private funcServ: FunctionsService,
        private route: Router,
        private auth: AuthService
    ) {}
    isApprover;
    userType;
    ngOnInit(): void {
        let userData = this.auth.getUserData();
        this.userType = userData[0].user.type;
        this.isApprover = localStorage.getItem("is_approver");
    }
    logout() {
        this.funcServ.logout();
    }
    goto(to) {
        //console.log(to);

        this.route.navigate(["/chhadmin/" + to]);
    }
}
