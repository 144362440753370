import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { GeneralService } from "src/app/services/general.service";
import { ScreenSizeService } from "src/app/services/screen-size/screen-size.service";
import { takeUntil } from "rxjs/operators";
import { BsDropdownConfig } from "ngx-bootstrap/dropdown";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { DomSanitizer } from "@angular/platform-browser";
import { PrivacyComponent } from "../../modal/privacy/privacy.component";
@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
    providers: [
        {
            provide: BsDropdownConfig,
            useValue: { isAnimated: true, autoClose: true },
        },
    ],
})
export class HomeOneComponent implements OnInit {
    private ngUnsubscribe = new Subject();
    isNotification: boolean;
    isPortrait: boolean;
    isDesktop: boolean;
    constructor(
        private general: GeneralService,
        private screensizeService: ScreenSizeService,
        public router: Router,
        private sanitizer: DomSanitizer,
        private modalService: BsModalService
    ) {
        this.screensizeService
            .isPortraitView()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isPortrait) => {
                if (this.isPortrait && !isPortrait) {
                    this.ngOnInit();
                }
                if (this.isPortrait != undefined && isPortrait) {
                    this.ngOnInit();
                }
                this.isPortrait = isPortrait;
            });
        this.screensizeService
            .isDesktopView()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isDesktop) => {
                //////console.log(isDesktop);

                //////console.log("RELOAD - HOME");
                if (this.isDesktop && !isDesktop) {
                    window.location.reload();
                }
                this.isDesktop = isDesktop;
            });
        ////console.log(this.isDesktop);
    }
    ourServices = [
        {
            name: "Expert Doctor",
            photoName: "expert-doctor.png",
            delay: ".3s",
            link: "/doctors",
        },
        {
            name: "Laboratory Services",
            photoName: "laboratory-services.png",
            delay: ".5s",
            link: "/services-details/laboratory-services",
        },
        {
            name: "Renal Unit",
            photoName: "renal.png",
            delay: ".9s",
            link: "/services-details/renal-unit",
        },
        {
            name: "Radiology",
            photoName: "radiology.png",
            delay: ".7s",
            link: "/services-details/radiology",
        },

        {
            name: "Emergency/Acute Care",
            photoName: "emergency-acute-care.png",
            delay: ".9s",
            link: "/services-details/emergency-department",
        },
        {
            name: "Heart Institute",
            photoName: "heart-institute.png",
            delay: ".7s",
            link: "/services-details/heart-institute",
        },
        //    { name: "Cancer Center", photoName: "cancer.png", delay: ".5s" },
        {
            name: "Executive Health Unit",
            photoName: "executive-health.png",
            delay: ".3s",
            link: "/services-details/executive-health-unit",
        },
        {
            name: "More",
            photoName: "more.png",
            delay: ".3s",
            link: "/list-of-services",
        },
    ];
    url = "https://strapi.chonghua.com.ph";
    ngOnInit(): void {
        this.getSlider();
        this.getDoctors();
        this.getAbout();
        this.getIntroData();
        this.openModalError();
    }
    promos;
    imgCount;
    getSlider() {
        this.general.get("sliders").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //////console.log(data.data);

                this.promos = data.data;
                this.imgCount = data.meta.pagination.total + 1;
            },
        });
    }
    doctorsList = [];
    doctorsListFull;
    getDoctors() {
        let arr = [];
        this.general.getDoctorsDirectory().subscribe({
            complete: () => {
                while (this.doctorsList.length < 6) {
                    ////console.log("while");

                    this.doctorsList.push(
                        this.doctorsListFull[
                            Math.floor(
                                Math.random() * this.doctorsListFull.length
                            )
                        ]
                    );
                }
                ////console.log(this.doctorsList);
            },
            error: (error) => {},
            next: (data: any) => {
                //////console.log(data);

                this.doctorsListFull = data;
            },
        });
        function getDistinctDate(dates) {
            let lookup = {};
            for (let i = 0; i < dates.length; i++) {
                let jsontext = `{"year" : ${dates[i].deptCode}}`;
                lookup[jsontext] = true;
            }
            return Object.keys(lookup);
        }
    }
    redirect(x) {
        ////console.log(x);

        localStorage.setItem("selectedDoctor", JSON.stringify(x));
        this.router.navigate(["/doctors/" + x.drCode]);
    }
    addDoc = {
        data: {
            title: "",
            aboutData: null,
            aboutImage: "",
            aboutImage_hash: "",
            aboutImage_id: null,
            visionMissionData: null,
            visionMissionImage: "",
            visionMissionImage_hash: "",
            visionMissionImage_id: null,
        },
    };
    abouturl = "https://strapi.chonghua.com.ph";
    getAbout() {
        this.general.get("about-uses").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);

                this.addDoc.data.title = data.data[0].attributes.title;

                this.addDoc.data.aboutData =
                    this.sanitizer.bypassSecurityTrustHtml(
                        data.data[0].attributes.aboutData
                    );
                this.addDoc.data.visionMissionData =
                    this.sanitizer.bypassSecurityTrustHtml(
                        data.data[0].attributes.visionMissionData
                    );
                this.abouturl =
                    this.abouturl + data.data[0].attributes.aboutImage;
                this.addDoc.data.aboutImage_id =
                    data.data[0].attributes.aboutImage_id;

                this.addDoc.data.visionMissionImage_id =
                    data.data[0].attributes.visionMissionImage_id;
                this.addDoc.data.visionMissionImage_hash =
                    data.data[0].attributes.visionMissionImage_hash;
                this.addDoc.data.visionMissionImage =
                    data.data[0].attributes.visionMissionImage;

                ////console.log(this.addDoc);
            },
        });
    }
    getIntroDataValue = "";
    getIntroData() {
        let getIntroDataValue;
        this.general.get("home-intros?sort=sort:ASC").subscribe({
            complete: () => {
                this.getIntroDataValue = getIntroDataValue;
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);

                getIntroDataValue = data.data;
            },
        });
    }
    bsModalRef?: BsModalRef;
    openModalError() {
        const initialState: ModalOptions = {
            initialState: {
                title: "",
            },
            backdrop: true,
            ignoreBackdropClick: false,
        };
        this.bsModalRef = this.modalService.show(
            PrivacyComponent,
            initialState
        );
        this.bsModalRef.content.closeBtnName = "Close";
    }
}
