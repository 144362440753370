<app-page-title1 pageTitle="HMO EDITOR"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-6">
                                ACCREDITED HMOs
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="right"
                                        [ngModelOptions]="{ standalone: true }"
                                    ></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                INSURANCE & THIRD PARTY PAYORS
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="left"
                                        [ngModelOptions]="{ standalone: true }"
                                    >
                                    </ckeditor>
                                </div>
                            </div>

                            <div class="col-lg-12"><br /></div>

                            <div class="col-lg-12"><br /><br /><br /></div>
                            <div class="col-lg-12">
                                <button
                                    *ngIf="!isModify"
                                    type="button"
                                    class="btn btn-success"
                                    (click)="addFile()"
                                >
                                    Add
                                </button>
                                <button
                                    *ngIf="isModify"
                                    type="button"
                                    class="btn btn-warning"
                                    (click)="clear()"
                                >
                                    Clear
                                </button>
                                <button
                                    *ngIf="isModify"
                                    type="button"
                                    class="btn btn-success"
                                    (click)="update()"
                                >
                                    Update
                                </button>
                            </div>

                            <div class="col-lg-12">
                                <div class="text-center"></div>
                            </div>
                            <div class="col-lg-12">
                                <div *ngFor="let x of promos">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <p
                                                    [innerHTML]="
                                                        x.attributes.right
                                                    "
                                                ></p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p
                                                    [innerHTML]="
                                                        x.attributes.left
                                                    "
                                                ></p>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="pointHand" (click)="modify(x)">
                                        MODIFY
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
