import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { AuthConstants } from "../config/auth-constants";
@Injectable({
    providedIn: "root",
})
export class IndexGuard implements CanActivate {
    constructor(public auth: AuthService, public router: Router) {}
    userType;
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return new Promise((resolve) => {
            ////console.log("IndexGuard");

            let res = this.auth.get(AuthConstants.AUTH);
            if (res) {
                let userData = this.auth.getUserData();
                this.userType = userData[0].user.type;

                resolve(true);
            } else {
                this.router.navigate(["/login"]);
                resolve(false);
            }
        });
    }
}
