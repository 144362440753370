import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TermsConditionComponent } from "./components/pages/terms-condition/terms-condition.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { SignupComponent } from "./components/pages/signup/signup.component";
import { TestimonialsComponent } from "./components/website/patient-visitors-guide/testimonials/testimonials.component";
import { DepartmentsComponent } from "./components/pages/departments/departments.component";
import { AppointmentComponent } from "./components/pages/appointment/appointment.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ServicesComponent } from "./components/pages/services/services.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { DoctorComponent } from "./components/pages/doctor/doctor.component";
import { DoctorDetailsComponent } from "./components/pages/doctor-details/doctor-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { AboutChhcComponent } from "./components/pages/about-chhc/about-chhc.component";
import { AboutChhmComponent } from "./components/pages/about-chhm/about-chhm.component";

import { AdmissionProcedureComponent } from "./components/website/patient-visitors-guide/admission-procedure/admission-procedure.component";
import { DischargeProcedureComponent } from "./components/website/patient-visitors-guide/discharge-procedure/discharge-procedure.component";
import { AdvanceDirectivesComponent } from "./components/website/patient-visitors-guide/advance-directives/advance-directives.component";
import { MedicalRecordsComponent } from "./components/website/patient-visitors-guide/medical-records/medical-records.component";
import { PatientRightsResponsibilitiesComponent } from "./components/website/patient-visitors-guide/patient-rights-responsibilities/patient-rights-responsibilities.component";
import { PharmacyInfoComponent } from "./components/website/patient-visitors-guide/pharmacy-info/pharmacy-info.component";
import { PricesComponent } from "./components/website/patient-visitors-guide/prices/prices.component";
import { OthersComponent } from "./components/website/patient-visitors-guide/others/others.component";
import { PromosPackagesComponent } from "./components/website/whats-new/promos-packages/promos-packages.component";
//import { HomeComponent } from "./components/admin/home/home.component";
import { IndexGuard } from "./guards/index.guard";
//import { PromosPackagesEditorComponent } from "./components/admin/promos-packages-editor/promos-packages-editor.component";
//import { ListsComponent } from "./components/admin/approver/lists/lists.component";
import { UserDataResolver } from "./resolvers/user-data.resolver";
//import { HomeEditorComponent } from "./components/admin/home-editor/home-editor.component";
import { ResidencyTrainingComponent } from "./components/pages/residency-training/residency-training.component";
import { FellowshipTrainingComponent } from "./components/pages/fellowship-training/fellowship-training.component";
//import { ResidencyEditorComponent } from "./components/admin/residency-editor/residency-editor.component";
//import { FellowshipEditorComponent } from "./components/admin/fellowship-editor/fellowship-editor.component";
import { ContactUsComponent } from "./components/pages/contact-us/contact-us.component";
import { CareersComponent } from "./components/pages/careers/careers.component";
//import { CareersEditorComponent } from "./components/admin/careers-editor/careers-editor.component";
//import { AdmissionProcedureEditorComponent } from "./components/admin/admission-procedure-editor/admission-procedure-editor.component";
//import { DischargeProcedureEditorComponent } from "./components/admin/discharge-procedure-editor/discharge-procedure-editor.component";
//import { AdvanceDirectivesEditorComponent } from "./components/admin/advance-directives-editor/advance-directives-editor.component";
//import { MedicalRecordEditorComponent } from "./components/admin/medical-record-editor/medical-record-editor.component";
//import { PatientRightsResponsibilitiesEditorComponent } from "./components/admin/patient-rights-responsibilities-editor/patient-rights-responsibilities-editor.component";
//import { PharmacyInfoEditorComponent } from "./components/admin/pharmacy-info-editor/pharmacy-info-editor.component";
//import { OthersEditorComponent } from "./components/admin/others-editor/others-editor.component";
//import { AboutchhceditorComponent } from "./components/admin/aboutchhceditor/aboutchhceditor.component";
//import { AboutchhmeditorComponent } from "./components/admin/aboutchhmeditor/aboutchhmeditor.component";
//import { PriceListEditorComponent } from "./components/admin/price-list-editor/price-list-editor.component";
//import { PageHeaderEditorComponent } from "./components/admin/page-header-editor/page-header-editor.component";
//import { AboutusEditorComponent } from "./components/admin/aboutus-editor/aboutus-editor.component";
//import { ListComponent } from "./components/approver/list/list.component";
//import { ResidencyApproverComponent } from "./components/approver/residency-approver/residency-approver.component";
//import { HomePageEditorComponent } from "./components/admin/home-page-editor/home-page-editor.component";
import { HrGuard } from "./guards/hr.guard";
import { EcuComponent } from "./components/pages/writeup/ecu/ecu.component";
//import { ServicesEditorComponent } from "./components/admin/services-editor/services-editor.component";
import { CareersApproverComponent } from "./components/approver/careers-approver/careers-approver.component";
import { EcuPlansComponent } from "./components/pages/ecu-plans/ecu-plans.component";
import { ListOfServicesComponent } from "./pages/list-of-services/list-of-services.component";
import { CmeComponent } from "./pages/cme/cme.component";
import { PrivacyComponent } from "./components/pages/privacy/privacy.component";
import { ResidencyTrainingMandaueComponent } from "./components/pages/residency-training-mandaue/residency-training-mandaue.component";
//import { ResidencyEditorMComponent } from "./components/admin/residency-editor-m/residency-editor-m.component";
import { VisitationGuidelinesComponent } from "./components/website/visitation-guidelines/visitation-guidelines.component";
import { IrbComponent } from "./components/pages/irb/irb.component";
import { PromosViewComponent } from "./components/website/promos-view/promos-view.component";
import { MasRiseComponent } from "./components/pages/events/mas-rise/mas-rise.component";
import { RndSummitComponent } from "./components/pages/events/rnd-summit/rnd-summit.component";
import { SponsorsComponent } from "./components/pages/events/sponsors/sponsors.component";
import { AdminModule } from "./components/admin/admin.module";
import { Irb2Module } from "./components/pages/irb2/irb2.module";
import { RoomMandaueComponent } from "./components/pages/room-mandaue/room-mandaue.component";
import { InsuranceComponent } from "./components/pages/insurance/insurance.component";
import { RoomCebuComponent } from "./components/pages/room-cebu/room-cebu.component";
import { EventsComponent } from "./components/website/whats-new/events/events.component";

const routes: Routes = [
    { path: "room-rates-mandaue", component: RoomMandaueComponent },
    { path: "room-rates-cebu", component: RoomCebuComponent },
    { path: "ecu", component: EcuComponent },
    {
        path: "list-of-accredited-hmos-and-insurance",
        component: InsuranceComponent,
    },

    { path: "irb", component: IrbComponent },
    //{ path: "irb", loadChildren: () => Irb2Module },

    { path: "mas-rise", component: MasRiseComponent },
    { path: "mas-rise/sponsors", component: SponsorsComponent },
    { path: "medical-ancillary-services", component: RndSummitComponent },
    {
        path: "medical-ancillary-services/sponsors",
        component: SponsorsComponent,
    },

    { path: "privacy", component: PrivacyComponent },
    { path: "", component: HomeOneComponent },
    { path: "about_chhc", component: AboutChhcComponent },
    { path: "residency-training", component: ResidencyTrainingComponent },
    {
        path: "residency-training-mandaue",
        component: ResidencyTrainingMandaueComponent,
    },
    { path: "fellowship-training", component: FellowshipTrainingComponent },
    { path: "careers", component: CareersComponent },
    { path: "careers/:name", component: CareersComponent },
    { path: "careers/:division/:name", component: CareersComponent },

    { path: "about_chhm", component: AboutChhmComponent },

    {
        path: "admission-procedure",
        component: AdmissionProcedureComponent,
    },
    {
        path: "ECU-plans",
        component: EcuPlansComponent,
    },
    {
        path: "discharge-procedure",
        component: DischargeProcedureComponent,
    },
    {
        path: "advance-directives",
        component: AdvanceDirectivesComponent,
    },
    {
        path: "medical-records",
        component: MedicalRecordsComponent,
    },
    {
        path: "visitation-guidelines",
        component: VisitationGuidelinesComponent,
    },
    {
        path: "patient-rights-responsibilities",
        component: PatientRightsResponsibilitiesComponent,
    },
    {
        path: "pharmacy-info",
        component: PharmacyInfoComponent,
    },
    {
        path: "prices",
        component: PricesComponent,
    },
    {
        path: "doctors",
        component: DoctorComponent,
    },
    {
        path: "doctors/:category",
        component: DoctorComponent,
    },
    {
        path: "doctor/:licno",
        component: DoctorDetailsComponent,
    },
    {
        path: "contact-us",
        component: ContactUsComponent,
    },

    {
        path: "general-information",
        component: OthersComponent,
    },
    /*{
        path: "promos-packages",
        component: PromosPackagesComponent,
    },
    {
        path: "promos-packages/:id",
        component: PromosViewComponent,
    },
    
    */
    {
        path: "whats-new/events",
        component: EventsComponent,
    },
    {
        path: "whats-new/:type",
        component: PromosPackagesComponent,
    },
    {
        path: "whats-new/:type/:dataId",
        component: PromosViewComponent,
    },
    {
        path: "loginaschh",
        component: LoginComponent,
    },
    { path: "chhadmin", loadChildren: () => AdminModule },

    /*
    {
        canActivate: [IndexGuard],
        path: "admin/careers-approver",
        component: CareersApproverComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "admin/services-editor",
        component: ServicesEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "admin/home",
        component: HomeEditorComponent,
    },

    {
        canActivate: [IndexGuard],
        path: "admin/about-editor",
        component: AboutusEditorComponent,
    },
    {
        canActivate: [IndexGuard, HrGuard],
        path: "admin/careers-editor",
        resolve: {
            userData: UserDataResolver,
        },
        component: CareersEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "admin/aboutchhc-editor",
        component: AboutchhceditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "admin/pageHeaderEditor",
        component: PageHeaderEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "admin/aboutchhm-editor",
        component: AboutchhmeditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "admin/price-editor",
        component: PriceListEditorComponent,
    },

    {
        canActivate: [IndexGuard],
        path: "admin/promos-packages-editor",
        component: PromosPackagesEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "admin/approver/lists",
        component: ListsComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/home-editor",
        component: HomeEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/home-page-editor",
        component: HomePageEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/admission-procedure-editor",
        component: AdmissionProcedureEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/discharge-procedure-editor",
        component: DischargeProcedureEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/advance-directive-editor",
        component: AdvanceDirectivesEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/medical-records-editor",
        component: MedicalRecordEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/residency-editor",
        component: ResidencyEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/residency-editor-mandaue",
        component: ResidencyEditorMComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/patient-rights-responsibilities-editor",
        component: PatientRightsResponsibilitiesEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/pharmacy-info-editor",
        component: PharmacyInfoEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/others-editor",
        component: OthersEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/fellowship-editor",
        component: FellowshipEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/approver",
        component: ListComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admin/residency-approver",
        component: ResidencyApproverComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },*/
    //{ path: "home-two", component: HomeTwoComponent },
    // { path: "home-two", component: HomeTwoComponent },
    // { path: "home-three", component: HomeThreeComponent },
    { path: "about", component: AboutComponent },
    //{ path: "appointment", component: AppointmentComponent },
    //{ path: "departments", component: DepartmentsComponent },
    //{ path: "testimonials", component: TestimonialsComponent },
    { path: "signup", component: SignupComponent },

    //  { path: "privacy-policy", component: PrivacyPolicyComponent },
    //// { path: "terms-condition", component: TermsConditionComponent },
    // { path: "faq", component: FaqComponent },
    ////{ path: "error", component: ErrorComponent },
    // { path: "coming-soon", component: ComingSoonComponent },
    //{ path: "services", component: ServicesComponent },
    { path: "services-details/:id", component: ServicesDetailsComponent },
    { path: "residency-training/:id", component: ServicesDetailsComponent },
    { path: "fellowship-training/:id", component: ServicesDetailsComponent },
    { path: "department/:id", component: ServicesDetailsComponent },
    { path: "data/:id", component: ServicesDetailsComponent },
    { path: "services-details", component: ServicesDetailsComponent },
    /* { path: "doctor", component: DoctorComponent },
    { path: "doctor-details", component: DoctorDetailsComponent },*/
    { path: "list-of-services", component: ListOfServicesComponent },
    { path: "continuing-medical-education", component: CmeComponent },
    // { path: "blog", component: BlogComponent },
    // { path: "blog-details", component: BlogDetailsComponent },
    //{ path: "contact", component: ContactComponent },
    // Here add new pages component

    { path: "**", component: ErrorComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            relativeLinkResolution: "legacy",
            useHash: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
