import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FunctionsService } from "src/app/shared/functions/functions.service";

@Component({
    selector: "approver-nav",
    templateUrl: "./approver-nav.component.html",
    styleUrls: ["./approver-nav.component.scss"],
})
export class ApproverNavComponent implements OnInit {
    constructor(private funcServ: FunctionsService, private route: Router) {}

    ngOnInit(): void {}
    logout() {
        this.funcServ.logout();
    }
    goto(to) {
        //console.log(to);

        this.route.navigate(["/admin/" + to]);
    }
}
