<!--page-title [pageTitle]="title"></page-title-->
<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ title1 }}</h2>
                <!--ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul-->
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img [src]="url + imglinkBig" alt="About" />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <h2 [innerHTML]="title1"></h2>
                    <p [innerHTML]="description"></p>

                    <!--ul>
                        <li><i class="icofont-check-circled"></i> Browse Our Website</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                        <li><i class="icofont-check-circled"></i> Send Messege</li>
                    </ul>
                    <a routerLink="/about">Know More</a-->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-right" [innerHTML]="content"></div>
            </div>
            <div class="col-lg-7">
                <div class="container-fluid">
                    <div class="row location-wrap">
                        <div class="col-sm-6 col-lg-4">
                            <div class="location-item">
                                <a
                                    href="https://www.google.com/maps/place/Chong+Hua+Hospital+Mandaue/@10.3230767,123.9270309,17z/data=!3m1!4b1!4m5!3m4!1s0x33a999a807abed51:0x8cf1e70e847da08!8m2!3d10.3230715!4d123.9315156"
                                    target="_BLANK"
                                >
                                    <i class="icofont-location-pin"></i>
                                    <h3>Location</h3>
                                    <ul>
                                        <li>
                                            Mantawi Int'l Drive, City South
                                            Economic Administrative Zone,
                                            Mandaue City, Cebu, Philippines 6014
                                        </li>
                                    </ul>
                                </a>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <div class="location-item">
                                <a href="mailto:info@chonghua.com.ph">
                                    <i class="icofont-ui-message"></i>
                                    <h3>Email</h3>
                                    <ul>
                                        <li>info@chonghua.com.ph</li>
                                    </ul>
                                </a>
                            </div>
                        </div>

                        <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                            <div class="location-item">
                                <a href="tel:+63 (32) 233-8000">
                                    <i class="icofont-ui-call"></i>
                                    <h3>Phone</h3>
                                    <ul>
                                        <li>+63 (32) 233-8000</li>
                                    </ul>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contact-map-area">
                    <div id="map">
                        <div class="mapouter">
                            <div class="gmap_canvas">
                                <iframe
                                    class="gmap_iframe"
                                    width="100%"
                                    frameborder="0"
                                    scrolling="no"
                                    marginheight="0"
                                    marginwidth="0"
                                    src="https://maps.google.com/maps?width=600&amp;height=500&amp;hl=en&amp;q=10.322952751033736, 123.93145505517558&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                ></iframe>
                            </div>
                            <style>
                                .mapouter {
                                    position: relative;
                                    text-align: right;
                                    width: 100%;
                                    height: 400px;
                                }

                                .gmap_canvas {
                                    overflow: hidden;
                                    background: none !important;
                                    width: 100%;
                                    height: 400px;
                                }

                                .gmap_iframe {
                                    height: 400px !important;
                                }
                            </style>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
