import { Component, NgZone, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-residency-training-mandaue",
    templateUrl: "./residency-training-mandaue.component.html",
    styleUrls: ["./residency-training-mandaue.component.scss"],
})
export class ResidencyTrainingMandaueComponent implements OnInit {
    constructor(
        private genServ: GeneralService,
        private sanitizer: DomSanitizer,
        private general: GeneralService,
        private ngZone: NgZone
    ) {}
    id;
    description;
    ngOnInit(): void {
        this.get();
        this.getBanner();
    }

    get() {
        this.genServ.defaultGet("mandaue-residencies").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.id = data.data[0].id;
                this.description = this.sanitizer.bypassSecurityTrustHtml(
                    data.data[0].attributes.residencycolumn
                );
                //console.log(this.description);
            },
        });
    }
    bannerImage;
    bacg;
    getBanner() {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                let dummyx = [];
                this.bannerImage = data.data;
                dummyx = this.bannerImage.filter(
                    (x) => x.attributes.owner == "Residency"
                );
                this.ngZone.run(() => {
                    this.bacg =
                        "https://strapi.chonghua.com.ph" +
                        dummyx[0].attributes.images;
                });
            },
        });
    }
}
