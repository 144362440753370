import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
    selector: "app-careers-approver",
    templateUrl: "./careers-approver.component.html",
    styleUrls: ["./careers-approver.component.scss"],
})
export class CareersApproverComponent implements OnInit {
    jobTitle1;
    jobTitleLink;
    category;
    type;
    qualifications;
    contact;
    short_Desc;
    addCareers = {
        data: {
            job_title: "",
            link_title: "",
            category: "",
            type: "",
            qualification: "",
            contact: "",
            approver_id1: null,
            approver_id1_status: false,
            short_Desc: "",
        },
    };
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone,
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {}
    ngOnInit(): void {
        this.chooseType(this.approvedUnapproved);
    }
    careerList;
    getCareer() {
        this.careerList = [];
        this.general.get("careers").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data.data);

                this.careerList = data.data;

                ////console.log(this.careerList);
            },
        });
    }
    colorBG(x) {
        if (x) {
            return "greenMe";
        } else {
            return "redMe";
        }
    }
    selectedId;
    isUpdatemeCLicked;
    updateMe(id, x) {
        //console.log(id);
        //console.log(x);

        this.jobTitle1 = "";
        this.jobTitleLink = "";
        this.category = "";
        this.type = "";
        this.qualifications = "";
        this.contact = "";
        this.short_Desc = "";
        this.selectedId = id;
        this.isUpdatemeCLicked = true;
        ////console.log(id);
        ////console.log(x);
        this.jobTitle1 = this.addCareers.data.job_title =
            x.attributes.job_title;
        this.jobTitleLink = this.addCareers.data.link_title =
            x.attributes.link_title;
        this.category = this.addCareers.data.category = x.attributes.category;
        this.type = this.addCareers.data.type = x.attributes.type;
        this.qualifications = this.sanitizer.bypassSecurityTrustHtml(
            x.attributes.qualification
        );
        this.addCareers.data.qualification = x.attributes.qualification;
        this.addCareers.data.short_Desc = x.attributes.short_Desc;

        this.addCareers.data.approver_id1 = x.attributes.approver_id1;
        this.contact = this.sanitizer.bypassSecurityTrustHtml(
            x.attributes.contact
        );

        this.addCareers.data.contact = x.attributes.contact;

        this.short_Desc = this.addCareers.data.short_Desc =
            x.attributes.short_Desc;
        ////console.log(this.addCareers);

        /**/
    }
    approve(a) {
        this.jobTitle1 = "";
        this.jobTitleLink = "";
        this.category = "";
        this.type = "";
        this.qualifications = "";
        this.contact = "";
        this.short_Desc = "";
        this.selectedId = a.id;
        this.isUpdatemeCLicked = true;
        ////console.log(id);
        ////console.log(x);
        this.jobTitle1 = this.addCareers.data.job_title =
            a.attributes.job_title;
        this.jobTitleLink = this.addCareers.data.link_title =
            a.attributes.link_title;
        this.category = this.addCareers.data.category = a.attributes.category;
        this.type = this.addCareers.data.type = a.attributes.type;
        this.qualifications = this.sanitizer.bypassSecurityTrustHtml(
            a.attributes.qualification
        );
        this.addCareers.data.qualification = a.attributes.qualification;
        this.addCareers.data.short_Desc = a.attributes.short_Desc;

        this.addCareers.data.approver_id1 = a.attributes.approver_id1;
        this.contact = this.sanitizer.bypassSecurityTrustHtml(
            a.attributes.contact
        );

        this.addCareers.data.contact = a.attributes.contact;

        this.short_Desc = this.addCareers.data.short_Desc =
            a.attributes.short_Desc;
        //console.log(a.id);

        this.addCareers.data.approver_id1_status = true;
        //console.log(this.addCareers);
        this.general.put("careers", this.addCareers, a.id).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {},
            next: (data: any) => {},
        });
    }
    approvedUnapproved: boolean = false;
    chooseType(b) {
        this.jobTitle1 = "";
        this.jobTitleLink = "";
        this.category = "";
        this.type = "";
        this.qualifications = "";
        this.contact = "";
        this.short_Desc = "";
        this.isUpdatemeCLicked = true;
        this.short_Desc = null;
        this.approvedUnapproved = b;
        //https://strapi.chonghua.com.ph/api/careers?filters[approver_id1_status][$eq]=false
        //console.log(b);
        this.careerList = [];
        this.general
            .get(
                "careers?filters[approver_id1_status][$eq]=" +
                    b +
                    "&pagination[pageSize]=99999"
            )
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (data: any) => {
                    this.careerList = data.data;
                },
            });
    }

    approveunapproved(x) {
        //  //console.log(x);
    }
}
