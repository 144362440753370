import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { GeneralService } from "src/app/services/general.service";
import { ScreenSizeService } from "src/app/services/screen-size/screen-size.service";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    private ngUnsubscribe = new Subject();
    isNotification: boolean;
    isPortrait: boolean;
    isDesktop: boolean;
    constructor(private screensizeService: ScreenSizeService) {
        this.screensizeService
            .isPortraitView()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isPortrait) => {
                if (this.isPortrait && !isPortrait) {
                    this.ngOnInit();
                }
                if (this.isPortrait != undefined && isPortrait) {
                    this.ngOnInit();
                }
                this.isPortrait = isPortrait;
            });
        this.screensizeService
            .isDesktopView()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isDesktop) => {
                ////console.log(isDesktop);

                ////console.log("RELOAD - HOME");
                if (this.isDesktop && !isDesktop) {
                    window.location.reload();
                }
                this.isDesktop = isDesktop;
            });
        ////console.log(this.isDesktop);
    }
    link: any;
    ngOnInit(): void {
        ////console.log();
    }
    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
