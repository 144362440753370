<app-page-title1 pageTitle="Room Catalogue Editor"></app-page-title1>

<app-admin-nav></app-admin-nav>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Site
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Page Title"
                                        [(ngModel)]="site"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Queue
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Page Title"
                                        [(ngModel)]="queue"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-3">
                                        Large Image:
                                        <input
                                            type="file"
                                            name="files"
                                            accept="image/*"
                                            #file
                                            placeholder="Choose file"
                                            (change)="selectbigImg($event)"
                                            style="display: none"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-warning"
                                            (click)="file.click()"
                                        >
                                            Upload
                                        </button>
                                        <span
                                            class="upload"
                                            *ngIf="progress > 0"
                                        >
                                            {{ progress }}%
                                        </span>
                                        <span class="upload" *ngIf="message">
                                            {{ message }}
                                        </span>
                                    </div>
                                    <div class="col-lg-9">
                                        <img
                                            src=""
                                            alt="Big Image"
                                            id="img_big"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-6 offset-md-3">
                                            <div class="col-md-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        (click)="startUpload()"
                                        *ngIf="!isloading"
                                    >
                                        Add
                                    </button>
                                    <p *ngIf="isloading">Loading....</p>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="text-center"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-12"><br /><br /></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-2 pb-70" *ngFor="let x of promos">
                            <div>
                                <div class="container">
                                    <div class="row align-items-center">
                                        <div class="col-lg-12">
                                            <p>
                                                Site :
                                                <b>{{ x.attributes.site }}</b> |
                                                Queue :
                                                <b>{{ x.attributes.queue }}</b
                                                ><br />
                                                <span
                                                    class="pointHand"
                                                    style="color: RED"
                                                    (click)="delete(x.id, x)"
                                                >
                                                    Delete</span
                                                >
                                            </p>
                                        </div>

                                        <div class="col-lg-12">
                                            <img
                                                [src]="
                                                    url + x.attributes.filename
                                                "
                                                alt="About"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div
                class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
            >
                <br />
            </div>
        </div>
    </div>
</section>
