import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as EmailValidator from "email-validator";
import { GeneralService } from "src/app/services/general.service";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { AfterFeedbackComponent } from "src/app/components/modal/after-feedback/after-feedback.component";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    email = {
        subject: "",
        email: "",
        message: "",
        key: "Dbiqw@drJjpbpt2dqELp8P7",
    };
    name;
    phone = "";
    subject;
    eemail;
    error = "";
    message;
    isEmail = true;
    bsModalRef?: BsModalRef;
    constructor(
        private genServ: GeneralService,
        private modalService: BsModalService
    ) {}
    siteKey = "6LclRXgjAAAAAOmXY7ZaPsvwz6JOaqH-00gKT1Hs";
    recaptcha;
    ngOnInit(): void {
        /* this.reCaptchaV3Service.execute(
            this.siteKey,
            "homepage",
            (token) => {
                console.log("This is your token: ", token);
            },
            {
                useGlobalDomain: false,
            }
        );*/
    }
    nameError;
    emailError;
    phoneError;
    subjectError;
    messageError;
    send() {
        if (
            this.name == "" ||
            this.name == null ||
            this.name == "null" ||
            this.name == undefined ||
            this.name == "undefined"
        ) {
            this.nameError = true;
        } else {
            this.nameError = false;
        }
        if (
            this.eemail == "" ||
            this.eemail == null ||
            this.eemail == "null" ||
            this.eemail == undefined ||
            this.eemail == "undefined"
        ) {
            this.emailError = true;
        } else {
            this.emailError = false;
        }
        if (
            this.phone == "" ||
            this.phone == null ||
            this.phone == "null" ||
            this.phone == undefined ||
            this.phone == "undefined"
        ) {
            this.phoneError = true;
        } else {
            this.phoneError = false;
        }
        if (
            this.subject == "" ||
            this.subject == null ||
            this.subject == "null" ||
            this.subject == undefined ||
            this.subject == "undefined"
        ) {
            this.subjectError = true;
        } else {
            this.subjectError = false;
        }
        if (
            this.message == "" ||
            this.message == null ||
            this.message == "null" ||
            this.message == undefined ||
            this.message == "undefined"
        ) {
            this.messageError = true;
        } else {
            this.messageError = false;
        }
        if (
            this.nameError == false &&
            this.subjectError == false &&
            this.emailError == false &&
            this.phoneError == false &&
            this.messageError == false
        ) {
            this.isSuccess = false;
            this.error = "";
            EmailValidator.validate(this.eemail);
            this.isEmail = EmailValidator.validate(this.eemail);
            //console.log(this.isEmail);
            // this.viewPending();
            if (this.isEmail) {
                //this.sendFeedback("jessiejayr@gmail.com");
                this.sendFeedback("info@chonghua.com.ph");
                this.viewPending();
            } else {
                this.error = "Email is Invalid";
            }
        } else {
        }
    }
    sendFeedback(email) {
        this.email.email = email;
        this.email.subject = "PXI: " + this.subject;
        let nn =
            "Name : " +
            this.name +
            "<br />Phone : " +
            this.phone +
            "<br />Email : " +
            this.eemail +
            "<br /><br />";
        this.email.message = nn + this.message;
        this.genServ.sendEmail(this.email).subscribe({
            complete: () => {
                this.name = "";
                this.eemail = "";
                this.subject = "";
                this.message = "";
                this.phone = "";
                console.log("deleteall");
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });
    }

    viewPending() {
        const initialState: ModalOptions = {
            initialState: {
                site: "",
            },
        };
        this.bsModalRef = this.modalService.show(
            AfterFeedbackComponent,
            initialState
        );
        this.bsModalRef.setClass("modal-md");
        this.bsModalRef.content.closeBtnName = "Close";
        //this.bsModalRef.content.event.subscribe((res) => {});
    }
    protected aFormGroup: FormGroup;
    //6LcZ5HcjAAAAAMGlbzzV8s9UYfujBytGMFybHwTI
    isSuccess: boolean = false;
    handleSuccess(e) {
        this.isSuccess = true;
    }
}
