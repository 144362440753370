<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Service Details</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Service Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area ptb-100">
    <div class="container">
        <div class="services-details-img">
            <img
                src="assets/img/service-details-bg.jpg"
                alt="Service Details"
            />

            <h2>Our hospital always provide good services</h2>
            <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
            </p>

            <blockquote>
                <i class="icofont-quote-left"></i>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint
                optio rem magni, dolorum aut vel nostrum quae, fugit
                necessitatibus eius perferendis. Quia optio tenetur pariatur
                aliquam obcaecati enim quam eum?Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Sint optio rem magni, dolorum aut
                vel nostrum quae, fugit necessitatibus eius perferendis. Quia
                optio tenetur pariatur aliquam obcaecati enim quam eum?
            </blockquote>

            <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-5">
                <div class="service-details-inner-left">
                    <img src="assets/img/signup-bg.jpg" alt="Service" />
                </div>
            </div>

            <div class="col-lg-7">
                <div class="service-details-inner">
                    <h2>We always take care our patient</h2>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Architecto blanditiis obcaecati veritatis magnam
                        pariatur molestiae in maxime. Animi quae vitae in
                        inventore. Totam mollitia aspernatur provident veniam
                        aperiam placeat impedit! Lorem ipsum dolor sit amet,
                        consectetur adipisicing elit. Saepe rem natus nobis,
                        dolorum nam excepturi iure autem nemo ducimus temporibus
                        facere, est eum voluptatem, culpa optio fugit assumenda
                        quod? Praesentium.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Id, laudantium ullam, iure distinctio officia libero
                        voluptatem obcaecati vero deleniti minima nemo itaque
                        alias nisi eveniet soluta architecto quae laboriosam
                        unde.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
