import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-pharmacy-info",
    templateUrl: "./pharmacy-info.component.html",
    styleUrls: ["./pharmacy-info.component.scss"],
})
export class PharmacyInfoComponent implements OnInit {
    constructor(
        private genServ: GeneralService,
        private sanitizer: DomSanitizer
    ) {}
    description;
    id;

    ngOnInit(): void {
        this.get();
    }
    get() {
        this.genServ.get("pharmacy-infos").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.id = data.data[0].id;
                this.description = this.sanitizer.bypassSecurityTrustHtml(
                    data.data[0].attributes.column1
                );
            },
        });
    }
}
