<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Visitation Guidelines</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Visitation Guidelines</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <!--img src="assets/img/home-three/6.png" alt="Shape" /-->
        </div>

        <div class="row m-0 align-items-center">
            <div class="col-lg-6 offset-lg-3">
                <br />
                <div>
                    <b
                        >The Philippines is currently in the transition phase of
                        the endemic phase of COVID-19. As mandated by the DOH,
                        we request everyone to comply with the following:</b
                    >
                    <br /><br />

                    <ul>
                        <li>
                            A maximum of two healthy adult watchers or visitors
                            are permitted to stay with a non-COVID patient
                            during admission.
                        </li>
                        <li>
                            Only one adult watcher or visitor MAY be allowed to
                            stay with a confirmed COVID patient during their
                            admission.
                        </li>
                        <li>
                            Healthy visitors of non-COVID patients, EXCEPT for
                            those in the critical care units, may be allowed
                            entry from 6 a.m. to 9 p.m. only.
                        </li>
                        <li>All visitors must observe physical distancing.</li>
                        <li>
                            All visitors must wear a surgical mask while in the
                            hospital.
                        </li>
                        <li>
                            All visitors must practice cough etiquette. Ensure
                            that a surgical mask is on when you cough or sneeze.
                            Change the mask if it is soiled or damp. Throw the
                            used mask in the yellow trash bin and wash your
                            hands afterward.
                        </li>
                        <li>
                            Visitors must follow proper hand hygiene protocols
                            such as frequent hand washing and the use of hand
                            sanitizers.
                        </li>
                        <li>
                            Visitors experiencing symptoms of COVID-19, such as
                            fever, cough, and difficulty breathing, shall not be
                            allowed to visit patients.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0"></div>
    </div>
</section>
