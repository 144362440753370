import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-child-nav",
    templateUrl: "./child-nav.component.html",
    styleUrls: ["./child-nav.component.scss"],
})
export class ChildNavComponent implements OnInit {
    @Input() ParentId;

    constructor(private route: Router, private general: GeneralService) {}
    Parents: any;
    name;
    link;
    contact;
    sort;
    length;
    addCareersData = {
        data: {
            sort: "",
            name: "",
            link: "",
            haschild: false,
        },
    };
    ngOnInit(): void {
        this.getCareer();
    }
    redirectTo(to) {
        this.route.navigate([to]).then(() => {});
    }
    getCareer() {
        this.general
            .get(
                "irbnav-children?filters[parentId][$eq]=" +
                    this.ParentId +
                    "&sort=sort:ASC"
            )
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (data: any) => {
                    this.length = (data.data.length + 1).toString();
                    this.sort = this.length;
                    this.Parents = data.data;
                    //console.log(this.Parents);
                },
            });
    }
}
