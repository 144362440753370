<div class="header-top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-12 col-lg-5">
                <a class="navbar-brand" routerLink="/"
                    ><img src="assets/img/Bigger Date__Org-wide.png" alt="Logo"
                /></a>
            </div>

            <div class="col-sm-12 col-lg-7">
                <div class="header-top-item">
                    <div class="container">
                        <div class="header-top-right">
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Cebu City</b> <br />
                                    <a
                                        _ngcontent-xju-c39=""
                                        href="tel:(+6332) 255-8000"
                                        style="color: #677294"
                                        ><i
                                            _ngcontent-xju-c39=""
                                            class="icofont-ui-call"
                                        ></i>
                                        <b>(+6332) 255-8000</b></a
                                    >
                                </div>
                                <div class="col-md-4">
                                    <b>Mandaue City</b><br />

                                    <a
                                        _ngcontent-xju-c39=""
                                        href="tel:(+6332) 233-8000"
                                        style="color: #677294"
                                        ><i
                                            _ngcontent-xju-c39=""
                                            class="icofont-ui-call"
                                        ></i>
                                        <b>(+6332) 233-8000</b></a
                                    >
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/ChongHuaHospital/"
                                                target="_blank"
                                                ><i class="icofont-facebook"></i
                                            ></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area sticky-top">
    <div class="mobile-nav">
        <a routerLink="/" style="display: none" class="logo testMain"
            ><img
                src="assets/img/Org-wide_Org-wide Supergraphic.png"
                alt="Logo"
        /></a>
    </div>

    <div class="main-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <li
                            class="nav-item"
                            *ngFor="let grandParent of grandParents"
                        >
                            <ng-container
                                *ngIf="grandParent.attributes.haschild"
                                ><a
                                    class="nav-link pointHand"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    style="font-size: 18px !important"
                                    >{{ grandParent.attributes.name }}

                                    <app-parent-nav
                                        [grandParentId]="grandParent.id"
                                    ></app-parent-nav> </a></ng-container
                            ><ng-container
                                *ngIf="!grandParent.attributes.haschild"
                                ><a
                                    routerLink="/irb/{{
                                        grandParent.attributes.link
                                    }}"
                                    class="nav-link pointHand"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    style="font-size: 18px !important"
                                    >{{ grandParent.attributes.name }}</a
                                ></ng-container
                            >
                        </li>
                        <!--li class="nav-item">
                            <a
                                routerLink="/irb/SOP"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                style="font-size: 18px !important"
                                >SOP's</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                href="javascript:void(0)"
                                class="nav-link dropdown-toggle testsubmeter"
                                >Protocol Reviews Submission Guidelines for
                                Investigators</a
                            >

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        class="nav-link pointHand"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >General Submission Guidelines</a
                                    >
                                    <ul class="dropdown-menu">
                                        <li class="">
                                            <a
                                                routerLink="/irb/submission-flow-charts"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Submission Flow Chart</a
                                            >
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                routerLink="/about_chhc"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Critiera for Exempt, Expedited
                                                and Full Board review</a
                                            >
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                routerLink="/irb/meeting-dates-and-cut-off-dates-for-submissions"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Meeting Dates and Cut-off Dates
                                                for Submission</a
                                            >
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/about_chhm"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Procedures for Submission</a
                                    >
                                    <ul class="dropdown-menu">
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Initial Submissions</a
                                            >
                                        </li>
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Resubmitted Documents</a
                                            >
                                        </li>
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Post Review Ammendments</a
                                            >
                                        </li>
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Progress Reports</a
                                            >
                                        </li>
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Continuing Review
                                                Submissions</a
                                            >
                                        </li>
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Reporting of Servious Adverse
                                                Events</a
                                            >
                                            <ul class="dropdown-menu">
                                                <li class="">
                                                    <a
                                                        routerLink="/about"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        class="testsubmeter"
                                                        >Onsite SAEs</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/about_chhc"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        class="testsubmeter"
                                                        >Offsite SAEs</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Protocol Deviation / Violation
                                                Reports</a
                                            >
                                        </li>
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Final Report Procedures</a
                                            >
                                        </li>
                                        <li class="">
                                            <a
                                                routerLink="/about"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="testsubmeter"
                                                >Early Termination</a
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li-->
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
