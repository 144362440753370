import { Component, NgZone, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
    constructor(
        private general: GeneralService,
        private sanitizer: DomSanitizer,
        private ngZone: NgZone
    ) {}
    addDoc = {
        data: {
            title: "",
            aboutData: null,
            aboutImage: "",
            aboutImage_hash: "",
            aboutImage_id: null,
            visionMissionData: null,
            visionMissionImage: "",
            visionMissionImage_hash: "",
            visionMissionImage_id: null,
        },
    };
    ngOnInit(): void {
        this.getSlider();
        this.getBanner();
    }
    abouturl = "https://strapi.chonghua.com.ph";
    getSlider() {
        this.general.get("about-uses").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data);

                this.addDoc.data.title = data.data[0].attributes.title;

                this.addDoc.data.aboutData =
                    this.sanitizer.bypassSecurityTrustHtml(
                        data.data[0].attributes.aboutData
                    );
                this.addDoc.data.visionMissionData =
                    this.sanitizer.bypassSecurityTrustHtml(
                        data.data[0].attributes.visionMissionData
                    );
                this.addDoc.data.aboutImage =
                    this.abouturl + data.data[0].attributes.aboutImage;
                this.addDoc.data.aboutImage_id =
                    data.data[0].attributes.aboutImage_id;

                this.addDoc.data.visionMissionImage_id =
                    data.data[0].attributes.visionMissionImage_id;
                this.addDoc.data.visionMissionImage_hash =
                    data.data[0].attributes.visionMissionImage_hash;
                this.addDoc.data.visionMissionImage =
                    this.abouturl + data.data[0].attributes.visionMissionImage;

                //console.log(this.addDoc);
            },
        });
    }
    bannerImage;
    bacg;
    getBanner() {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.bannerImage = data.data;
                let asdasdasd;
                this.bannerImage = data.data;
                asdasdasd = this.bannerImage.filter(
                    (x) => x.attributes.owner == "About Us"
                );
                this.ngZone.run(() => {
                    this.bacg =
                        "https://strapi.chonghua.com.ph" +
                        asdasdasd[0].attributes.images;
                });
            },
        });
    }
}
