import { Component, OnInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-after-feedback",
    templateUrl: "./after-feedback.component.html",
    styleUrls: ["./after-feedback.component.scss"],
})
export class AfterFeedbackComponent implements OnInit {
    constructor(private modalService: BsModalService) {}

    ngOnInit(): void {}
    onClick() {
        //this.event.emit("asdasdas");
        this.modalService.hide();
    }
}
