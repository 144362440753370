import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-clinic-helper",
    templateUrl: "./clinic-helper.component.html",
    styleUrls: ["./clinic-helper.component.scss"],
})
export class ClinicHelperComponent implements OnInit {
    @Input() clinic_Contact_No = null;
    @Input() clinic_Schedule = null;
    constructor() {}

    ngOnInit(): void {
        //console.log(this.clinic_Schedule);
        if (this.clinic_Contact_No != null) {
            if (this.clinic_Contact_No.includes("/")) {
                this.clinic_Contact_No = this.clinic_Contact_No.replace(
                    " /",
                    "<br />"
                );
            }
            if (this.clinic_Contact_No.includes("\n")) {
                this.clinic_Contact_No = this.clinic_Contact_No.replace(
                    /\n/g,
                    "<br />"
                );
            }
            if (this.clinic_Contact_No.includes("\r")) {
                this.clinic_Contact_No = this.clinic_Contact_No.replace(
                    "\r",
                    "<br />"
                );
            }
        }
    }
}
