import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthConstants } from "src/app/config/auth-constants";
import { PromosService } from "src/app/services/promos.service";

@Component({
    selector: "app-lists",
    templateUrl: "./lists.component.html",
    styleUrls: ["./lists.component.scss"],
})
export class ListsComponent implements OnInit {
    constructor(
        private promoService: PromosService,
        private activatedRoute: ActivatedRoute
    ) {}
    ngOnInit(): void {}
    /* userId;
    ngOnInit(): void {
        //console.log("ngOnInit");
        this.displayData = [];
        this.activatedRoute.data.subscribe((userData: any) => {
            this.userId = userData.userData[0].user.id;
            this.getPromosPackagesCount(this.userId);
        });
    }
    promosPackagesCount;
    getPromosPackagesCount(id) {
        //console.log("getPromosPackagesCount", id);
        let x;
        this.promoService.getCount("promos-packages", id).subscribe(
            (res: any) => {
                x = res.data.length;
            },
            (error) => {},
            () => {
                this.promosPackagesCount = x;
            }
        );
    }
    displayData;
    url = "https://strapi.chonghua.com.ph";
    showData(data) {
        //console.log("showData");

        let x;
        this.promoService.getCount("promos-packages", this.userId).subscribe(
            (res: any) => {
                x = res.data.length;
                this.displayData = res.data;
                //console.log(JSON.stringify(this.displayData));
            },
            (error) => {},
            () => {
                this.promosPackagesCount = x;
            }
        );
    }
    addpromo = {
        data: {
            approver_id1_status: true,
            is_visible: true,
        },
    };
    approve(x) {
        //console.log(x);
        this.promoService.updatePromo(this.addpromo, x).subscribe((res) => {
            //console.log(res);
            this.ngOnInit();
        });
    }*/
}
