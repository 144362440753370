import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-mas-rise",
    templateUrl: "./mas-rise.component.html",
    styleUrls: ["./mas-rise.component.scss"],
})
export class MasRiseComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
    isChosen: any = "1";
    chosen(data) {
        this.isChosen = data;
    }
}
