import { ThisReceiver } from "@angular/compiler";
import { Component, OnInit, ViewChild } from "@angular/core";

import { GeneralService } from "src/app/services/general.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";

@Component({
    selector: "app-residency-approver",
    templateUrl: "./residency-approver.component.html",
    styleUrls: ["./residency-approver.component.scss"],
})
export class ResidencyApproverComponent implements OnInit {
    jsonData;
    description;
    id;
    isSelectedApproved: boolean = false;
    selectedJson: any;
    addSlider = {
        data: {
            residencycolumn: "",
            approver_id1: null,
            approver_id1_status: false,
            approver_id2: null,
            approver_id2_status: false,
        },
    };
    constructor(
        private genServ: GeneralService,
        private funcServ: FunctionsService
    ) {}

    ngOnInit(): void {
        this.get();
    }
    get() {
        let jsonData;

        this.genServ.adminGet("cebu-residencies").subscribe({
            complete: () => {
                this.jsonData = [];
                function comp(a, b) {
                    return (
                        new Date(a.attributes.createdAt).getTime() -
                        new Date(b.attributes.createdAt).getTime()
                    );
                }
                jsonData.forEach((element) => {
                    element.attributes._createdAt =
                        this.funcServ.convertDatetoMMDDYYYY(
                            element.attributes.createdAt
                        ) +
                        " " +
                        this.funcServ.getFormatAMPM(
                            element.attributes.createdAt
                        );

                    this.jsonData.push(element);
                });
                ////console.log(this.jsonData);
            },
            error: (error) => {},
            next: (data: any) => {
                this.selectedJson = data.data[0];
                this.isSelectedApproved =
                    data.data[0].attributes.approver_id1_status;
                jsonData = data.data;
                this.id = data.data[0].id;
                this.description = data.data[0].attributes.residencycolumn;
            },
        });
    }

    choose(y) {
        this.selectedJson = y;
        //console.log(this.selectedJson);

        this.id = y.id;
        this.isSelectedApproved = y.attributes.approver_id1_status;
        this.description = y.attributes.residencycolumn;
    }
    test(y, x) {
        let cssClass = "";
        if (y) {
            cssClass += "italicMe";
        }
        if (x == this.id) {
            cssClass += " boldMe";
        }
        return cssClass;
    }
    removeList(y) {
        ////console.log(y);
        /*  this.genServ.delete("cebu-residencies", y.id).subscribe({
            complete: () => {
                this.get();
            },
            error: (error) => {},
            next: (data: any) => {},
        });*/
    }
    approve() {
        let sectedJson = JSON.parse(
            "[" + JSON.stringify(this.selectedJson) + "]"
        );
        let selectedId = sectedJson[0].id;
        this.jsonData.forEach((element) => {
            this.addSlider.data.approver_id1 = element.attributes.approver_id1;
            this.addSlider.data.approver_id2 = element.attributes.approver_id2;
            this.addSlider.data.approver_id1_status = false;
            this.addSlider.data.approver_id1_status = false;
            this.addSlider.data.residencycolumn =
                element.attributes.residencycolumn;
            if (element.id != selectedId) {
                this.addSlider.data.approver_id1_status = false;
            } else {
                this.addSlider.data.approver_id1_status = true;
            }
            this.genServ
                .put("cebu-residencies", this.addSlider, element.id)
                .subscribe({
                    complete: () => {
                        this.ngOnInit();
                    },
                    error: (error) => {},
                    next: (data: any) => {},
                });
        });
    }
}
