import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthConstants } from "../config/auth-constants";
import { AuthService } from "../services/auth.service";
@Injectable({
    providedIn: "root",
})
export class HrGuard implements CanActivate {
    constructor(public auth: AuthService, public router: Router) {}
    userType;
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return new Promise((resolve) => {
            ////console.log("IndexGuard");

            let res = this.auth.get(AuthConstants.AUTH);
            if (res) {
                let userData = this.auth.getUserData();
                this.userType = userData[0].user.type;
                //console.log(this.userType);

                if (this.userType == "HRUSER") {
                    resolve(true);
                } else {
                    this.router.navigate(["/admin/home"]);
                    resolve(false);
                }
            } else {
                //this.router.navigate(["/login"]);
                resolve(false);
            }
        });
    }
}
