<div class="page-title-area page-title-four ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Pharmacy Info</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape">
        </div>

        <div class="row m-0 align-items-center">


            <div class="col-lg-6 offset-lg-3">
                <div>
                    <br />
                    <div class="" [innerHtml]="description"></div>
                </div>
                <div>
                    <!--br />
                    
                    <ul>
                        <li>The hospital pharmacy, located at the lobby of Building AB beside the entrance, is open 24
                            hours
                            a day, seven days a
                            week, with a professional pharmacy staff to assist you, working together with the nursing
                            and
                            nutrition services to
                            provide you with comprehensive pharmaceutical services that follows international standards.
                        </li>
                        <li>Your safety is our primary concern. Effective January 1, 2010, Chong Hua Hospital
                            requires all parental/injectable
                            medications to come from the hospital pharmacy and all parental/ injectable drugs bought
                            outside
                            will no longer be
                            administered to the patient.</li>
                        <li>An 18% discount on the regular price is given to all
                            cash payments, and 8% discount to all medications upon full payment
                            cash.</li>
                        <li>Over-the-counter cash purchase will get 18% discount on the
                            regular
                            price.</li>
                        <li>Non-over-the-counter inpatient medication will
                            get 8% discount upon full cash
                            payment on discharge accounts.</li>
                    </ul><br />

                    <h4>Hospital Pharmacy Policies</h4>
                    <ul>
                        <li>The main objective of these policies, is to ensure patient medication safety</li>
                    </ul><br />
                    <h4>In-House Pharmacy / Close Pharmacy</h4>
                    <ul>
                        <li>All medications administered to inpatients must come from the hospital pharmacy, to avoid
                            the use of fake, substandard
                            or expired medicines.</li>
                    </ul><br />
                    <h4>Herbals and Natural Products</h4>
                    <ul>
                        <li>The hospital allows the use of herbal medications, as long as they comply with the

                            requirements and provisions of the Traditional and Alternative Medicine Act of 1997 (R.A.
                            8423).</li>
                    </ul><br />
                    <h4>Self-Medication</h4>
                    <ul>
                        <li>The administration of your medication is done by the hospital's nursing staff. However, at
                            the appropriate time, you
                            will be taught and encouraged by your doctor, nurse and pharmacist to administer your
                            medications yourself (ie.
                            Insulin).</li>
                    </ul><br />
                    <h4>Miscellaneous</h4>
                    <ul>
                        <li>The Chong Hua Hospital Pharmacy will not dispense controlled and regulated medications
                            without the proper prescription.</li>
                        <li>Injectable controlled drugs are dispensed for inpatients only.</li>
                        <li>Only over-the-counter (OTC) medications are dispensed without a prescription.</li>
                    </ul><br /-->

                    <!--ul>
                        <li><i class="icofont-check-circled"></i> Browse our Website</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                    </ul>
                    <a class="hospital-btn" routerLink="/about">Know More</a-->
                </div>
            </div>

        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <!--div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                        <p>World class, state-of-the-art facilities, paired with an efficient and compassionate medical
                            staff dedicated to bringing
                            you a comprehensive, cost-efficient patient experience designed for your specific needs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h4>Emergency Call</h4>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</section>