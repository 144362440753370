<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item">
                    <h1>Healing with passion. Caring with compassion. MEETING WITH IT</h1>
                    <p>At Chong Hua Hospital, the concept of PATIENT-FOCUSED CARE puts you, the patient, first.</p>

                    <div class="common-btn-two">
                        <a routerLink="/appointment">Get Appointment</a>
                        <a class="cmn-btn-right-two" routerLink="/about">Learn More</a>
                    </div>

                    <div class="banner-right">
                        <img src="assets/img/home-three/1.png" alt="Banner">
                        <img src="assets/img/home-three/2.png" alt="Banner">
                        <img src="assets/img/home-three/2.png" alt="Banner">
                        <img src="assets/img/home-one/home-bg5.png" alt="Banner">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="heart-shape"><img src="assets/img/home-three/3.png" alt="Banner"></div>
</div>

<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape">
        </div>

        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0">
                <div class="hospital-item">
                    <img src="assets/img/pxi/chh mandaue facade.jpg" alt="About">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="hospital-item hospital-right">
                    <h2>About Our Hospital</h2>
                    <p>Chong Hua Hospital is a tertiary medical center at the heart of the historic island of Cebu,
                        Philippines and has
                        provided quality healthcare since 1909. It was established by the Asociacion Benevola de Cebu
                        Inc.to provide reliable,
                        compassionate and accessible health care services to the community and continues to uphold this
                        purpose today. From a
                        humble makeshift clinic in 1909, Chong Hua Hospital has expanded into two hospitals in Cebu City
                        and Mandaue City, with
                        more than 900 hospital beds, 600 physicians, and 2,500 employees. Chong Hua Hosptal is committed
                        to deliver
                        comprehensive and quality health services through it's skilled, board-certified medical and
                        allied medical staff,
                        efficient management personnel, and state-of-the-art equipment and facilities.</p>
                    <!--ul>
                        <li><i class="icofont-check-circled"></i> Browse our Website</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                    </ul-->
                    <a class="hospital-btn" routerLink="/about">Know More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape">
        </div>

        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0">
                <div class="hospital-item">
                    <img src="assets/img/pxi/chh mandaue facade.jpg" alt="About">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="hospital-item hospital-right">
                    <h2>About Our Hospital</h2>
                    <p>Chong Hua Hospital is a tertiary medical center at the heart of the historic island of Cebu,
                        Philippines and has
                        provided quality healthcare since 1909. It was established by the Asociacion Benevola de Cebu
                        Inc.to provide reliable,
                        compassionate and accessible health care services to the community and continues to uphold this
                        purpose today. From a
                        humble makeshift clinic in 1909, Chong Hua Hospital has expanded into two hospitals in Cebu City
                        and Mandaue City, with
                        more than 900 hospital beds, 600 physicians, and 2,500 employees. Chong Hua Hosptal is committed
                        to deliver
                        comprehensive and quality health services through it's skilled, board-certified medical and
                        allied medical staff,
                        efficient management personnel, and state-of-the-art equipment and facilities.</p>
                    <!--ul>
                        <li><i class="icofont-check-circled"></i> Browse our Website</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                    </ul-->
                    <a class="hospital-btn" routerLink="/about">Know More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                        <p>World class, state-of-the-art facilities, paired with an efficient and compassionate medical
                            staff dedicated to bringing
                            you a comprehensive, cost-efficient patient experience designed for your specific needs.</p>
                    </div>

                    <!--div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Child Care</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>More Stuff</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Enough Lab</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>24 Hour Doctor</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>
                        </div>
                    </div-->
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Services</span>
            <h2>Our Hospital Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="welcome-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-left">
                    <img src="assets/img/home-two/bg3.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-right">
                    <div class="section-title-two">
                        <span>About Us</span>
                        <h2>Welcome to Mediserv Hospital. We have...</h2>
                    </div>

                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <i class="icofont-doctor-alt"></i>
                            <div class="welcome-inner">
                                <h3>Certified Doctors</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.consectetur adipiscing elit.
                                </p>
                            </div>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <i class="icofont-stretcher"></i>
                            <div class="welcome-inner">
                                <h3>Emergency 24 hours</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.consectetur adipiscing elit.
                                </p>
                            </div>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <i class="icofont-network"></i>
                            <div class="welcome-inner">
                                <h3>Modern Technologey</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.consectetur adipiscing elit.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="video-wrap video-wrap-two">
    <div class="container-fluid p-0">
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Hospital Introduction</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                            ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                                            facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>About Our Pharmacy</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                            ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                                            facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our reasearch center and lab </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                            ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                                            facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-icu" role="tabpanel" aria-labelledby="pills-icu-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>CCU & ICU</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                            ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                                            facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-doctor" role="tabpanel" aria-labelledby="pills-doctor-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our Doctors</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                            ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                                            facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item video-nav-item">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="tab" data-bs-target="#pills-home"
                    type="button" role="tab" aria-controls="pills-home" aria-selected="true">Hospital
                    Introduction</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="tab" data-bs-target="#pills-profile"
                    type="button" role="tab" aria-controls="pills-profile" aria-selected="true">Pharmacy</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="tab" data-bs-target="#pills-contact"
                    type="button" role="tab" aria-controls="pills-contact" aria-selected="true">Reasearch & Lab</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-icu-tab" data-bs-toggle="tab" data-bs-target="#pills-icu"
                    type="button" role="tab" aria-controls="pills-icu" aria-selected="true">CCU & ICU</button>
            </li>

            <li class="nav-item video-nav-item">
                <button class="nav-link" id="pills-doctor-tab" data-bs-toggle="tab" data-bs-target="#pills-doctor"
                    type="button" role="tab" aria-controls="pills-doctor" aria-selected="true">Doctors</button>
            </li>
        </ul>
    </div>
</div>

<section class="doctors-area pt-100">
    <div class="container">
        <div class="section-title-two">
            <span>Doctors</span>
            <h2>Meet Our Doctors </h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/1.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Babatunde</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/2.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Addision Smith</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".7s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/3.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Sarah Tylor</a></h3>
                        <span>Dental Surgeon</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="doctor-btn">
            <a routerLink="/doctor">See All</a>
        </div>
    </div>
</section>

<div class="counter-area counter-area-three">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">850</h3>
                    <p>Patients Beds</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">25000</span>+</h3>
                    <p>Happy Patients</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">750</h3>
                    <p>Doctors & Nurse</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">18</h3>
                    <p>Year Experience</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="review-area pb-100">
    <div class="container">
        <div class="main">
            <div class="slider-nav">
                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/7.png" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Adision Smith</h3>
                        <span>Designer</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/8.png" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>John Cena</h3>
                        <span>Designer</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/9.png" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Mac Smith</h3>
                        <span>Designer</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/9.png" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Shane Watson</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>

            <div class="slider-for">
                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                    </p>
                </div>

                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                    </p>
                </div>

                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                    </p>
                </div>

                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Blogs</span>
            <h2>Our latest blogs</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a>
                        </h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>