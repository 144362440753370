import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";
@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    pageTitle;
    description;
    expiryDate;
    approver_id1;
    approver_id2;
    addpromo = {
        data: {
            title: "",
            description: "",
            images: "",
            img_id: "",
            img_hash: "",
            img_ext: "",
            approver_id1: null,
            approver_id1_status: false,
            approver_id2: null,
            approver_id2_status: null,
            is_visible: null,
            expiry: null,
        },
    };
    formData;
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient
    ) {}
    progress;
    message;
    ngOnInit(): void {
        this.pageTitle = "";
        this.description = "";
        this.imglink = "";
        this.isUploadStatus = false;
        this.getPromoView();
        if (localStorage.getItem("approver_id1") != undefined) {
            //console.log("approver_id1");

            this.addpromo.data.approver_id1 = Number(
                localStorage.getItem("approver_id1")
            );
        }
        if (localStorage.getItem("approver_id2") != undefined) {
            this.addpromo.data.approver_id2 =
                localStorage.getItem("approver_id2");
        }
    }

    addPromoPro() {
        this.addpromo.data.title = this.pageTitle;
        this.addpromo.data.description = this.description;
        this.addpromo.data.images = this.imglink;
        this.addpromo.data.img_hash = this.img_hash;
        this.addpromo.data.img_id = this.img_id;
        this.addpromo.data.img_ext = this.img_ext;
        this.addpromo.data.expiry = new Date(this.expiryDate);

        ////console.log(this.addpromo);
        //console.log(JSON.stringify(this.addpromo));
        this.promoService.addPromo(this.addpromo).subscribe(
            (res) => {
                ////console.log(res);
            },
            (error) => {
                ////console.log(error);
            },
            () => {
                this.ngOnInit();
            }
        );
    }
    delete(data) {
        ////console.log(data);
        this.promoService.deletePormo(data).subscribe(
            (res) => {
                ////console.log(res);
            },
            (error) => {
                ////console.log(error);
            },
            () => {
                this.ngOnInit();
            }
        );
    }
    imglink;
    img_hash;
    img_id;
    img_ext;
    isUploadStatus: boolean = false;
    imageDetails;
    public uploadFile = (files) => {
        const file: File = files.target.files[0];
        let fileToUpload = files.target.files[0];
        let fileName: string = this.funcServ.generateYMDHMS(new Date());
        let fileExtension: string = fileToUpload.name
            .split("?")[0]
            .split(".")
            .pop();
        if (fileExtension) {
            this.formData = new FormData();
            this.formData.append(
                "files",
                fileToUpload,
                fileName + "." + fileExtension
            );
            this.http
                .post(environment.apiRouterUrl + "api/upload", this.formData, {
                    headers: {},
                    reportProgress: true,
                    observe: "events",
                })
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress)
                            this.progress = Math.round(
                                (100 * event.loaded) / event.total
                            );
                        else if (event.type === HttpEventType.Response) {
                            this.message = "Upload success.";
                        }
                        if (event.body != undefined) {
                            ////console.log(event.body);
                            this.isUploadStatus = false;
                            this.imglink = event.body[0].url;
                            this.img_id = event.body[0].id;
                            this.img_hash = event.body[0].hash;
                            this.img_ext = event.body[0].ext;
                        } else {
                            ////console.log("error");
                            this.isUploadStatus = true;
                        }
                    },
                    (error) => {
                        ////console.log(error);
                    },
                    () => {}
                );
        }
    };
    promos;
    url = "https://strapi.chonghua.com.ph";
    getPromo() {
        this.promoService.getPromoEditor().subscribe((res: any) => {
            ////console.log(res.data);

            this.promos = res.data;
        });
    }
    getPromoView() {
        this.promoService.getPromoView().subscribe((res: any) => {
            ////console.log(res.data);

            this.promos = res.data;
        });
    }
}
