import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IndexGuard } from "src/app/guards/index.guard";
import { HomeEditorComponent } from "./home-editor/home-editor.component";

import { CareersApproverComponent } from "../approver/careers-approver/careers-approver.component";

import { UserDataResolver } from "src/app/resolvers/user-data.resolver";
import { ResidencyApproverComponent } from "../approver/residency-approver/residency-approver.component";
import { ListComponent } from "../approver/list/list.component";
import { FellowshipEditorComponent } from "./fellowship-editor/fellowship-editor.component";
import { OthersEditorComponent } from "./others-editor/others-editor.component";
import { PharmacyInfoEditorComponent } from "./pharmacy-info-editor/pharmacy-info-editor.component";
import { PatientRightsResponsibilitiesEditorComponent } from "./patient-rights-responsibilities-editor/patient-rights-responsibilities-editor.component";
import { ResidencyEditorMComponent } from "./residency-editor-m/residency-editor-m.component";
import { ResidencyEditorComponent } from "./residency-editor/residency-editor.component";
import { MedicalRecordEditorComponent } from "./medical-record-editor/medical-record-editor.component";
import { AdvanceDirectivesEditorComponent } from "./advance-directives-editor/advance-directives-editor.component";
import { DischargeProcedureEditorComponent } from "./discharge-procedure-editor/discharge-procedure-editor.component";
import { AdmissionProcedureEditorComponent } from "./admission-procedure-editor/admission-procedure-editor.component";
import { HomePageEditorComponent } from "./home-page-editor/home-page-editor.component";
import { ListsComponent } from "./approver/lists/lists.component";
import { PromosPackagesEditorComponent } from "./promos-packages-editor/promos-packages-editor.component";
import { PriceListEditorComponent } from "./price-list-editor/price-list-editor.component";
import { AboutchhmeditorComponent } from "./aboutchhmeditor/aboutchhmeditor.component";
import { PageHeaderEditorComponent } from "./page-header-editor/page-header-editor.component";
import { AboutchhceditorComponent } from "./aboutchhceditor/aboutchhceditor.component";
import { CareersEditorComponent } from "./careers-editor/careers-editor.component";
import { HrGuard } from "src/app/guards/hr.guard";
import { AboutusEditorComponent } from "./aboutus-editor/aboutus-editor.component";
import { ServicesEditorComponent } from "./services-editor/services-editor.component";
import { NewsEditorComponent } from "./news-editor/news-editor.component";
import { PatienteducationEditorComponent } from "./patienteducation-editor/patienteducation-editor.component";
import { EcuComponent } from "../pages/writeup/ecu/ecu.component";
import { EcueditorComponent } from "./ecueditor/ecueditor.component";
import { RoomcatalogEditorComponent } from "./roomcatalog-editor/roomcatalog-editor.component";
import { HmoeditorComponent } from "./hmoeditor/hmoeditor.component";

const routes: Routes = [
    {
        canActivate: [IndexGuard],
        path: "home",
        component: HomeEditorComponent,
    },

    {
        canActivate: [IndexGuard],
        path: "careers-approver",
        component: CareersApproverComponent,
    },

    {
        canActivate: [IndexGuard],
        path: "careers-approver",
        component: CareersApproverComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "services-editor",
        component: ServicesEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "ecu-editor",
        component: EcueditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "room-editor",
        component: RoomcatalogEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "hmo-editor",
        component: HmoeditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "home",
        component: HomeEditorComponent,
    },

    {
        canActivate: [IndexGuard],
        path: "about-editor",
        component: AboutusEditorComponent,
    },
    {
        canActivate: [IndexGuard, HrGuard],
        path: "careers-editor",
        resolve: {
            userData: UserDataResolver,
        },
        component: CareersEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "aboutchhc-editor",
        component: AboutchhceditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "pageHeaderEditor",
        component: PageHeaderEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "aboutchhm-editor",
        component: AboutchhmeditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "price-editor",
        component: PriceListEditorComponent,
    },

    {
        canActivate: [IndexGuard],
        path: "promos-packages-editor",
        component: PromosPackagesEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "news-editor",
        component: NewsEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "patienteducation-editor",
        component: PatienteducationEditorComponent,
    },
    {
        canActivate: [IndexGuard],
        path: "approver/lists",
        component: ListsComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "home-editor",
        component: HomeEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "home-page-editor",
        component: HomePageEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "admission-procedure-editor",
        component: AdmissionProcedureEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "discharge-procedure-editor",
        component: DischargeProcedureEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "advance-directive-editor",
        component: AdvanceDirectivesEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "medical-records-editor",
        component: MedicalRecordEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "residency-editor",
        component: ResidencyEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "residency-editor-mandaue",
        component: ResidencyEditorMComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "patient-rights-responsibilities-editor",
        component: PatientRightsResponsibilitiesEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "pharmacy-info-editor",
        component: PharmacyInfoEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "others-editor",
        component: OthersEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "fellowship-editor",
        component: FellowshipEditorComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "approver",
        component: ListComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
    {
        canActivate: [IndexGuard],
        path: "residency-approver",
        component: ResidencyApproverComponent,
        resolve: {
            userData: UserDataResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {}
