import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { AuthConstants } from "src/app/config/auth-constants";
import { Router } from "@angular/router";
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    constructor(private auth: AuthService, private route: Router) {}
    username: any;
    password: any;
    userType;
    ngOnInit(): void {}
    processLogin() {
        let loginResponse = [];
        let strapiLogin = {
            identifier: "",
            password: "",
        };
        strapiLogin.identifier = this.username;
        strapiLogin.password = this.password;

        ////////console.log(this.username);
        ////////console.log(this.password);
        this.auth.strapiLogin(strapiLogin).subscribe(
            (data) => {
                loginResponse = JSON.parse("[" + JSON.stringify(data) + "]");
            },
            (error) => {
                ////////console.log(error);
            },
            () => {
                ////console.log(loginResponse);

                if (loginResponse) {
                    if (loginResponse[0].user.type == "irb") {
                    } else {
                        this.auth.store(AuthConstants.AUTH, loginResponse);
                        localStorage.setItem("id_token", loginResponse[0].jwt);
                        if (loginResponse[0].user.is_approver) {
                            localStorage.setItem("is_approver", "1");
                            this.getApprover(loginResponse[0].user.id);
                            let userData = this.auth.getUserData();
                            this.userType = userData[0].user.type;
                            if (this.userType == "HRAPPROVER") {
                                this.redirectEditor(
                                    "chhadmin/careers-approver"
                                );
                            } else {
                                this.redirectEditor("chhadmin/approver");
                            }
                        }
                        if (loginResponse[0].user.is_editor) {
                            localStorage.setItem("is_editor", "1");
                            this.getApprover(loginResponse[0].user.id);

                            let userData = this.auth.getUserData();
                            this.userType = userData[0].user.type;
                            if (this.userType == "HRUSER") {
                                this.redirectEditor("chhadmin/careers-editor");
                            } else {
                                this.redirectEditor("chhadmin/home");
                            }
                        }
                    }
                }
            }
        );
    }
    redirectEditor(x) {
        this.route.navigate([x]).then(() => {});
    }
    getApprover(id) {
        this.auth.getApprover(id).subscribe((res: any) => {
            if (res.data[0].attributes.approver_id1 != null) {
                localStorage.setItem(
                    "approver_id1",
                    res.data[0].attributes.approver_id1
                );
            }
            if (res.data[0].attributes.approver_id2 != null) {
                localStorage.setItem(
                    "approver_id2",
                    res.data[0].attributes.approver_id2
                );
            }
        });
    }
}
