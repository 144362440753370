import { Component, OnInit } from "@angular/core";

@Component({
    selector: "spinnerV1",
    templateUrl: "./spinner-v1.component.html",
    styleUrls: ["./spinner-v1.component.scss"],
})
export class SpinnerV1Component implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
