import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-aboutus-editor",
    templateUrl: "./aboutus-editor.component.html",
    styleUrls: ["./aboutus-editor.component.scss"],
})
export class AboutusEditorComponent implements OnInit {
    title;
    content;
    description;
    progress;
    message;
    toUploadBigFile;
    formData;
    isUploadStatus;
    imglinkBig = "";
    img_idBig;
    img_hashBig;

    addDoc = {
        data: {
            title: "",
            aboutData: "",
            aboutImage: "",
            aboutImage_hash: "",
            aboutImage_id: null,
            visionMissionData: "",
            visionMissionImage: "",
            visionMissionImage_hash: "",
            visionMissionImage_id: null,
        },
    };
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone
    ) {}
    id;
    promos;
    ngOnInit(): void {
        this.getSlider();
    }
    getSlider() {
        this.general.get("about-uses").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data);

                this.id = data.data[0].id;

                this.addDoc.data.title = data.data[0].attributes.title;
                this.addDoc.data.aboutData = data.data[0].attributes.aboutData;
                this.addDoc.data.visionMissionData =
                    data.data[0].attributes.visionMissionData;
                this.addDoc.data.aboutImage =
                    data.data[0].attributes.aboutImage;
                this.addDoc.data.aboutImage_id =
                    data.data[0].attributes.aboutImage_id;

                this.addDoc.data.visionMissionImage_id =
                    data.data[0].attributes.visionMissionImage_id;
                this.addDoc.data.visionMissionImage_hash =
                    data.data[0].attributes.visionMissionImage_hash;
                this.addDoc.data.visionMissionImage =
                    data.data[0].attributes.visionMissionImage;

                //console.log(this.addDoc);
            },
        });
    }
    selectbigImg(files) {
        this.toUploadBigFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_big")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
    }
    toUploadSmallFile;
    selectsmallImg(files) {
        ////console.log("selectsmallImg");

        this.toUploadSmallFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_small")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
    }
    remove() {
        ////console.log(this.id);
        ////console.log(this.addDoc.data.aboutImage_id);
        this.general
            .delete("upload/files", this.addDoc.data.aboutImage_id)
            .subscribe({
                complete: () => {
                    this.addFile();
                },
                error: (error) => {},
                next: (data: any) => {
                    this.addDoc.data.aboutImage_hash = null;
                    this.img_hashBig = null;
                    this.addDoc.data.aboutImage = null;
                    this.imglinkBig = null;
                    this.addDoc.data.aboutImage_id = null;
                    this.img_idBig = null;
                },
            });
    }
    startUpload() {
        this.uploadFileBig(this.toUploadBigFile);
    }
    public uploadFileBig = (files) => {
        if (files == null) {
            // this.addFile();
            this.uploadFilesmall(this.toUploadSmallFile);
        } else {
            let fileToUpload = files.target.files[0];
            let fileName: string =
                "AboutUs-" + this.funcServ.generateYMDHMS(new Date());
            let fileExtension: string = fileToUpload.name
                .split("?")[0]
                .split(".")
                .pop();
            //////console.log(fileExtension);

            if (fileExtension) {
                this.formData = new FormData();
                this.formData.append(
                    "files",
                    fileToUpload,
                    fileName + "." + fileExtension
                );
                this.http
                    .post(
                        environment.apiRouterUrl + "api/upload",
                        this.formData,
                        {
                            headers: {},
                            reportProgress: true,
                            observe: "events",
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress)
                                this.progress = Math.round(
                                    (100 * event.loaded) / event.total
                                );
                            else if (event.type === HttpEventType.Response) {
                                this.message = "Upload success.";
                            }
                            if (event.body != undefined) {
                                //////console.log("EVENT RESPONSE");

                                ////console.log(event.body);
                                this.isUploadStatus = false;
                                this.imglinkBig = event.body[0].url;
                                this.img_idBig = event.body[0].id;
                                this.img_hashBig = event.body[0].hash;
                                this.addDoc.data.aboutImage_hash =
                                    this.img_hashBig;
                                this.addDoc.data.aboutImage = this.imglinkBig;
                                this.addDoc.data.aboutImage_id = this.img_idBig;
                                this.uploadFilesmall(this.toUploadSmallFile);
                            } else {
                                ////////console.log("error");
                                this.isUploadStatus = true;
                            }
                        },
                        (error) => {
                            ////////console.log(error);
                        },
                        () => {}
                    );
            }
        }
    };
    imglinkBig1;
    img_idBig1;
    img_hashBig1;
    public uploadFilesmall = (files) => {
        if (files == null) {
            this.addFile();
        } else {
            let fileToUpload = files.target.files[0];
            let fileName: string =
                "visionMission-" + this.funcServ.generateYMDHMS(new Date());
            let fileExtension: string = fileToUpload.name
                .split("?")[0]
                .split(".")
                .pop();
            //////console.log(fileExtension);

            if (fileExtension) {
                this.formData = new FormData();
                this.formData.append(
                    "files",
                    fileToUpload,
                    fileName + "." + fileExtension
                );
                this.http
                    .post(
                        environment.apiRouterUrl + "api/upload",
                        this.formData,
                        {
                            headers: {},
                            reportProgress: true,
                            observe: "events",
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress)
                                this.progress = Math.round(
                                    (100 * event.loaded) / event.total
                                );
                            else if (event.type === HttpEventType.Response) {
                                this.message = "Upload success.";
                            }
                            if (event.body != undefined) {
                                //////console.log("EVENT RESPONSE");

                                //console.log(event.body);
                                this.isUploadStatus = false;
                                this.imglinkBig1 = event.body[0].url;
                                this.img_idBig1 = event.body[0].id;
                                this.img_hashBig1 = event.body[0].hash;
                                this.addDoc.data.visionMissionImage_hash =
                                    this.img_hashBig1;
                                this.addDoc.data.visionMissionImage =
                                    this.imglinkBig1;
                                this.addDoc.data.visionMissionImage_id =
                                    this.img_idBig1;

                                this.addFile();
                            } else {
                                ////////console.log("error");
                                this.isUploadStatus = true;
                            }
                        },
                        (error) => {
                            ////////console.log(error);
                        },
                        () => {}
                    );
            }
        }
    };
    url = "https://strapi.chonghua.com.ph";
    addFile() {
        ////console.log(this.addDoc);
        this.general.put("about-uses", this.addDoc, this.id).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {
                ////console.log(error);
            },
            next: (data: any) => {
                ////console.log(data);
            },
        });
        /*this.general.add("about-chhcs", this.addDoc).subscribe({
            complete: () => {
                this.toUploadBigFile = "";
            },
            error: (error) => {
                ////console.log(error);
            },
            next: (data: any) => {
                ////console.log(data);

                this.title = "";
                this.content = "";
                this.description = "";
            },
        });*/
    }
    removesmall() {
        //console.log(this.addDoc.data.visionMissionImage_id);

        this.general
            .delete("upload/files", this.addDoc.data.visionMissionImage_id)
            .subscribe({
                complete: () => {
                    this.addFile();
                },
                error: (error) => {},
                next: (data: any) => {
                    this.addDoc.data.visionMissionData = null;
                    this.img_hashBig1 = null;
                    this.addDoc.data.visionMissionImage = null;
                    this.imglinkBig1 = null;
                    this.addDoc.data.visionMissionImage_id = null;
                    this.img_idBig1 = null;
                },
            });
    }
}
