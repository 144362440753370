<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>What's New</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>{{ h1 }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!--div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img
                            src="../../../../../assets/img/events/masrise.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
      
                    <h2>1st MAS Postgraduate Course</h2>
                    <div>
                        The Medical Ancillary Services Division of Chong Hua
                        Hospital will be having its 1st Postgraduate Course via
                        Zoom on August 10-11, 2023, with the theme,
                        “Future-Proofing Healthcare: Towards a Sustainable,
                        Resilient, Innovative, and Safe Quality Health Service
                        Delivery.” It is open to Doctors, Nurses, Allied
                        Healthcare Professionals, and Students. <br /><br />This
                        postgraduate program is intended to prepare healthcare
                        professionals with the information and skills necessary
                        to future-proof healthcare systems in terms of
                        sustainability, resilience, innovation, and safety in
                        the delivery of quality healthcare. To maintain the
                        sustainability of healthcare systems, healthcare
                        professionals must be proactive in adjusting to emerging
                        trends and problems due to the quickly changing
                        healthcare environment. <br /><br />This course will
                        examine the fundamental concepts and guiding principles
                        of future-proofing healthcare systems, such as
                        sustainability, resilience, innovation, and safety.
                        Participants will gain knowledge of the most recent
                        healthcare trends and technologies, as well as
                        implementation strategies for these advances. In
                        addition, they will comprehend the significance of
                        safety culture and practices in the delivery of quality
                        healthcare. <br /><br />Participants will engage in a
                        learning experience that fosters critical thinking and
                        problem-solving abilities through interactive lectures,
                        case studies, and group discussions. In addition, they
                        will get the chance to network and cooperate with
                        healthcare experts from a variety of disciplines and
                        locations
                    </div>

                    <a href="/mas-rise" class="pointHand">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</div-->
<!--div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img
                            src="../../../../../assets/img/events/RND Summit FB.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    
                    <h2>The RND Summit</h2>
                    <div>
                        The Food and Nutrition Services of Chong Hua Hospital
                        will hold the first RND Summit a two-day face-to-face
                        conference on July 29 and July 31, 2023 at the Chong Hua
                        Hospital-Mandaue 12th floor with the theme “Synergistic
                        Opportunities: Convergence of Public Health Nutrition
                        and Clinical Nutrition<br /><br /><br />The aim of the
                        conference is to promote connection between community,
                        public health, and clinical nutrition as well as
                        suggestions for creative techniques that leverage each
                        field’s advantages to produce an overall effect in the
                        communities being served and enabling individuals,
                        groups, systems, and organizations to achieve essential
                        goals in an effective, efficient, and sustainable
                        manner. This summit will be participated by Registered
                        Dietitians from Visayas, and Mindanao.
                    </div>

                    <a href="/rnd-summit" class="pointHand">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</div-->

<div class="about-area pt-100 pb-70" *ngFor="let x of promos">
    <div class="container">
        <div class="row align-items-center">
            <ng-container *ngIf="x.attributes.images != ''">
                <div class="col-lg-6">
                    <div class="about-item">
                        <div class="about-left">
                            <img
                                [src]="url + x.attributes.images"
                                alt="About"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="about-items about-right">
                        <!--img src="assets/img/home-one/home-bg5.png" alt="About"-->
                        <h2>{{ x.attributes.title }}</h2>
                        <div [innerHtml]="x.attributes.description"></div>

                        <a (click)="downloadme(x.id)" class="pointHand"
                            >Learn More</a
                        >
                    </div>
                </div></ng-container
            >
            <ng-container *ngIf="x.attributes.images == ''">
                <div class="col-lg-8 offset-lg-2">
                    <div class="about-items about-right">
                        <!--img src="assets/img/home-one/home-bg5.png" alt="About"-->
                        <h2>{{ x.attributes.title }}</h2>
                        <div [innerHtml]="x.attributes.description"></div>

                        <a (click)="downloadme(x.id)" class="pointHand"
                            >Learn More</a
                        >
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><br /></div>
        </div>
    </div>
</div>
