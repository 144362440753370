import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClinicHelperComponent } from "./clinic-helper/clinic-helper.component";
import { FooterComponent } from "./footer/footer.component";
import { FormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { NavbarComponent } from "./navbar/navbar.component";
import { RouterModule } from "@angular/router";
import { PageTitleComponent } from "./page-title/page-title.component";
import { PageTitle1Component } from "./page-title1/page-title1.component";
import { PreloaderComponent } from "./preloader/preloader.component";
import { Irb2NavbarComponent } from "./irb2-navbar/irb2-navbar.component";
import { Irb2FooterComponent } from "./irb2-footer/irb2-footer.component";
import { ParentNavComponent } from './irb2-navbar/parent-nav/parent-nav.component';
import { ChildNavComponent } from './irb2-navbar/parent-nav/child-nav/child-nav.component';
import { GrandchildNavComponent } from './irb2-navbar/parent-nav/child-nav/grandchild-nav/grandchild-nav.component';

@NgModule({
    declarations: [
        ClinicHelperComponent,
        FooterComponent,
        NavbarComponent,
        PageTitleComponent,
        PageTitle1Component,
        PreloaderComponent,
        Irb2NavbarComponent,
        Irb2FooterComponent,
        ParentNavComponent,
        ChildNavComponent,
        GrandchildNavComponent,
    ],
    imports: [CommonModule, FormsModule, NgxCaptchaModule, RouterModule],
    exports: [
        ClinicHelperComponent,
        FooterComponent,
        NavbarComponent,
        PageTitleComponent,
        PageTitle1Component,
        PreloaderComponent,
        Irb2NavbarComponent,
        Irb2FooterComponent,
    ],
})
export class SharedModule {}
