<app-page-title1 pageTitle="Careers Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6" style="top: 0">
                <div class="signup-form">
                    <form #myForm="ngForm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Job title
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Page Title"
                                        [(ngModel)]="jobTitle1"
                                        (keyup)="forJobTItleLink()"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Job title link
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        placeholder="Job title link"
                                        [(ngModel)]="jobTitleLink"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    short desc
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="short desc (ASAP), (ACTIVELY HIRING)"
                                        [(ngModel)]="short_Desc"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Category
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Category"
                                        [(ngModel)]="category"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Type
                                    <select
                                        name="cars"
                                        id="cars"
                                        class="form-control"
                                        [(ngModel)]="type"
                                        [ngModelOptions]="{ standalone: true }"
                                    >
                                        <option value="Full-Time">
                                            Full-Time
                                        </option>
                                        <option value="Part-Time">
                                            Part-Time
                                        </option>
                                        <option value="Contractual">
                                            Contractual
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                Qualifications
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="qualifications"
                                        [ngModelOptions]="{ standalone: true }"
                                    >
                                    </ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-12">
                                Contact
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="contact"
                                        [ngModelOptions]="{ standalone: true }"
                                    >
                                    </ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div
                                    class="text-center"
                                    *ngIf="!isUpdatemeCLicked"
                                >
                                    <button
                                        type="submit"
                                        class="btn btn-success"
                                        (click)="addCareer()"
                                    >
                                        Add
                                    </button>
                                </div>
                                <div
                                    class="text-center"
                                    *ngIf="isUpdatemeCLicked"
                                >
                                    <button
                                        type="submit"
                                        class="btn btn-success"
                                        (click)="updateCareer()"
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="text-center"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="container">
                    <div class="">
                        <div
                            class="row service-item-custom pointHand"
                            *ngFor="let x of careerList"
                        >
                            <div
                                class="col-lg-9"
                                style="top: 0"
                                (click)="updateMe(x.id, x)"
                            >
                                <span
                                    [ngClass]="
                                        colorBG(
                                            x.attributes.approver_id1_status
                                        )
                                    "
                                    >{{ x.attributes.job_title }}</span
                                >
                            </div>
                            <div class="col-lg-3">
                                <span
                                    class="btn btn-danger"
                                    (click)="delete(x.id)"
                                    >Delete</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="blog-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div
                        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
                        data-wow-delay=".3s"
                    >
                        <br />
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
