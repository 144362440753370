import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
@Component({
    selector: "app-promos-packages",
    templateUrl: "./promos-packages.component.html",
    styleUrls: ["./promos-packages.component.scss"],
})
export class PromosPackagesComponent implements OnInit {
    routerSubscription;
    constructor(
        private promoService: PromosService,
        private sanitizer: DomSanitizer,
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private GenServ: GeneralService,
        private router: Router
    ) {
        this.routerSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                filter(() => this.isWithinParentRoute())
            )
            .subscribe((event: NavigationEnd) => {
                console.log("123");

                this.apiCall();
            });
    }
    private isWithinParentRoute(): boolean {
        const currentRoute = this.router.url;
        return currentRoute.includes("whats-new");
    }
    url = "https://strapi.chonghua.com.ph";
    type;
    h1;
    ngOnInit(): void {
        this.apiCall();
    }
    promos;
    apiCall() {
        this.type = this.activatedRoute.snapshot.params.type;
        if (this.type == "promos-packages") {
            this.getPromo("promos-packages");
            this.h1 = "Promos & Packages";
        } else if (this.type == "news") {
            this.getPromo("news-portals");
            this.h1 = "News";
        } else if (this.type == "patient-education") {
            this.getPromo("patient-educations");
            this.h1 = "Patient Education";
        }
    }
    getPromo(data) {
        this.GenServ.getCustomQuery(
            "api/" +
                data +
                "?filters[approver_id1_status][$eq]=true&sort=id:DESC"
        ).subscribe({
            complete: () => {},
            error: (error) => {},
            next: (res: any) => {
                this.promos = [];
                res.data.forEach((element) => {
                    this.promos.push(element);
                });
            },
        });
        /*this.promoService.getPromo().subscribe({
            complete: () => {},
            error: (error) => {},
            next: (res: any) => {
                this.promos = [];
                res.data.forEach((element) => {
                    element.description =
                        this.sanitizer.bypassSecurityTrustHtml(
                            element.description
                        );
                    this.promos.push(element);
                });
            },
        });*/
    }

    downloadme(data) {
        //console.log(data);
        //console.log(this.url + data);

        this.route.navigate(["/whats-new/" + this.type + "/" + data]);
    }
    ngOnDestroy() {
        // Unsubscribe from the router events to avoid memory leaks
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
}
