<page-title pageTitle="Careers" [bacg]="bacg"></page-title>

<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="about-item about-right">
                    <img
                        src="assets/img/home-one/home-bg5.png"
                        class="img-responsive"
                        alt="About"
                    />
                    <div [innerHTML]="addDoc.data.visionMissionData"></div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <p style="margin-left: 0cm; margin-right: 0cm">
                    <span style="font-size: 11pt"
                        ><span style="background-color: white"
                            ><span style="font-family: Calibri, sans-serif"
                                ><strong
                                    ><span style="font-size: 12pt"
                                        ><span
                                            style="
                                                font-family: 'Arial', sans-serif;
                                            "
                                            ><span style="color: #538135"
                                                >Core values</span
                                            ></span
                                        ></span
                                    ></strong
                                ></span
                            ></span
                        ></span
                    >
                </p>
                <img
                    src="{{ addDoc.data.visionMissionImage }}"
                    class="img-responsive"
                    alt="About"
                />
            </div>
        </div>
    </div>
</section>
<div class="about-area pt-90 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img
                            src="{{ addDoc.data.aboutImage }}"
                            alt="About"
                            class="img-responsive"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About" />
                    <h2>{{ addDoc.data.title }}</h2>
                    <p [innerHTML]="addDoc.data.aboutData"></p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-90 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="assets/img/Quality Policy ver01_07.01.2022.jpg"
                            alt="About"
                            class="img-responsive"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <img
                    src="assets/img/Public Advisory Poster copy.jpg"
                    class="img-responsive"
                    alt="About"
                />
            </div>
            <!--div class="col-lg-6">
                <img
                    src="assets/img/2024 Certificate of Registration_National Privacy Commission 1-pdf.png"
                    class="img-responsive"
                    alt="About"
                />
            </div-->
            <div class="col-lg-6">
                <img
                    src="assets/img/NPC COR Seal.jpg"
                    class="img-responsive"
                    alt="About"
                />
            </div>

            <div class="col-lg-6">
                <!--div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About" />
                    <h2>{{ addDoc.data.title }}</h2>
                    <p [innerHTML]="addDoc.data.aboutData"></p>
                </div-->
            </div>
        </div>
    </div>
</div>
