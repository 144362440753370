import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthConstants } from "../config/auth-constants";
@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(private http: HttpClient) {}
    store(storageKey: string, value: any) {
        const encryptedValue = btoa(escape(JSON.stringify(value)));
        return localStorage.setItem(storageKey, encryptedValue);
    }
    get(storageKey: string) {
        return localStorage.getItem(storageKey);
    }
    getUserData() {
        if (localStorage.getItem(AuthConstants.AUTH) != null) {
            return JSON.parse(
                unescape(atob(localStorage.getItem(AuthConstants.AUTH)))
            );
        } else {
            return null;
        }
    }
    strapiLogin(data) {
        //console.log(data);

        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + "api/auth/local";
        return this.http.post(url, data, options);
    }

    getApprover(id) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: false };
        const url =
            environment.apiRouterUrl +
            "api/approver-and-editors?filters[editor_id][$eq]=" +
            id;
        return this.http.get(url);
    }
}
