import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-hmoeditor",
    templateUrl: "./hmoeditor.component.html",
    styleUrls: ["./hmoeditor.component.scss"],
})
export class HmoeditorComponent implements OnInit {
    right;
    left;
    specialexam;
    progress;
    message;
    toUploadBigFile;
    formData;
    isUploadStatus;
    contact = "";
    name = "";
    img_idBig;
    img_hashBig;
    addDoc = {
        data: {
            left: "",
            right: "",
        },
    };
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.getSlider();
    }
    promos;

    getSlider() {
        this.general.get("insurance-catalogs").subscribe({
            complete: () => {
                console.log(this.promos);
            },
            error: (error) => {},
            next: (data: any) => {
                this.promos = data.data;
            },
        });
    }

    id;
    addFile() {
        this.addDoc.data.right = this.right;
        this.addDoc.data.left = this.left;

        /*this.general.put("insurance-catalogs", this.addDoc, this.id).subscribe({
            complete: () => {},
            error: (error) => {
                console.log(error);
            },
            next: (data: any) => {
                console.log(data);
            },
        });*/
        this.general.add("insurance-catalogs", this.addDoc).subscribe({
            complete: () => {
                this.getSlider();
            },
            error: (error) => {
                console.log(error);
            },
            next: (data: any) => {
                console.log(data);
            },
        });
        //add
    }
    isModify: boolean = false;
    modify(x) {
        this.isModify = true;
        this.right = x.attributes.right;
        this.left = x.attributes.left;

        this.id = x.id;
    }
    clear() {
        this.isModify = false;
        this.right = "";
        this.left = "";
    }
    update() {
        this.addDoc.data.right = this.right;
        this.addDoc.data.left = this.left;

        this.general.put("insurance-catalogs", this.addDoc, this.id).subscribe({
            complete: () => {
                this.clear();
                this.getSlider();
            },
            error: (error) => {
                console.log(error);
            },
            next: (data: any) => {
                console.log(data);
            },
        });
    }
}
