<page-title pageTitle="" [bacg]="bacg"></page-title>

<!--div class="page-title-area page-title-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-itemwo">
                <h2>
                    Dr. {{ selectedDoctors.firstName }}
                    {{ selectedDoctors.lastName }}
                </h2>
                <h3>{{ selectedDoctors.deptCode }}</h3>
                <p>{{ selectedDoctors.expertise }}</p>
            </div>
        </div>
    </div>
</div-->
<!--page-title pageTitle=""></page-title-->

<div class="doctor-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="doctor-details-item1 doctor-details-left1 pb-70">
                    <br />
                    <br />

                    <div
                        class="doctor-details-contact"
                        *ngFor="let xyz of selectedDoctors"
                    >
                        <!--h2>
                            Dr. {{ selectedDoctors.firstName }}
                            {{ selectedDoctors.middleName }}
                            {{ selectedDoctors.lastName }}
                        </h2-->

                        <h2 class="chhgreen" *ngIf="xyz.doctorsName != null">
                            {{ xyz.doctorsName }}
                        </h2>
                        <h2 class="chhgreen" *ngIf="xyz.doctorsName == null">
                            {{ xyz.firstName }}
                            {{ xyz.lastName }}, M.D.
                        </h2>
                        <h3>
                            <dept-helper
                                [specialization]="xyz.specialization"
                            ></dept-helper>
                            <!--dept-helper
                                [reCeiver]="selectedDoctors.deptCode"
                                [bigFont]="true"
                            ></dept-helper-->
                            <br />
                            <subspec-helper
                                [subspecialty]="xyz.subSpecialization"
                                [smallFont]="false"
                            ></subspec-helper>
                        </h3>
                        <b>Hospital Appointment</b><br />
                        <div *ngIf="xyz.hospitalAppointment == 'Both'">
                            <span
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chong Hua
                                Hospital</span
                            ><br />
                            <span
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chong Hua
                                Hospital Mandaue</span
                            >
                        </div>
                        <div
                            *ngIf="
                                (xyz.hospitalAppointment == 'Cebu' ||
                                    xyz.hospitalAppointment ==
                                        'Chong Hua Hospital') &&
                                xyz.hospitalAppointment != 'Both'
                            "
                        >
                            <span
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chong Hua
                                Hospital</span
                            >
                        </div>
                        <div
                            *ngIf="
                                (xyz.hospitalAppointment == 'Mandaue' ||
                                    xyz.hospitalAppointment ==
                                        'Chong Hua Hospital Mandaue') &&
                                xyz.hospitalAppointment != 'Both'
                            "
                        >
                            <span
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chong Hua
                                Hospital Mandaue</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h3>Clinic Schedule</h3>
                            <table class="table">
                                <tbody *ngFor="let x of clinicList">
                                    <tr>
                                        <td>Site</td>
                                        <td>
                                            <dept-helper
                                                [site]="x.site"
                                                [bigFont]="true"
                                            ></dept-helper>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Building</td>
                                        <td>{{ x.clinic_Building }}</td>
                                    </tr>
                                    <tr>
                                        <td>Room #</td>
                                        <td>{{ x.clinic_Room_No }}</td>
                                    </tr>
                                    <tr>
                                        <td>Schedule</td>
                                        <td>
                                            <app-clinic-helper
                                                [clinic_Schedule]="
                                                    x.clinic_Schedule
                                                "
                                            ></app-clinic-helper>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Contact #</td>
                                        <td>
                                            <app-clinic-helper
                                                [clinic_Contact_No]="
                                                    x.clinic_Contact_No
                                                "
                                            ></app-clinic-helper>
                                        </td>
                                    </tr>
                                    <tr *ngIf="x.clinic_Secretary != ' '">
                                        <td>Secretary</td>
                                        <td>
                                            {{ x.clinic_Secretary }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
