import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-services-editor",
    templateUrl: "./services-editor.component.html",
    styleUrls: ["./services-editor.component.scss"],
})
export class ServicesEditorComponent implements OnInit {
    addSlider = {
        data: {
            Title: "",
            Details: "",
            SmallTitle: "",
            SmallData: "",
            TitleImg: "",
            TitleImgId: 0,
            SmallImg: "",
            SmallImgId: 0,
            link: "",
        },
    };
    title;
    content;
    description;
    progress;
    message;
    toUploadBigFile;
    formData;
    isUploadStatus;
    imglinkBig = "";
    img_idBig;
    img_hashBig;
    url = "https://strapi.chonghua.com.ph";
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.selectedId = "";
        this.getCareer();
    }
    serviceList;
    getCareer() {
        this.general
            .get("service-details?sort=link:ASC&pagination[pageSize]=99999")
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (data: any) => {
                    console.log(data.data);

                    this.serviceList = data.data;

                    ////console.log(this.careerList);
                },
            });
    }
    selectbigImg(files) {
        this.toUploadBigFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_big")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
    }
    startUpload() {
        this.uploadFileBig(this.toUploadBigFile);
    }
    public uploadFileBig = (files) => {
        if (files == null) {
            this.uploadFilesmall(this.toUploadSmallFile);
        } else {
            let fileToUpload = files.target.files[0];
            let fileName: string =
                "ServBig-" + this.funcServ.generateYMDHMS(new Date());
            let fileExtension: string = fileToUpload.name
                .split("?")[0]
                .split(".")
                .pop();
            ////console.log(fileExtension);

            if (fileExtension) {
                this.formData = new FormData();
                this.formData.append(
                    "files",
                    fileToUpload,
                    fileName + "." + fileExtension
                );
                this.http
                    .post(
                        environment.apiRouterUrl + "api/upload",
                        this.formData,
                        {
                            headers: {},
                            reportProgress: true,
                            observe: "events",
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress)
                                this.progress = Math.round(
                                    (100 * event.loaded) / event.total
                                );
                            else if (event.type === HttpEventType.Response) {
                                this.message = "Upload success.";
                            }
                            if (event.body != undefined) {
                                this.isUploadStatus = false;
                                this.addSlider.data.TitleImg =
                                    event.body[0].url;
                                this.addSlider.data.TitleImgId =
                                    event.body[0].id;
                                this.uploadFilesmall(this.toUploadSmallFile);
                            } else {
                                //////console.log("error");
                                this.isUploadStatus = true;
                            }
                        },
                        (error) => {
                            //////console.log(error);
                        },
                        () => {}
                    );
            }
        }
    };
    imglinkBig1;
    img_idBig1;
    img_hashBig1;
    public uploadFilesmall = (files) => {
        if (files == null) {
            //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            //console.log(this.isUpdate);

            // this.addFile();
            if (this.isUpdate) {
                this.updateMe(this.selectedId);
            } else {
                this.addFile();
            }
        } else {
            let fileToUpload = files.target.files[0];
            let fileName: string =
                "servSmall-" + this.funcServ.generateYMDHMS(new Date());
            let fileExtension: string = fileToUpload.name
                .split("?")[0]
                .split(".")
                .pop();
            if (fileExtension) {
                this.formData = new FormData();
                this.formData.append(
                    "files",
                    fileToUpload,
                    fileName + "." + fileExtension
                );
                this.http
                    .post(
                        environment.apiRouterUrl + "api/upload",
                        this.formData,
                        {
                            headers: {},
                            reportProgress: true,
                            observe: "events",
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress)
                                this.progress = Math.round(
                                    (100 * event.loaded) / event.total
                                );
                            else if (event.type === HttpEventType.Response) {
                                this.message = "Upload success.";
                            }
                            if (event.body != undefined) {
                                this.isUploadStatus = false;
                                this.imglinkBig1 = event.body[0].url;
                                this.img_idBig1 = event.body[0].id;
                                this.addSlider.data.SmallImg =
                                    event.body[0].url;
                                this.addSlider.data.SmallImgId =
                                    event.body[0].id;
                                if (this.isUpdate) {
                                    this.updateMe(this.selectedId);
                                } else {
                                    this.addFile();
                                }
                            } else {
                                this.isUploadStatus = true;
                            }
                        },
                        (error) => {},
                        () => {}
                    );
            }
        }
    };
    toUploadSmallFile;
    selectsmallImg(files) {
        ////console.log("selectsmallImg");

        this.toUploadSmallFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_small")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
    }
    addFile() {
        //console.log(this.addSlider);
        this.general.add("service-details", this.addSlider).subscribe({
            complete: () => {
                this.toUploadBigFile = "";
                this.toUploadSmallFile = "";
                this.addSlider = {
                    data: {
                        Title: "",
                        Details: "",
                        SmallTitle: "",
                        SmallData: "",
                        TitleImg: "",
                        TitleImgId: 0,
                        SmallImg: "",
                        SmallImgId: 0,
                        link: "",
                    },
                };
                this.ngOnInit();
            },
            error: (error) => {},
            next: (data: any) => {},
        });
    }
    jobTitleLink;
    forJobTItleLink() {
        let inString = this.addSlider.data.Title.toLowerCase();
        let outString;
        outString = inString.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            ""
        );
        outString = inString.split(" ").join("-");
        this.addSlider.data.link = outString;
    }
    selectedId = "";
    isUpdate: boolean = false;
    Choose(x) {
        this.isUpdate = true;
        this.selectedId = x.id;
        this.addSlider.data.Title = x.attributes.Title;
        this.addSlider.data.Details = x.attributes.Details;
        this.addSlider.data.SmallTitle = x.attributes.SmallTitle;
        this.addSlider.data.SmallData = x.attributes.SmallData;
        this.addSlider.data.link = x.attributes.link;
        this.addSlider.data.TitleImg = x.attributes.TitleImg;
        this.addSlider.data.TitleImgId = x.attributes.TitleImgId;
        this.addSlider.data.TitleImg = x.attributes.TitleImg;
        this.addSlider.data.SmallImgId = x.attributes.SmallImgId;
    }
    update() {
        if (
            this.toUploadBigFile == null ||
            this.toUploadBigFile == "" ||
            this.toUploadBigFile == undefined
        ) {
            ////console.log("null");
        } else {
            this.remove(this.addSlider.data.TitleImgId);
        }
        if (
            this.toUploadSmallFile == null ||
            this.toUploadSmallFile == "" ||
            this.toUploadSmallFile == undefined
        ) {
            //console.log("null");
        } else {
            this.remove(this.addSlider.data.SmallImg);
        }
        //console.log(this.selectedId);
        this.startUpload();
    }
    remove(x) {
        this.general.delete("upload/files", x).subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {},
        });
    }
    updateMe(x) {
        this.general.put("service-details", this.addSlider, x).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {
                ////console.log(error);
            },
            next: (data: any) => {
                ////console.log(data);
            },
        });
    }
    testsss(s) {
        if (
            s.includes("residency-") ||
            s.includes("fellowship-") ||
            s.includes("clinical-department-") ||
            s.includes("service-")
        ) {
            return "boldMe";
        }
    }
}
