<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Privacy Policy Notice</h2>
            </div>
        </div>
    </div>
</div>
<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <br />
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div>
                    <p>
                        As required by law, this notice provides you with
                        information about your rights and our legal duties and
                        privacy practices with respect to the privacy of your
                        personal information stored in our website. This notice
                        also discusses the uses and disclosures we will make of
                        your personal information. We reserve the right to
                        change the terms of this notice from time to time and to
                        make the revised notice effective for all personal and
                        sensitive information we maintain.
                        <br /><br />
                        Although Chong Hua Hospital exercises every effort to
                        ensure that the materials in its main portal are
                        accurate and timely, this is not always possible. We
                        encourage you to call us at +63 32 255 8000 to verify
                        critical information.
                        <br /><br /><br />
                        <b>WEB LOG FILE DATA</b><br />
                        You can volunteer to give us personal information or you
                        can visit our site without submitting any information
                        about yourself. If you fill out the forms on the site or
                        send us email, logically we will have your name, e-mail
                        address and other information you gave us voluntarily.
                        We will use them only for replying to your message or
                        forwarding the requested material. We will not share
                        this information with any other partners, affiliates or
                        persons and organizations outside our organization.
                        <br /><br /><br />
                        <b>ONLINE FORMS AND DATA SECURITY</b><br />
                        Chong Hua Hospital provides intake forms on our website
                        for ECU/EHU reservation, which may request confidential
                        information. Rest assured that these online forms are
                        located on our secure site. Chong Hua Hospital also
                        provides results on our patients’ portal in the website,
                        which can be accessed by the patient concern. Only you
                        can access these information. Consequently, you are
                        discouraged to share this information <br /><br />
                        We guarantee that we will not share this information
                        with any other partners, affiliates or persons and
                        organizations outside our organization. Although,
                        information transmitted over the Internet cannot be
                        guaranteed, we at Chong Hua Hospital, however, makes
                        every reasonable effort to ensure that all of the
                        transactions that occur on our website are secure.<br /><br />
                        We urge you to discuss information particularly health
                        related concerns personally with your physician or
                        health provider and not through electronic or email.
                        <br /><br /><br />
                        <b>HOW WE USE INFORMATION</b><br />
                        Chong Hua Hospital uses the information you provide to
                        fulfill your online request, facilitate customer service
                        and inform you of our programs and events. It is
                        possible, however, that we could be forced to disclose
                        personally identifiable user information in response to
                        a search warrant, subpoena, or court order.
                        <br /><br /><br />
                        <b>RETENTION PERIOD</b><br />
                        We will retain your Personal Information only for the
                        period required or permitted by law.<br /><br /><br />
                        <b>CHONG HUA HOSPITAL CONTACT INFORMATION</b><br />
                        If you have any questions and /or concerns regarding
                        your personal information, you may contact our Data
                        Privacy Officer thru the following contact details:<br /><br />
                        <b>Atty. Geraldine A. Cajilig</b><br />
                        Data Protection Officer<br />
                        Tel #: (032) 255-8000 local 75111 <br />
                        Email address: <br />
                        dataprotection@chonghua.com.ph <br />
                        info@chonghua.com.ph<br />
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
