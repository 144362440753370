<app-page-title1 pageTitle="ECU PLANS EDITOR"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-10">
                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            plan
                                            <input
                                                type="text"
                                                class="form-control"
                                                [(ngModel)]="plan"
                                                [ngModelOptions]="{
                                                    standalone: true
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            name
                                            <input
                                                type="text"
                                                class="form-control"
                                                [(ngModel)]="name"
                                                [ngModelOptions]="{
                                                    standalone: true
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <button
                                            *ngIf="!isModify"
                                            type="button"
                                            class="btn btn-success"
                                            (click)="addFile()"
                                        >
                                            Add
                                        </button>
                                        <button
                                            *ngIf="isModify"
                                            type="button"
                                            class="btn btn-warning"
                                            (click)="clear()"
                                        >
                                            Clear
                                        </button>
                                        <button
                                            *ngIf="isModify"
                                            type="button"
                                            class="btn btn-success"
                                            (click)="update()"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                details
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="details"
                                        [ngModelOptions]="{ standalone: true }"
                                    ></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                specialexam
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="specialexam"
                                        [ngModelOptions]="{ standalone: true }"
                                    >
                                    </ckeditor>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                contact
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="contact"
                                        [ngModelOptions]="{ standalone: true }"
                                    >
                                    </ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>

                            <div class="col-lg-12"><br /><br /><br /></div>

                            <div class="col-lg-12">
                                <div class="text-center"></div>
                            </div>
                            <div class="col-lg-12"></div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-2">
                <div *ngFor="let x of promos">
                    <p class="pointHand" (click)="modify(x)">
                        {{ x.attributes.plan }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
