import { Component, OnInit } from "@angular/core";

import { HttpClient, HttpEventType } from "@angular/common/http";

import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";
@Component({
    selector: "app-news-editor",
    templateUrl: "./news-editor.component.html",
    styleUrls: ["./news-editor.component.scss"],
})
export class NewsEditorComponent implements OnInit {
    pageTitle;
    description;
    addpromo = {
        data: {
            title: "",
            description: "",
            description1: "",
            description2: "",
            description3: "",
            images: "",
            img_id: "",
            img_hash: "",
            img_ext: "",
            expiry: "",
            approver_id1_status: true,
        },
    };
    formData;
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService
    ) {}
    progress;
    message;
    ngOnInit(): void {
        this.pageTitle = "";
        this.description = "";
        this.imglink = "";
        this.isUploadStatus = false;
        this.getPromo();
    }
    expiryDate;
    addPromoPro() {
        this.addpromo.data.title = this.pageTitle;
        this.addpromo.data.description = this.description;
        this.addpromo.data.images = this.imglink;
        this.addpromo.data.img_hash = this.img_hash;
        this.addpromo.data.img_id = this.img_id;
        this.addpromo.data.img_ext = this.img_ext;
        this.addpromo.data.expiry = this.expiryDate;
        this.addpromo.data.approver_id1_status = true;
        ////console.log(this.addpromo);
        this.general.addPost("api/news-portals", this.addpromo).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {
                //console.log(error);
            },
            next: (data: any) => {},
        });
    }
    delete(data, wholeData) {
        this.promoService
            .delete("upload/files", wholeData.attributes.img_id)
            .subscribe({
                complete: () => {
                    //this.ngOnInit();
                },
                error: (error) => {
                    //console.log(error);
                },
                next: (data: any) => {},
            });
        this.general.deletePost("api/news-portals/" + data).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {
                //console.log(error);
            },
            next: (data: any) => {},
        });
    }
    imglink;
    img_hash;
    img_id;
    img_ext;
    isUploadStatus: boolean = false;
    imageDetails;
    public uploadFile = (files) => {
        ////console.log(files);

        const file: File = files.target.files[0];
        let fileToUpload = files.target.files[0];
        let fileName: string = this.funcServ.generateYMDHMS(new Date());
        let fileExtension: string = fileToUpload.name
            .split("?")[0]
            .split(".")
            .pop();
        if (fileExtension) {
            this.formData = new FormData();
            this.formData.append(
                "files",
                fileToUpload,
                fileName + "." + fileExtension
            );
            this.http
                .post(environment.apiRouterUrl + "api/upload", this.formData, {
                    headers: {},
                    reportProgress: true,
                    observe: "events",
                })
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress)
                            this.progress = Math.round(
                                (100 * event.loaded) / event.total
                            );
                        else if (event.type === HttpEventType.Response) {
                            this.message = "Upload success.";
                        }
                        if (event.body != undefined) {
                            ////console.log(event.body);
                            this.isUploadStatus = false;
                            ////console.log(event.body[0].url);
                            this.imglink = event.body[0].url;
                            this.img_id = event.body[0].id;
                            this.img_hash = event.body[0].hash;
                            this.img_ext = event.body[0].ext;
                        } else {
                            ////console.log("error");
                            this.isUploadStatus = true;
                        }
                    },
                    (error) => {
                        ////console.log(error);
                    },
                    () => {}
                );
        }
    };
    promos;
    url = "https://strapi.chonghua.com.ph";
    getPromo() {
        this.general
            .getCustomQuery(
                "api/news-portals?filters[approver_id1_status][$eq]=true&sort=id:DESC"
            )
            .subscribe((res: any) => {
                this.promos = res.data;
            });
    }
    toUploadBigFile: any;
    selectbigImg(files) {
        this.toUploadBigFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_big")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
    }
    startUpload() {
        this.uploadFileBig(this.toUploadBigFile);
    }
    imgCount;
    imglinkBig;
    img_idBig;
    img_hashBig;
    bigImgID;
    img_extBig;
    public uploadFileBig = (files) => {
        if (files) {
            let fileToUpload = files.target.files[0];
            let fileName: string =
                "promos-Big-" + this.funcServ.generateYMDHMS(new Date());
            let fileExtension: string = fileToUpload.name
                .split("?")[0]
                .split(".")
                .pop();
            //////console.log(fileExtension);

            if (fileExtension) {
                this.formData = new FormData();
                this.formData.append(
                    "files",
                    fileToUpload,
                    fileName + "." + fileExtension
                );
                this.http
                    .post(
                        environment.apiRouterUrl + "api/upload",
                        this.formData,
                        {
                            headers: {},
                            reportProgress: true,
                            observe: "events",
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress)
                                this.progress = Math.round(
                                    (100 * event.loaded) / event.total
                                );
                            else if (event.type === HttpEventType.Response) {
                                this.message = "Upload success.";
                            }
                            if (event.body != undefined) {
                                this.isUploadStatus = false;
                                this.imglinkBig = event.body[0].url;
                                this.img_idBig = event.body[0].id;
                                this.img_hashBig = event.body[0].hash;
                                this.bigImgID = event.body[0].id;
                                this.isUploadStatus = false;
                                ////console.log(event.body[0].url);
                                this.imglink = event.body[0].url;
                                this.img_id = event.body[0].id;
                                this.img_hash = event.body[0].hash;
                                this.img_ext = event.body[0].ext;
                                //////console.log("img_hashBig : ", event.body[0].hash);

                                this.img_extBig = event.body[0].ext;
                                this.addPromoPro();
                            } else {
                                ////////console.log("error");
                                this.isUploadStatus = true;
                            }
                        },
                        (error) => {
                            ////////console.log(error);
                        },
                        () => {}
                    );
            } else {
                console.log("123");
            }
        } else {
            this.addPromoPro();
        }
    };
}
