<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Events</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>{{ h1 }}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!--div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img
                            src="../../../../../assets/img/events/masrise.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
      
                    <h2>1st MAS Postgraduate Course</h2>
                    <div>
                        The Medical Ancillary Services Division of Chong Hua
                        Hospital will be having its 1st Postgraduate Course via
                        Zoom on August 10-11, 2023, with the theme,
                        “Future-Proofing Healthcare: Towards a Sustainable,
                        Resilient, Innovative, and Safe Quality Health Service
                        Delivery.” It is open to Doctors, Nurses, Allied
                        Healthcare Professionals, and Students. <br /><br />This
                        postgraduate program is intended to prepare healthcare
                        professionals with the information and skills necessary
                        to future-proof healthcare systems in terms of
                        sustainability, resilience, innovation, and safety in
                        the delivery of quality healthcare. To maintain the
                        sustainability of healthcare systems, healthcare
                        professionals must be proactive in adjusting to emerging
                        trends and problems due to the quickly changing
                        healthcare environment. <br /><br />This course will
                        examine the fundamental concepts and guiding principles
                        of future-proofing healthcare systems, such as
                        sustainability, resilience, innovation, and safety.
                        Participants will gain knowledge of the most recent
                        healthcare trends and technologies, as well as
                        implementation strategies for these advances. In
                        addition, they will comprehend the significance of
                        safety culture and practices in the delivery of quality
                        healthcare. <br /><br />Participants will engage in a
                        learning experience that fosters critical thinking and
                        problem-solving abilities through interactive lectures,
                        case studies, and group discussions. In addition, they
                        will get the chance to network and cooperate with
                        healthcare experts from a variety of disciplines and
                        locations
                    </div>

                    <a href="/mas-rise" class="pointHand">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</div-->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img
                            src="../../../../../assets/img/events/2ndPostGrad.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <h2>
                        2nd Medical Ancillary Services Division Postgraduate
                        Course
                    </h2>
                    <div>
                        Each year, Chong Hua Hospital observes Medical Ancillary
                        Services Week during the second week of August. In order
                        to celebrate and recognize the crucial roles that
                        medical ancillary professionals play in improving
                        patient care, cultivating a respectful and appreciative
                        culture within the healthcare community, and inspiring
                        the next generation of healthcare leaders, the 2024
                        theme,
                        <b
                            >MAS SHINE: Shine, Honor, Inspire, Nature, and
                            Empower the Next Generation of Healthcare Leaders</b
                        >, has been chosen. <br /><br />
                        The one-week-long celebration includes another round of
                        continuing professional development. Join us for the 2nd
                        Medical Ancillary Services Postgraduate Course, themed

                        <b
                            >"Building Best Practices for Health: Empowering
                            Healthcare Professionals to Promote Optimal Health
                            Outcomes."</b
                        >
                        <br /><br />
                        Discover cutting-edge practices, gain valuable insights,
                        and connect with industry leaders dedicated to advancing
                        healthcare. This course is an excellent opportunity for
                        professional development, networking, and fostering a
                        culture of excellence in patient care.
                        <br /><br />
                        Don’t miss out on this chance to enhance your skills and
                        contribute to the future of healthcare. Reserve your
                        spot today and be part of a transformative experience!
                    </div>

                    <a href="/medical-ancillary-services" class="pointHand"
                        >Learn More</a
                    >
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><br /></div>
        </div>
    </div>
</div>
