<div class="signup-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 ptb-100">
                <div class="signup-item">
                    <div class="signup-head">
                        <h2>Login Here</h2>
                        <p>
                            Didn't you account yet?
                            <a routerLink="/signup">Register Here</a>
                        </p>
                    </div>

                    <div class="signup-form">
                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            class="form-control"
                                            placeholder="Your Email"
                                            [(ngModel)]="username"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="password"
                                            class="form-control"
                                            placeholder="Password"
                                            [(ngModel)]="password"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <div class="forgot-pass">
                                            <a href="#">Forgot Password?</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <button
                                            type="submit"
                                            class="btn signup-btn"
                                            (click)="processLogin()"
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
