import { ThisReceiver } from "@angular/compiler";
import { Component, OnInit, ViewChild } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-admission-procedure-editor",
    templateUrl: "./admission-procedure-editor.component.html",
    styleUrls: ["./admission-procedure-editor.component.scss"],
})
export class AdmissionProcedureEditorComponent implements OnInit {
    description = "<p>Hello, world!</p>";
    id;
    constructor(private genServ: GeneralService) {
        this.mycontent = `<p>My html content</p>`;
    }
    addSlider = {
        data: {
            column1: "",
        },
    };
    ngOnInit(): void {
        // https://ckeditor.com/cke4/builder
        this.ckeConfig = {
            extraPlugins:
                "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
                "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
                "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
                "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
                "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
                "indentblock,indentlist,justify,link,list,liststyle,magicline," +
                "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
                "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
                "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
                "tabletools,templates,toolbar,undo,wsc,wysiwygarea",
        };
        this.get();

        this.config.toolbar = [["TextColor", "BGColor"]];
    }

    get() {
        this.genServ.get("admission-procedures").subscribe({
            complete: () => {
                //console.log("!!!!!!!!!!!!!!!!!!!!");
                //console.log(this.description);
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);

                //console.log(data.data[0].id);
                this.id = data.data[0].id;
                //console.log(data.data[0].attributes.column1);
                this.description = data.data[0].attributes.column1;
            },
        });
    }
    config: any;
    startUpload() {
        this.addSlider.data.column1 = this.description;
        //console.log(this.addSlider);

        this.genServ
            .put("admission-procedures", this.addSlider, this.id)
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (data: any) => {
                    //console.log(data);
                },
            });
    }
    name = "ng2-ckeditor";
    ckeConfig: any;
    mycontent: string;
    log: string = "";
    @ViewChild("myckeditor") ckeditor: any;
    onEditorChange(event) {
        //console.log(event);
    }

    onChange(event: any): void {
        //console.log(event);
        //console.log(this.mycontent);
        //this.log += new Date() + "<br />";
    }
}
