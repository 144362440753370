<div class="modal-header">
    <b class="modal-title pull-left"
        ><b>Employment Applicant Consent Form</b></b
    >
</div>
<div class="modal-body">
    <ng-template #customLoadingTemplate>
        <div class="custom-class">
            <h3>Loading...</h3>
        </div>
    </ng-template>
    <div class="signup-area">
        <div class="container-fluid">
            <div class="my-container">
                <div class="row">
                    <div class="col-lg-12">
                        In compliance with the Data Privacy Act of 2012
                        (Republic Act No. 10173), Chong Hua Hospital ensures
                        that your personal data will be handled and processed
                        responsibly and securely. By ticking the box below, I
                        hereby give my consent to Chong Hua Hospital to collect,
                        use, and process my personal data as part of the
                        recruitment and hiring process.<br /><br />
                        <b>1. Personal Data Collection</b><br />
                        I, the undersigned, hereby give my consent to Chong Hua
                        Hospital to collect and process my personal data,
                        including but not limited to my name, contact details,
                        employment history, educational background, and other
                        relevant information necessary for the recruitment and
                        hiring process.<br /><br />

                        <b>2. Purpose of Data Processing</b><br />
                        I understand that the personal data provided will be
                        used solely for the purpose of evaluating my suitability
                        for employment with Chong Hua Hospital, and for any
                        other purposes related to my job application.<br /><br />

                        <b>3. Data Storage and Security</b><br />
                        I acknowledge that my personal data will be stored
                        securely and will be retained only for as long as
                        necessary to complete the recruitment process or as
                        required by law.<br /><br />

                        <b>4. Data Sharing</b><br />
                        I understand that my personal data may be shared within
                        the organization and with third-party service providers,
                        only for the purpose of recruitment and
                        employment-related activities, in accordance with the
                        Data Privacy Act.<br /><br />

                        <b>5. Data Subject Rights</b><br />
                        I am aware that I have the right to access, correct, and
                        request the deletion of my personal data, subject to the
                        applicable provisions of the Data Privacy Act.<br /><br />

                        <b>6. Consent Withdrawal</b><br />
                        I understand that I may withdraw my consent at any time
                        by notifying Chong Hua Hospital in writing, and that
                        withdrawal may affect my eligibility for employment.<br /><br />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="mb-3">
        I have read and agree to the terms outlined above regarding the
        collection, use, and processing of my personal data.<br />
        <button class="btn btn-success me-2" (click)="onAccept()">
            Accept
        </button>
        <button class="btn btn-danger" (click)="onDecline()">Decline</button>
    </div>
</div>
