import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-rnd-summit",
    templateUrl: "./rnd-summit.component.html",
    styleUrls: ["./rnd-summit.component.scss"],
})
export class RndSummitComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {}
    openSponsors() {
        this.router.navigate(["/medical-ancillary-services/sponsors"]);
    }
}
