<div class="modal-header">
    <b class="modal-title pull-left">Data Privacy Act of 2012</b>
    <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="onClick()"
    >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="loading-template">
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    As we strive to provide you with exceptional healthcare,
                    it's important for us to keep our Privacy Policy up-to-date.
                    This is in accordance with the Data Privacy Act of 2012
                    (R.A. 10173). We urge you to review our Privacy Policy by
                    following this
                    <a
                        href="/privacy"
                        title="Chong Hua Hospital - Privacy Policy"
                        >link</a
                    >.
                </div>
                <div class="col-12"><br /></div>
            </div>
        </div>
    </div>
</div>
