<div class="container-fluid">
    <div class="row">
        <div class="col-12"><br /></div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('home-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Hero Image</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('home-page-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Home Page</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('pageHeaderEditor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Page Banner</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('about-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >about</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('aboutchhc-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >about chhc</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('aboutchhm-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >about chhm</a
            >
        </div>

        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('price-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Price</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('promos-packages-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Promos & Packages</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('news-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >News</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('patienteducation-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Patient Education</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER' || userType == 'HRUSER'"
        >
            <a
                (click)="goto('careers-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Careers
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('admission-procedure-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Amission Procedure</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('discharge-procedure-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Discharge Procedure</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('advance-directive-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Advance Directive</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('medical-records-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Medical Records</a
            >
        </div>

        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('patient-rights-responsibilities-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Patient Rights Responsibilities</a
            >
        </div>

        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('pharmacy-info-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Pharmacy Info
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('others-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Others
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('residency-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >residency
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('residency-editor-mandaue')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >residency mandaue
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('fellowship-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >fellowship
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('services-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Services Editor
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('ecu-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >EHU Plans
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('hmo-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Insurance
            </a>
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('room-editor')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Room catalogue
            </a>
        </div>

        <div class="col" style="padding-top: 10px">
            <a
                (click)="logout()"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >logout</a
            >
        </div>
    </div>
</div>

<hr />
<div class="container-fluid" *ngIf="isApprover == 1">
    <div class="row">
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER'"
        >
            <a
                (click)="goto('residency-approver')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Residency Approver</a
            >
        </div>
        <div
            class="col"
            style="padding-top: 10px"
            *ngIf="userType == 'MKTGUSER' || userType == 'HRAPPROVER'"
        >
            <a
                (click)="goto('careers-approver')"
                class="nav-link pointHand"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Careers</a
            >
        </div>
    </div>
</div>
