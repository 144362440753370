import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chh-hr',
  templateUrl: './chh-hr.component.html',
  styleUrls: ['./chh-hr.component.scss']
})
export class ChhHrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
