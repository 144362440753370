<div class="modal-header">
    <b class="modal-title pull-left"
        >Thank you for filling out your information!</b
    >
    <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="onClick()"
    >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="loading-template">
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    We have received your message and want to thank you for
                    contacting us. If your inquiry is urgent, please use the
                    telephone numbers below to talk to one of our customer
                    service representatives.

                    <br /><br />
                    Cebu : +63 (32) 255-8000<br />
                    Mandaue : +63 (32) 233-8000
                    <br /><br />

                    Or, you may send us a message on our Facebook page at
                    <a
                        href="https://www.facebook.com/ChongHuaHospital"
                        target="_BLANK"
                        >Chong Hua Hospital</a
                    >. A customer service representative will assist you on
                    Facebook Messenger from Monday to Saturday, 7 a.m. to 12
                    midnight.

                    <br /><br />

                    Otherwise, we will reply by email within 24 hours.
                    <br /><br />

                    Talk to you soon,<br />
                    Chong Hua Hospital
                </div>
                <div class="col-12"><br /></div>
            </div>
        </div>
    </div>
</div>
