<page-title pageTitle="" [bacg]="bacg"></page-title>

<div class="doctor-search-area pt-100">
    <div class="container">
        <form>
            <div class="row doctor-search-wrap">
                <div class="col-sm-4 col-lg-4">
                    <div class="doctor-search-item">
                        <div class="form-group">
                            <i class="icofont-doctor-alt"></i>
                            <label>Search</label>
                            <input
                                [readOnly]="isLoading"
                                type="text"
                                class="form-control"
                                placeholder="Doctor's Name"
                                [(ngModel)]="searchData"
                                [ngModelOptions]="{
                                    standalone: true
                                }"
                                (keyup)="filter(this)"
                            />
                        </div>

                        <button
                            type="submit"
                            class="btn doctor-search-btn"
                            (click)="filter(searchData)"
                        >
                            <i class="icofont-search-1"></i>
                        </button>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-4">
                    <div class="doctor-search-item">
                        <div class="form-group">
                            <i class="icofont-hospital"></i>
                            <label>Category</label>
                            <ng-select
                                class="form-control"
                                [(ngModel)]="specialty"
                                (change)="chooseSpecialty1()"
                                ><ng-option value="All" selected>
                                    All
                                </ng-option>
                                <ng-option value="Anesthesia">
                                    Anesthesiology
                                </ng-option>
                                <ng-option
                                    value="Family and Community Medicine"
                                >
                                    Family and Community Medicine
                                </ng-option>
                                <ng-option
                                    value="sur-otorhinolaryngology (ent)"
                                >
                                    ENT (Otorhinolaryngology)</ng-option
                                >
                                <ng-option value="Internal Medicine">
                                    Internal Medicine
                                </ng-option>
                                <ng-option value="med-cardiology">
                                    &nbsp;-Cardiology
                                </ng-option>
                                <ng-option value="med-dermatology">
                                    &nbsp;-Dermatology
                                </ng-option>
                                <ng-option value="med-endocrinology">
                                    &nbsp;-Endocrinology
                                </ng-option>
                                <ng-option value="med-gastroenterology">
                                    &nbsp;-Gastroenterology
                                </ng-option>
                                <ng-option value="med-hematology">
                                    &nbsp;-Hematology
                                </ng-option>
                                <ng-option value="med-infectious diseases">
                                    &nbsp;-Infectious Diseases
                                </ng-option>
                                <ng-option value="med-nephrology">
                                    &nbsp;-Nephrology
                                </ng-option>
                                <ng-option value="med-neurology">
                                    &nbsp;-Neurology
                                </ng-option>
                                <ng-option value="med-oncology">
                                    &nbsp;-Oncology
                                </ng-option>
                                <ng-option value="med-pulmonary">
                                    &nbsp;-Pulmonary and Critical Care
                                </ng-option>
                                <ng-option value="med-rehabilitation">
                                    &nbsp;-Rehabilitation Medicine
                                </ng-option>
                                <ng-option value="med-rheumatology">
                                    &nbsp;-Rheumatology
                                </ng-option>
                                <ng-option value="Obstetrics & Gynecology">
                                    Obstetrics & Gynecology
                                </ng-option>
                                <ng-option value="Ophthalmology">
                                    Ophthalmology
                                </ng-option>
                                <ng-option value="Orthopedics">
                                    Orthopedics
                                </ng-option>

                                <ng-option value="Pathology">
                                    Pathology
                                </ng-option>
                                <ng-option value="Pediatrics">
                                    Pediatrics
                                </ng-option>
                                <ng-option value="ped-adolescent pediatrics"
                                    >&nbsp;-Adolescent Pediatrics</ng-option
                                >
                                <ng-option value="ped-allergology"
                                    >&nbsp;-Allergology</ng-option
                                >
                                <ng-option value="ped-ambulatory pediatrics"
                                    >&nbsp;-Ambulatory Pediatrics</ng-option
                                >
                                <ng-option value="ped-breastfeeding"
                                    >&nbsp;-Breastfeeding</ng-option
                                >
                                <ng-option value="ped-cardiology"
                                    >&nbsp;-Cardiology</ng-option
                                >
                                <ng-option value="ped-child protection"
                                    >&nbsp;-Child Protection</ng-option
                                >
                                <ng-option
                                    value="ped-developmental & behavioral"
                                    >&nbsp;-Developmental &
                                    Behavioral</ng-option
                                >
                                <ng-option value="ped-endocrinology"
                                    >&nbsp;-Endocrinology</ng-option
                                >
                                <ng-option value="ped-gastroenterology"
                                    >&nbsp;-Gastroenterology</ng-option
                                >
                                <ng-option value="ped-general pediatrics"
                                    >&nbsp;-General Pediatrics</ng-option
                                >
                                <ng-option value="ped-genetics"
                                    >&nbsp;-Genetics</ng-option
                                >
                                <ng-option value="ped-hematology"
                                    >&nbsp;-Hematology</ng-option
                                >
                                <ng-option value="ped-hematology- oncology"
                                    >&nbsp;-Hematology-Oncology</ng-option
                                >
                                <ng-option value="ped-infectious diseases"
                                    >&nbsp;-Infectious Diseases</ng-option
                                >
                                <ng-option value="ped-intensive care"
                                    >&nbsp;-Intensive Care</ng-option
                                >
                                <ng-option value="ped-neonatology"
                                    >&nbsp;-Neonatology</ng-option
                                >
                                <ng-option value="ped-nephrology"
                                    >&nbsp;-Nephrology</ng-option
                                >
                                <ng-option value="ped-neurology"
                                    >&nbsp;-Neurology</ng-option
                                >
                                <ng-option value="ped-pulmonology"
                                    >&nbsp;-Pulmonology</ng-option
                                >
                                <ng-option value="ped-rheumatology"
                                    >&nbsp;-Rheumatology</ng-option
                                >
                                <ng-option value="Radiology">
                                    Radiology
                                </ng-option>

                                <ng-option value="Surgery"> Surgery </ng-option>
                                <ng-option value="sur-breast surgery">
                                    &nbsp;-Breast Surgery
                                </ng-option>

                                <ng-option value="sur-cardiovascular surgery">
                                    &nbsp;-Cardiovascular Surgery
                                </ng-option>

                                <ng-option value="sur-colorectal surgery">
                                    &nbsp;-Colorectal Surgery
                                </ng-option>

                                <ng-option value="sur-dentistry">
                                    &nbsp;-Dentistry</ng-option
                                >

                                <ng-option value="sur-general surgery">
                                    &nbsp;-General Surgery</ng-option
                                >

                                <ng-option value="sur-hepatobiliary surgery">
                                    &nbsp;-Hepatobiliary Surgery</ng-option
                                >

                                <ng-option
                                    value="sur-minimally invasive surgery"
                                >
                                    &nbsp;-Minimally Invasive Surgery</ng-option
                                >

                                <ng-option value="sur-neurosurgery">
                                    &nbsp;-Neurosurgery</ng-option
                                >

                                <ng-option value="sur-pediatric surgery">
                                    &nbsp;-Pediatric Surgery</ng-option
                                >

                                <ng-option
                                    value="sur-plastic & reconstructive surgery"
                                >
                                    &nbsp;-Plastic & Reconstructive
                                    Surgery</ng-option
                                >

                                <ng-option value="sur-surgical-oncology">
                                    &nbsp;-Surgical-Oncology</ng-option
                                >

                                <ng-option value="sur-thoracic surgery">
                                    &nbsp;-Thoracic Surgery</ng-option
                                >

                                <ng-option
                                    value="sur-transplant & vascular surgery"
                                >
                                    &nbsp;-Transplant & Vascular
                                    Surgery</ng-option
                                >

                                <ng-option value="sur-urology">
                                    &nbsp;-Urology</ng-option
                                >
                            </ng-select>
                            <!--select
                                [disabled]="isLoading"
                                class="form-control"
                                [(ngModel)]="specialty"
                                [ngModelOptions]="{
                                    standalone: true
                                }"
                                (change)="chooseSpecialty1()"
                                #customInput
                            >
                                <option value="All" selected>
                                    &nbsp;&nbsp;All
                                </option>
                                <option value="Anesthesia">
                                    &nbsp;&nbsp;Anesthesiology
                                </option>
                                <option value="Family and Community Medicine">
                                    &nbsp;&nbsp;Family and Community Medicine
                                </option>
                                <option value="Internal Medicine">
                                    &nbsp;&nbsp;Internal Medicine
                                </option>
                                <option value="med-cardiology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Cardiology
                                </option>
                                <option value="med-dermatology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Dermatology
                                </option>
                                <option value="med-endocrinology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Endocrinology
                                </option>
                                <option value="med-gastroenterology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Gastroenterology
                                </option>
                                <option value="med-hematology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Hematology
                                </option>
                                <option value="med-infectious diseases">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Infectious
                                    Diseases
                                </option>
                                <option value="med-nephrology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Nephrology
                                </option>
                                <option value="med-neurology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Neurology
                                </option>
                                <option value="med-oncology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Oncology
                                </option>
                                <option value="med-pulmonary">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Pulmonary and
                                    Critical Care
                                </option>
                                <option value="med-rehabilitation">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Rehabilitation
                                    Medicine
                                </option>
                                <option value="med-rheumatology">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Rheumatology
                                </option>
                                <option value="Obstetrics & Gynecology">
                                    &nbsp;&nbsp;Obstetrics & Gynecology
                                </option>
                                <option value="Orthopedics">
                                    &nbsp;&nbsp;Orthopedics
                                </option>
                                <option value="Pathology">
                                    &nbsp;&nbsp;Pathology
                                </option>
                                <option value="Pediatrics">
                                    &nbsp;&nbsp;Pediatrics
                                </option>
                                <option value="Radiology">
                                    &nbsp;&nbsp;Radiology
                                </option>
                                <option value="Surgery">
                                    &nbsp;&nbsp;Surgery
                                </option>
                            </select-->
                        </div>
                        <!--button type="submit" class="btn doctor-search-btn">
                            <i class="icofont-search-1"></i>
                        </button-->
                    </div>
                </div>
                <div class="col-sm-4 col-lg-4">
                    <div class="doctor-search-item">
                        <div class="form-group">
                            <i class="icofont-location-pin"></i>
                            <label>Site</label>
                            <select
                                [disabled]="isLoading"
                                name="site"
                                class="form-control"
                                [(ngModel)]="xxxxsite"
                                [ngModelOptions]="{
                                    standalone: true
                                }"
                                (change)="chooseSpecialty()"
                            >
                                <option value="All">&nbsp;&nbsp;All</option>
                                <option value="B">&nbsp;&nbsp;Both</option>
                                <option value="C">&nbsp;&nbsp;Cebu</option>
                                <option Value="M">&nbsp;&nbsp;Mandaue</option>
                            </select>
                        </div>

                        <button type="submit" class="btn doctor-search-btn">
                            <i class="icofont-search-1"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<section class="doctors-area doctors-area-two pt-100">
    <div class="doctor-shape">
        <img src="assets/img/doctor/2.png" alt="Shape" />
    </div>

    <div class="container">
        <div class="row" *ngIf="isLoading">
            <div class="col-lg-2 offset-lg-5">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- wow animate__animated animate__fadeInUp-->
            <div
                class="col-6 col-lg-2"
                *ngFor="
                    let x of doctorsList
                        | paginate : { itemsPerPage: 24, currentPage: p }
                "
            >
                <div
                    class="doctor-item chhgreen pointHand"
                    (click)="redirect(x)"
                >
                    <div class="doctor-top">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 offset-sm-3">
                                    <img
                                        *ngIf="
                                            x.specialization != 'Ophthalmology'
                                        "
                                        src="assets/icon/{{ x.deptCode }}.png"
                                        alt="Doctor"
                                        class="img-responsive"
                                    />
                                    <img
                                        *ngIf="
                                            x.specialization == 'Ophthalmology'
                                        "
                                        src="assets/icon/Ophthalmology.png"
                                        alt="Doctor"
                                        class="img-responsive"
                                    />
                                </div>
                                <div class="col-12">
                                    <!--span style="font-size: 12px">{{
                                        x.specialty
                                    }}</span-->

                                    <dept-helper
                                        *ngIf="
                                            x.specialization != 'Ophthalmology'
                                        "
                                        [reCeiver]="x.deptCode"
                                    ></dept-helper>
                                    <dept-helper
                                        *ngIf="
                                            x.specialization == 'Ophthalmology'
                                        "
                                        [reCeiver]="x.specialization"
                                    ></dept-helper>
                                    <br />

                                    <!--subspec-helper
                                        *ngIf="
                                            x.deptCode == 'MED' ||
                                            x.deptCode == 'PED' ||
                                            x.deptCode == 'SURA' ||
                                            x.deptCode == 'SURP' ||
                                            x.deptCode == 'ORTH'
                                        "
                                        [subspecialty]="x.clinicalDepartment"
                                    ></subspec-helper-->
                                </div>
                            </div>
                        </div>

                        <!--a class="pointHand" (click)="redirect(x)"
                            >Get Appointment</a
                        -->
                    </div>

                    <div class="doctor-bottom paddingDoctor">
                        <!--b class="chhgreen"
                            ><a>Dr. {{ x.lastName }}, {{ x.firstName }} </a></b
                        -->
                        <b class="chhgreen" *ngIf="x.doctorsName != null"
                            ><a>{{ x.doctorsName }}</a></b
                        >
                        <b class="chhgreen" *ngIf="x.doctorsName == null"
                            ><a>{{ x.firstName }} {{ x.lastName }}, M.D.</a></b
                        >

                        <!--span>{{ x.expertise }}</span-->
                    </div>
                </div>
            </div>
            <pagination-controls
                (pageChange)="p = $event"
                class="floatRight"
                directionLinks="true"
                autoHide="true"
                responsive="true"
                previousLabel="Previous"
                nextLabel="Next"
                screenReaderPaginationLabel="Pagination"
                screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page"
            ></pagination-controls>
        </div>
    </div>
</section>
