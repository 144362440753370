import { Component, Input, OnInit } from "@angular/core";
import { ScreenSizeService } from "src/app/services/screen-size/screen-size.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
@Component({
    selector: "page-title",
    templateUrl: "./page-title.component.html",
    styleUrls: ["./page-title.component.scss"],
})
export class PageTitleComponent implements OnInit {
    @Input() pageTitle: string;
    @Input() bacg: string = "/assets/img/defaultpagebanner.png";
    private ngUnsubscribe = new Subject();
    isNotification: boolean;
    isPortrait: boolean;
    isDesktop: boolean;
    constructor(private screensizeService: ScreenSizeService) {
        this.screensizeService
            .isPortraitView()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isPortrait) => {
                if (this.isPortrait && !isPortrait) {
                    this.ngOnInit();
                }
                if (this.isPortrait != undefined && isPortrait) {
                    this.ngOnInit();
                }
                this.isPortrait = isPortrait;
            });
        this.screensizeService
            .isDesktopView()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((isDesktop) => {
                ////console.log(isDesktop);

                ////console.log("RELOAD - HOME");
                if (this.isDesktop && !isDesktop) {
                    window.location.reload();
                }
                this.isDesktop = isDesktop;
            });
        //console.log(this.isDesktop);
    }
    isDefault: boolean = false;
    ngOnInit(): void {
        if (this.bacg == "/assets/img/defaultpagebanner.png") {
            this.isDefault = true;
        }
        //console.log(this.isDefault);
    }
}
