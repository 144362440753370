import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, NgZone, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-services-details",
    templateUrl: "./services-details.component.html",
    styleUrls: ["./services-details.component.scss"],
})
export class ServicesDetailsComponent implements OnInit {
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone,
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {}
    Title;
    Details;
    SmallTitle;
    SmallData;
    serviceId;
    TitleImg = "";
    SmallImg = "";
    url = "https://strapi.chonghua.com.ph";
    ngOnInit(): void {
        this.serviceId = this.activatedRoute.snapshot.params.id;
        this.getCareer();
        //console.log(this.serviceId);
    }
    isLoading: boolean = false;
    serviceList;
    getCareer() {
        this.isLoading = true;
        //service-details
        this.general
            .get("service-details?filters[link][$eq]=" + this.serviceId)
            .subscribe({
                complete: () => {
                    this.isLoading = false;
                },
                error: (error) => {},
                next: (data: any) => {
                    this.serviceList = data.data;
                    //console.log(this.serviceList);
                    this.Title = this.serviceList[0].attributes.Title;
                    console.log(this.Title);

                    this.getBanner(this.Title);
                    this.Details = this.sanitizer.bypassSecurityTrustHtml(
                        this.serviceList[0].attributes.Details
                    );
                    this.TitleImg = this.serviceList[0].attributes.TitleImg;

                    this.SmallTitle = this.serviceList[0].attributes.SmallTitle;
                    this.SmallData = this.sanitizer.bypassSecurityTrustHtml(
                        this.serviceList[0].attributes.SmallData
                    );
                    this.SmallImg = this.serviceList[0].attributes.SmallImg;
                },
            });
    }
    bannerImage;
    bacg;
    getBanner(dddddd) {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data.data);
                this.bannerImage = data.data;
                this.bannerImage.filter((x) => x.attributes.owner == dddddd);

                let asdasdasd;
                this.bannerImage = data.data;
                asdasdasd = this.bannerImage.filter(
                    (x) => x.attributes.owner == dddddd
                );
                //console.log(this.bannerImage);
                this.ngZone.run(() => {
                    this.bacg =
                        "https://strapi.chonghua.com.ph" +
                        asdasdasd[0].attributes.images;

                    //console.log(this.bacg);
                });
            },
        });
    }
}
