import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-list-of-services",
    templateUrl: "./list-of-services.component.html",
    styleUrls: ["./list-of-services.component.scss"],
})
export class ListOfServicesComponent implements OnInit {
    ourServices = [
        {
            name: "Expert Doctor",
            photoName: "expert-doctor.png",
            delay: ".3s",
            link: "/doctors",
        },
        {
            name: "Laboratory Services",
            photoName: "laboratory-services.png",
            delay: ".5s",
            link: "/services-details/laboratory-services",
        },
        {
            name: "Renal Unit",
            photoName: "renal.png",
            delay: ".9s",
            link: "/services-details/renal-unit",
        },
        {
            name: "Radiology",
            photoName: "radiology.png",
            delay: ".7s",
            link: "/services-details/radiology",
        },

        {
            name: "Emergency/Acute Care",
            photoName: "emergency-acute-care.png",
            delay: ".9s",
            link: "/services-details/emergency-department",
        },
        {
            name: "Heart Institute",
            photoName: "heart-institute.png",
            delay: ".7s",
            link: "/services-details/heart-institute",
        },
        {
            name: "Cancer Center",
            photoName: "cancer.png",
            delay: ".5s",
            link: "/data/service-cancer-center-mandaue",
        },
        {
            name: "Executive Health Unit",
            photoName: "executive-health.png",
            delay: ".3s",
            link: "/services-details/executive-health-unit",
        },
        {
            name: "Center for Sleep Disorder",
            photoName: "sleep disorder.png",
            delay: ".3s",
            link: "/data/service-center-for-sleep-disorders-c",
        },
        {
            name: "Eye Institute",
            photoName: "eye institute.png",
            delay: ".3s",
            link: "/data/service-eye-institute-mandaue",
        },
        {
            name: "Food And Nutrition Services",
            photoName: "food and nutrition.png",
            delay: ".3s",
            link: "/data/clinical-unit-clinical-nutrition-service",
        },
        {
            name: "Neurophysiology",
            photoName: "Neurophysiology.png",
            delay: ".3s",
            link: "/data/service-neurophysiology",
        },
        {
            name: "Nursing Services",
            photoName: "nursing services.png",
            delay: ".3s",
            link: "/data/service-nursing",
        },
        {
            name: "Pharmacy",
            photoName: "pharmacy.png",
            delay: ".3s",
            link: "/data/clinical-unit-pharmacy-services",
        },
        {
            name: "Wellness Center",
            photoName: "wellness center.png",
            delay: ".3s",
            link: "/data/service-wellness-center-mandaue",
        },
        {
            name: "Endoscopy Services",
            photoName: "endoscopy unit.png",
            delay: ".3s",
            link: "/data/clinical-unit-endoscopy",
        },
    ];
    url = "https://strapi.chonghua.com.ph";
    constructor() {}

    ngOnInit(): void {}
}
