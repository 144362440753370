<app-page-title1 pageTitle="Residency Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <!--ckeditor [config]="config" [(ngModel)]="description"
                                        [ngModelOptions]="{standalone: true}">
                                    </ckeditor-->
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="description"
                                        [ngModelOptions]="{ standalone: true }"
                                    ></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        (click)="startUpload()"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!--app-page-title1 pageTitle="Residency Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-9">
                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                  
                                    <ckeditor
                                        [(ngModel)]="description"
                                        #myckeditor="ngModel"
                                        name="myckeditor"
                                        required
                                        [config]="ckeConfig"
                                        debounce="500"
                                        (ngModelChange)="onChange($event)"
                                    >
                                    </ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        (click)="startUpload()"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-3">
                <div
                    *ngFor="let y of jsonData"
                    [ngClass]="test(y.attributes.approver_id1_status, y.id)"
                >
                    <div class="pointHand" (click)="choose(y)">
                        <span *ngIf="y.id == id">-></span>
                        {{ y.attributes._createdAt }} &nbsp;&nbsp;&nbsp;<span
                            *ngIf="!y.attributes.approver_id1_status"
                            (click)="removeList(y)"
                            style="color: RED"
                            >Delete</span
                        ><span
                            *ngIf="y.attributes.approver_id1_status"
                            (click)="removeList(y)"
                            >(approved)</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->
