<!--page-title [pageTitle]="title"></page-title-->
<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Our Hospital Services</h2>
                <!--ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul-->
            </div>
        </div>
    </div>
</div>

<section class="services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <p>
                        Here at Chong Hua Hospital, we offer the most
                        comprehensive health care services at affordable costs.
                    </p>
                </div>
            </div>
            <div
                class="col-6 col-lg-3 wow animate__animated animate__fadeInUp"
                *ngFor="let reels of ourServices"
                [attr.data-wow-delay]="reels.delay"
            >
                <a [routerLink]="reels.link">
                    <div class="doctor-item chhgreen pointHand">
                        <div class="doctor-top">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 offset-md-3">
                                        <img
                                            src="assets/icon/{{
                                                reels.photoName
                                            }}"
                                            alt="Doctor"
                                            class="img-fluid"
                                            style="padding-top: 20px"
                                        />
                                    </div>
                                </div>
                            </div>

                            <!--a routerLink="/appointment">Get Appointment</a-->
                        </div>

                        <div class="doctor-bottom">
                            <h3 class="chhgreen customH3">{{ reels.name }}</h3>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
