import { Component, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-room-cebu",
    templateUrl: "./room-cebu.component.html",
    styleUrls: ["./room-cebu.component.scss"],
})
export class RoomCebuComponent implements OnInit {
    constructor(private general: GeneralService) {}

    ngOnInit(): void {
        this.getSlider();
    }
    promos;
    url = "https://strapi.chonghua.com.ph";
    getSlider() {
        this.general
            .get(
                "room-catalogs?filters[site][$eq]=C&sort=site:ASC&sort=queue:ASC"
            )
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (data: any) => {
                    console.log(data);

                    this.promos = data.data;
                },
            });
    }
}
