<page-title pageTitle="Careers" [bacg]="bacg"></page-title>

<div class="service-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div></div>
            <div class="col-lg-12">
                <div class="row" *ngIf="isLoading">
                    <div class="col-lg-2 offset-lg-5">
                        <div class="spinner-border m-5" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="services-details-img">
                    <!--h2>{{ Title }}</h2-->
                    <p [innerHTML]="Details"></p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-5">
                    <!--div class="">
                    <img
                        *ngIf="SmallImg != ''"
                        [src]="url + SmallImg"
                        alt="Service Details"
                    />
                </div-->
                </div>

                <div class="col-lg-7">
                    <div class="service-details-inner">
                        <h2 *ngIf="SmallTitle != ''">{{ SmallTitle }}</h2>
                        <p [innerHTML]="SmallData"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
