<div class="header-top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-12 col-lg-5">
                <a class="navbar-brand" routerLink="/"
                    ><img src="assets/img/Bigger Date__Org-wide.png" alt="Logo"
                /></a>
            </div>

            <div class="col-sm-12 col-lg-7">
                <div class="header-top-item">
                    <div class="container">
                        <div class="header-top-right">
                            <div class="row">
                                <div class="col-md-4">
                                    <b>Cebu City</b> <br />
                                    <a
                                        _ngcontent-xju-c39=""
                                        href="tel:(+6332) 255-8000"
                                        style="color: #275228"
                                        ><i
                                            _ngcontent-xju-c39=""
                                            class="icofont-ui-call"
                                        ></i>
                                        <b>(+6332) 255-8000</b></a
                                    >
                                </div>
                                <div class="col-md-4">
                                    <b>Mandaue City</b><br />

                                    <a
                                        _ngcontent-xju-c39=""
                                        href="tel:(+6332) 233-8000"
                                        style="color: #275228"
                                        ><i
                                            _ngcontent-xju-c39=""
                                            class="icofont-ui-call"
                                        ></i>
                                        <b>(+6332) 233-8000</b></a
                                    >
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/ChongHuaHospital/"
                                                target="_blank"
                                                ><i class="icofont-facebook"></i
                                            ></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area sticky-top">
    <div class="mobile-nav">
        <a routerLink="/" style="display: none" class="logo testMain"
            ><img
                src="assets/img/Org-wide_Org-wide Supergraphic.png"
                alt="Logo"
        /></a>
    </div>

    <div class="main-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                style="font-size: 18px !important"
                                >Home</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                href="javascript:void(0)"
                                class="nav-link dropdown-toggle"
                                style="font-size: 18px !important"
                                >About Us</a
                            >

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/about"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >About Us</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/about_chhc"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Chong Hua Hospital</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/about_chhm"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Chong Hua Hospital Mandaue</a
                                    >
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/doctors"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                style="font-size: 18px !important"
                                >Our Doctors</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/continuing-medical-education"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                style="font-size: 18px !important"
                                >Continuing Medical Education</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                href="javascript:void(0)"
                                class="nav-link dropdown-toggle"
                                style="font-size: 18px !important"
                                >Patients & Visitors Guide</a
                            >

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/list-of-accredited-hmos-and-insurance"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Accredited HMOs and Insurance</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/admission-procedure"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Admission Procedure</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/advance-directives"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Advance Directives</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/discharge-procedure"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Discharge Procedure</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/medical-records"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Medical Records</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/patient-rights-responsibilities"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Patient Rights and Responsibilities</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link pointHand"
                                        style="font-size: 18px !important"
                                        >Patients Room</a
                                    >
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                routerLink="/room-rates-cebu"
                                                class="nav-link testsubmeter"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                style="
                                                    font-size: 18px !important;
                                                "
                                                >Cebu</a
                                            >
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                routerLink="/room-rates-mandaue"
                                                class="nav-link testsubmeter"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                style="
                                                    font-size: 18px !important;
                                                "
                                                >Mandaue</a
                                            >
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/pharmacy-info"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Pharmacy Information</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/prices"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Pricelist</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/visitation-guidelines"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Visitation Guidelines</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/general-information"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >General Information</a
                                    >
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a
                                href="https://pxi.chonghua.com.ph"
                                class="nav-link"
                                routerLinkActive="active"
                                target="_BLANK"
                                style="font-size: 18px !important"
                                ><!--PXi-Patient Experience-->Patient Portal /
                                PXi</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                href="javascript:void(0)"
                                class="nav-link dropdown-toggle"
                                style="font-size: 18px !important"
                                >What's New</a
                            >
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/whats-new/promos-packages"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Promos & Packages</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/whats-new/events"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Events</a
                                    >
                                </li>
                                <!--li class="nav-item">
                                    <a
                                        routerLink="/whats-new/news"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >News</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/whats-new/patient-education"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Patient Education</a
                                    >
                                </li-->
                                <!--li class="nav-item"><a routerLink="/home-two"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">News
                                                                                &
                                                                                Events</a></li>
                                                                <li class="nav-item"><a routerLink="/home-two"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Blogs</a>
                                                                </li-->
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a
                                href="javascript:void(0)"
                                class="nav-link dropdown-toggle"
                                style="font-size: 18px !important"
                                >Career Center</a
                            >
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/careers"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Careers</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/residency-training"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Residency Training Programs - Cebu</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/residency-training-mandaue"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Residency Training Programs -
                                        Mandaue</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/fellowship-training"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        style="font-size: 18px !important"
                                        >Fellowship Training Programs</a
                                    >
                                </li>
                                <!--li class="nav-item"><a routerLink="/home-two"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Blogs</a>
                                                                </li-->
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/contact-us"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                style="font-size: 18px !important"
                                >Contact Us</a
                            >
                        </li>

                        <!--li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/appointment" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Appointment</a></li>

                                <li class="nav-item"><a routerLink="/departments" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Departments</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Login</a></li>

                                <li class="nav-item"><a routerLink="/signup" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy
                                        Policy</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms &
                                        Conditions</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Coming
                                        Soon</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a>
                                </li>

                                <li class="nav-item"><a routerLink="/services-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services
                                        Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Doctor</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/doctor" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Doctor</a></li>

                                <li class="nav-item"><a routerLink="/doctor-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Doctor
                                        Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog
                                        Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li-->
                    </ul>

                    <!--div class="nav-srh">
                        <div class="search-toggle">
                            <button class="search-icon icon-search"><i class="icofont-search-1"></i></button>
                            <button class="search-icon icon-close"><i class="icofont-close"></i></button>
                        </div>

                        <div class="search-area">
                            <form>
                                <input type="text" class="src-input" id="search-terms" placeholder="Search here..." />
                                <button type="submit" name="submit" value="Go" class="search-icon"><i
                                        class="icofont-search-1"></i></button>
                            </form>
                        </div>
                    </div-->
                </div>
            </nav>
        </div>
    </div>
</div>
