import {
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { SpecialtySelectionComponent } from "../../modal/specialty-selection/specialty-selection.component";

@Component({
    selector: "app-doctor",
    templateUrl: "./doctor.component.html",
    styleUrls: ["./doctor.component.scss"],
})
export class DoctorComponent implements OnInit {
    doctorsList;
    doctorsListFull;
    filteredList;
    searchData: string = "";

    constructor(
        private genServ: GeneralService,
        public router: Router,
        private sanitizer: DomSanitizer,
        private ngZone: NgZone,
        private general: GeneralService,
        private modalService: BsModalService,
        private route: Router,
        private activatedRoute: ActivatedRoute
    ) {}
    linkedData;
    ngOnInit(): void {
        ////console.log(this.activatedRoute.snapshot.params.category);
        if (
            this.activatedRoute.snapshot.params.category == undefined ||
            this.activatedRoute.snapshot.params.category == null ||
            this.activatedRoute.snapshot.params.category == "null"
        ) {
            this.specialty = "All";
        } else {
            this.specialty = this.activatedRoute.snapshot.params.category;
        }
        ////console.log(this.specialty);

        this.getDoctors();
        this.getBanner();
    }
    p: number = 1;
    isLoading: boolean = false;
    getDoctors() {
        this.isLoading = true;
        this.p = 1;
        let doctorsListFull = [];
        this.doctorsListFull = [];
        this.genServ.getDoctorsDirectory().subscribe({
            complete: () => {
                this.isLoading = false;
                this.doctorsListFull = doctorsListFull;
                this.doctorsList = this.doctorsListFull;
                this.filteredList = this.doctorsListFull;
                this.chooseSpecialty();
            },
            error: (error) => {
                this.isLoading = false;
            },
            next: (data: any) => {
                doctorsListFull = data;
                /* doctorsListFull.forEach((element) => {
                    element.wowdelay = this.radnomize();
                    this.doctorsListFull.push(element);
                });*/
            },
        });
        function getDistinctDate(dates) {
            let lookup = {};
            for (let i = 0; i < dates.length; i++) {
                let jsontext = `{"year" : ${dates[i].deptCode}}`;
                lookup[jsontext] = true;
            }
            return Object.keys(lookup);
        }
    }

    filter(sss) {
        let y;
        y = this.searchData.toLowerCase();

        this.doctorsList = [];

        this.doctorsList = this.filteredList.filter((x) => {
            let firstlast =
                x.firstName.toLowerCase() + " " + x.lastName.toLowerCase();
            let lastfirst =
                x.lastName.toLowerCase() + ", " + x.firstName.toLowerCase();
            let firstname = x.firstName.toLowerCase();
            let lastName = x.lastName.toLowerCase();
            let fullname = "";
            let specialization;
            ////console.log(x);

            if (
                x.specialization == "" ||
                x.specialization == "-" ||
                x.specialization == null
            ) {
                specialization = "";
            } else {
                specialization = x.specialization.toLowerCase();
            }
            let subSpecialization;
            if (
                x.subSpecialization == "" ||
                x.subSpecialization == "-" ||
                x.subSpecialization == null
            ) {
                subSpecialization = "";
            } else {
                subSpecialization = x.subSpecialization.toLowerCase();
            }
            ////console.log(subSpecialization);

            //let subSpecialization = x.subSpecialization.toLowerCase();
            if (x.doctorsName != null) {
                fullname = x.doctorsName.toLowerCase();
            }
            //////console.log(fullname);
            if (firstname.includes(y)) {
                return x;
            } else if (lastName.includes(y)) {
                return x;
            } else if (firstlast.includes(y)) {
                return x;
            } else if (lastfirst.includes(y)) {
                return x;
            } else if (fullname.includes(y)) {
                return x;
            } else if (specialization.includes(y)) {
                return x;
            } else if (subSpecialization.includes(y)) {
                return x;
            }
        });
        let doctorsListDraft;
        /* doctorsListDraft = this.doctorsList.filter((x) => {
            let xSite: any;
            //console.log(x.hospitalAppointment, "|");

            if (x.hospitalAppointment == "Both") {
                xSite = "B";
            } else if (x.hospitalAppointment == "Chong Hua Hospital Mandaue") {
                xSite = "M";
            } else if (
                x.hospitalAppointment == "Chong Hua Hospital" ||
                x.hospitalAppointment == "Cebu" ||
                x.hospitalAppointment == "Fuente"
            ) {
                xSite = "C";
            }
            if (xSite == this.xxxxsite) {
                return x;
            }
        }); this.doctorsList = doctorsListDraft;*/
    }
    specialty = "All";
    xxxxsite = "All";
    redirect(x) {
        localStorage.setItem("selectedDoctor", JSON.stringify(x));
        this.router.navigate(["/doctor/" + x.drCode]);
    }
    radnomize() {
        let xyz = [".3s", ".5s", ".7s", ".9s"];
        var rand = xyz[~~(Math.random() * xyz.length)];
        return rand;
    }
    chooseSite() {
        ////////console.log(this.xxxxsite);

        this.filteredList = [];

        //this.filteredList = this.doctorsListFull.filter((x) => {});
    }
    chooseSpecialty1() {
        // this.searchData = "";
        //////console.log(this.specialty);
        this.route.navigate(["/doctors/" + this.specialty]).then(() => {
            this.chooseSpecialty();
        });
    }
    chooseSpecialty() {
        //////console.log("chooseSpecialty");

        this.filteredList = [];
        if (this.xxxxsite == "") {
            this.filteredList = this.doctorsListFull;
        } else {
            ////////console.log("Search Me");
            let xSite;
            let searchMe;
            /* if (this.xxxxsite == "C") {
                searchMe = "Chong Hua Hospital";
            } else if (this.xxxxsite == "M") {
                searchMe = "Chong Hua Hospital Mandaue";
            } else {
                searchMe = "Both";
            }

            if (searchMe != "") {
                this.filteredList = this.doctorsListFull.filter((xyz) => {
                    if (xyz.hospitalAppointment == "Both") {
                        xSite = "B";
                    } else if (
                        xyz.hospitalAppointment == "Chong Hua Hospital Mandaue"
                    ) {
                        xSite = "M";
                    } else if (
                        xyz.hospitalAppointment == "Chong Hua Hospital" ||
                        xyz.hospitalAppointment == "Cebu" ||
                        xyz.hospitalAppointment == "Fuente"
                    ) {
                        xSite = "C";
                    }

                    if (xyz.hospitalAppointment != null) {
                        if (xSite == this.xxxxsite) {
                            return xyz;
                        }
                      
                    }
                });
            }*/

            this.filteredList = this.doctorsListFull.filter((xyz) => {
                if (this.xxxxsite == "C") {
                    if (
                        xyz.hospitalAppointment == "Chong Hua Hospital" ||
                        xyz.hospitalAppointment == "Cebu" ||
                        xyz.hospitalAppointment == "Fuente" ||
                        xyz.hospitalAppointment == "Both"
                    ) {
                        return xyz;
                    }
                }
                if (this.xxxxsite == "M") {
                    if (
                        xyz.hospitalAppointment ==
                            "Chong Hua Hospital Mandaue" ||
                        xyz.hospitalAppointment == "Both"
                    ) {
                        return xyz;
                    }
                }
                if (this.xxxxsite == "B") {
                    if (xyz.hospitalAppointment == "Both") {
                        return xyz;
                    }
                }
                if (this.xxxxsite == "All") {
                    return xyz;
                }
            });
        }
        this.doctorsList = this.filteredList;
        let doctorsList = [];
        //////console.log(this.specialty);
        let specialty;
        if (this.specialty != undefined) {
            this.doctorsList = [];
            specialty = this.specialty.trim();

            if (
                specialty == "All" ||
                specialty == "Anesthesia" ||
                specialty == "Family and Community Medicine" ||
                specialty == "Internal Medicine" ||
                specialty == "Obstetrics & Gynecology" ||
                specialty == "Orthopedics" ||
                specialty == "Pathology" ||
                specialty == "Pediatrics" ||
                specialty == "Radiology" ||
                specialty == "Orthopedics" ||
                specialty == "Surgery"
            ) {
                let typpeee;
                doctorsList = [];
                if (specialty == "All") {
                    doctorsList = this.filteredList;
                } else {
                    ////////console.log(specialty);

                    if (specialty == "Anesthesia") {
                        typpeee = "ANES";
                    }
                    if (specialty == "Family and Community Medicine") {
                        typpeee = "FMED";
                    }
                    if (specialty == "Internal Medicine") {
                        typpeee = "MED";
                    }

                    if (specialty == "Orthopedics") {
                        typpeee = "ORTH";
                    }
                    if (specialty == "Pathology") {
                        typpeee = "PATH";
                    }
                    if (specialty == "Pediatrics") {
                        typpeee = "PED";
                    }
                    if (specialty == "Radiology") {
                        typpeee = "RAD";
                    }

                    if (specialty == "Orthopedics") {
                        typpeee = "ORTH";
                    }
                    ////////console.log(this.filteredList);

                    doctorsList = this.filteredList.filter(
                        (x) => x.deptCode == typpeee
                    );
                    if (specialty == "Surgery") {
                        doctorsList = this.filteredList.filter(
                            (x) => x.deptCode == "SURA" || x.deptCode == "SURP"
                        );
                    }
                    if (specialty == "Obstetrics & Gynecology") {
                        doctorsList = this.filteredList.filter(
                            (x) => x.deptCode == "OB" || x.deptCode == "GYN"
                        );
                    }
                    ////////console.log("end else");
                    ////////console.log(doctorsList);
                }
            } else if (specialty.includes("med-")) {
                doctorsList = [];
                const myArray = specialty.split("-");
                let deptCode = myArray[0];
                let subSpec = myArray[1];
                doctorsList = this.filteredList.filter((x) => {
                    if (
                        x.subSpecialization == null ||
                        x.subSpecialization == "" ||
                        x.subSpecialization == "-" ||
                        x.subSpecialization == "N/A"
                    ) {
                        if (x.specialization != null) {
                            if (x.deptCode.toLowerCase() == deptCode) {
                                return x.specialization
                                    .toLowerCase()
                                    .includes(subSpec);
                            }
                        }
                    } else {
                        if (x.deptCode.toLowerCase() == deptCode) {
                            return x.subSpecialization
                                .toLowerCase()
                                .includes(subSpec);
                        }
                    }
                });
            } else if (specialty.includes("ped-")) {
                doctorsList = [];
                const myArray = specialty.split("-");
                let deptCode = myArray[0];
                let subSpec = myArray[1];

                doctorsList = this.filteredList.filter((x) => {
                    if (
                        x.subSpecialization == null ||
                        x.subSpecialization == "" ||
                        x.subSpecialization == " " ||
                        x.subSpecialization == "-" ||
                        x.subSpecialization == "N/A"
                    ) {
                    } else {
                        if (x.deptCode.toLowerCase() == deptCode) {
                            //console.log(x.subSpecialization);
                            return x.subSpecialization
                                .toLowerCase()
                                .includes(subSpec);
                        }
                    }
                });
            } else if (specialty.includes("sur-")) {
                doctorsList = [];
                const myArray = specialty.split("-");
                let deptCode = myArray[0];

                let subSpec = myArray[1];
                if (myArray[1] == "surgical" && myArray[2] == "oncology") {
                    subSpec = "surgical-oncology";
                }
                //console.log(subSpec);

                doctorsList = this.filteredList.filter((x) => {
                    if (x.deptCode == "SURA" || x.deptCode == "SURP") {
                        if (subSpec == "general surgery") {
                            if (x.specialization == "Surgery") {
                                if (
                                    x.subSpecialization == "-" ||
                                    x.subSpecialization == "	General Surgery"
                                ) {
                                    return x;
                                }
                            }
                        } else if (
                            subSpec == "dentistry" ||
                            subSpec == "neurosurgery" ||
                            subSpec == "otorhinolaryngology (ent)" ||
                            subSpec == "urology"
                        ) {
                            if (x.specialization != null) {
                                if (
                                    x.specialization
                                        .toLowerCase()
                                        .includes(subSpec)
                                ) {
                                    return x;
                                }
                            }
                        } else {
                            if (x.subSpecialization != null) {
                                if (
                                    x.subSpecialization
                                        .toLowerCase()
                                        .includes(subSpec)
                                ) {
                                    return x;
                                }
                            }
                        }
                    }
                    /*if (subSpec == "general surgery") {
                        if (x.specialization == "Surgery") {
                            if (
                                x.subSpecialization == "-" ||
                                x.subSpecialization == "	General Surgery"
                            ) {
                                return x;
                            }
                        }
                    } else if (
                        subSpec == "dentistry" ||
                        subSpec == "neurosurgery" ||
                        subSpec == "otorhinolaryngology (ent)"
                    ) {
                        //console.log(x.specialization.toLowerCase(), subSpec);

                        if (x.specialization.toLowerCase().includes(subSpec)) {
                            return x;
                        }
                    }*/
                });
            } else if (specialty.includes("Ophthalmology")) {
                // ////console.log(this.filteredList);

                doctorsList = this.filteredList.filter(
                    (x) => x.specialization == specialty /*{
                    // x.specialization == specialty;
                    if (x.specialization == specialty) {
                        ////console.log(x.specialization, specialty, x.doctorsName);
                    }
                }*/
                );
            }
            this.filteredList = [];
            this.filteredList = doctorsList;
            this.doctorsList = doctorsList;
            ////////console.log(doctorsList);
        }
        this.filter("");
    }
    bannerImage;
    bacg;
    getBanner() {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                let asdasdasd;
                this.bannerImage = data.data;
                asdasdasd = this.bannerImage.filter(
                    (x) => x.attributes.owner == "Doctor"
                );
                ////////////console.log(this.bannerImage);

                ////////////console.log(asdasdasd[0].attributes.owner);
                ////////////console.log(asdasdasd[0].attributes.images);
                this.ngZone.run(() => {
                    this.bacg =
                        "https://strapi.chonghua.com.ph" +
                        asdasdasd[0].attributes.images;

                    ////////////console.log(this.bacg);
                });
            },
        });
    }
    bsModalRef?: BsModalRef;
    openModalError() {
        const initialState: ModalOptions = {
            initialState: {
                title: "",
            },
            backdrop: true,
            ignoreBackdropClick: false,
        };
        this.bsModalRef = this.modalService.show(
            SpecialtySelectionComponent,
            initialState
        );
        this.bsModalRef.content.closeBtnName = "Close";
    }
    /*@ViewChild("customInput") myDiv: ElementRef<HTMLElement>;
    click() {
        this.myDiv.nativeElement.click();
        ////console.log(this.myDiv.nativeElement);
    }
    SelectClick() {
        alert("Select Click");
    }*/
}
