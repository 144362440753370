import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-price-list-editor",
    templateUrl: "./price-list-editor.component.html",
    styleUrls: ["./price-list-editor.component.scss"],
})
export class PriceListEditorComponent implements OnInit {
    pageTitle;
    description;
    expiryDate;
    approver_id1;
    approver_id2;
    addSlider = {
        data: {
            title: "",
            link: "",
            image: "",
            imageID: 0,
        },
    };
    bigImgID;
    formData;
    imglinkBig;
    img_hashBig;
    img_idBig;
    img_extBig;
    imglinkSmall;
    img_hashSmall;
    img_idSmall;
    img_extSmall;
    isUploadStatus: boolean = false;
    imageDetails;
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService
    ) {}
    progress;
    progress1;
    message;
    message1;
    promos;
    url = "https://strapi.chonghua.com.ph";
    ngOnInit(): void {
        this.getSlider();
        this.pageTitle = "";
        this.description = "";

        this.isUploadStatus = false;
        document.querySelector("#img_big").setAttribute("src", "");
        /*if (localStorage.getItem("approver_id1") != undefined) {
            ////console.log("approver_id1");

            this.addSlider.data.approver_id1 = Number(
                localStorage.getItem("approver_id1")
            );
        }*/
    }
    imgCount: any = 0;
    getSlider() {
        this.general.get("price-lists").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data);

                this.promos = data.data;
                this.imgCount = data.meta.pagination.total + 1;
            },
        });
    }
    inmgggggg;
    toUploadBigFile: any;
    toUploadSmallFile: any;
    startUpload() {
        this.uploadFileBig(this.toUploadBigFile);
    }
    selectbigImg(files) {
        this.toUploadBigFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_big")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
    }

    public uploadFileBig = (files) => {
        let fileToUpload = files.target.files[0];
        let fileName: string =
            "PriceList-" + this.funcServ.generateYMDHMS(new Date());
        let fileExtension: string = fileToUpload.name
            .split("?")[0]
            .split(".")
            .pop();
        ////console.log(fileExtension);

        if (fileExtension) {
            this.formData = new FormData();
            this.formData.append(
                "files",
                fileToUpload,
                fileName + "." + fileExtension
            );
            this.http
                .post(environment.apiRouterUrl + "api/upload", this.formData, {
                    headers: {},
                    reportProgress: true,
                    observe: "events",
                })
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress)
                            this.progress = Math.round(
                                (100 * event.loaded) / event.total
                            );
                        else if (event.type === HttpEventType.Response) {
                            this.message = "Upload success.";
                        }
                        if (event.body != undefined) {
                            ////console.log("EVENT RESPONSE");

                            //console.log(event.body);
                            this.isUploadStatus = false;
                            this.imglinkBig = event.body[0].url;
                            this.img_idBig = event.body[0].id;
                            this.img_hashBig = event.body[0].hash;
                            this.bigImgID = event.body[0].id;
                            ////console.log("img_hashBig : ", event.body[0].hash);

                            this.img_extBig = event.body[0].ext;
                            this.addDocument();
                        } else {
                            //////console.log("error");
                            this.isUploadStatus = true;
                        }
                    },
                    (error) => {
                        //////console.log(error);
                    },
                    () => {}
                );
        }
    };

    addDocument() {
        this.addSlider.data.title = this.pageTitle;
        this.addSlider.data.link = this.pageTitle;
        this.addSlider.data.image = this.imglinkBig;
        this.addSlider.data.imageID = this.img_idBig;
        /*
        this.addSlider.data.bigImgExt = this.img_extBig;
        this.addSlider.data.smallImg = this.img_hashSmall;
        this.addSlider.data.smallImgExt = this.img_extSmall;
        this.addSlider.data.bigImgID = this.bigImgID;
        */
        //this.addSlider.data.expiry = new Date(this.expiryDate);
        ////console.log(this.addSlider);

        this.general
            .add("price-lists?sort=title:ASC", this.addSlider)
            .subscribe({
                complete: () => {
                    this.ngOnInit();
                    this.toUploadBigFile = "";
                },
                error: (error) => {},
                next: (data: any) => {
                    ////console.log(data);
                },
            });
    }
    delete(idOffile, dataofdata) {
        let fileNmae =
            dataofdata.attributes.bigImg + dataofdata.attributes.imageID;
        //console.log(dataofdata);
        this.promoService
            .delete("upload/files", dataofdata.attributes.imageID)
            .subscribe({
                complete: () => {
                    this.ngOnInit();
                },
                error: (error) => {},
                next: (data: any) => {},
            });

        this.promoService
            .delete("price-lists?sort=title:ASC", idOffile)
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (data: any) => {},
            });
    }
}
