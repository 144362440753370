import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
@Component({
    selector: "app-promos-view",
    templateUrl: "./promos-view.component.html",
    styleUrls: ["./promos-view.component.scss"],
})
export class PromosViewComponent implements OnInit {
    constructor(
        private promoService: PromosService,
        private sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private genServ: GeneralService,
        private router: Router
    ) {}

    url = "https://strapi.chonghua.com.ph";
    mdCode;
    ngOnInit(): void {
        this.apiCall();
    }
    type;
    apiCall() {
        this.type = this.activatedRoute.snapshot.params.type;
        if (this.type == "promos-packages") {
            this.getId("promos-packages");
        } else if (this.type == "news") {
            this.getId("news-portals");
        } else if (this.type == "patient-education") {
            this.getId("patient-educations");
        }
    }
    promos;
    id;
    getId(data) {
        this.id = this.activatedRoute.snapshot.params.dataId;
        this.genServ
            .getCustomQuery(
                "api/" +
                    data +
                    "/" +
                    this.id +
                    "?filters[approver_id1_status][$eq]=true&sort=id:DESC"
            )
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (res: any) => {
                    console.log(res.data);
                    this.promos = [];
                    this.promos.push(res.data);
                },
            });
    }

    downloadme(data) {
        //console.log(data);
        //console.log(this.url + data);

        let link;
        var a = document.createElement("a");
        a.href = this.url + data;
        a.target = "_BLANK";
        a.download = this.url + data;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}
