<div class="modal-header">
    <b class="modal-title pull-left">Thank you!</b>
    <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        data-cy="closemodal"
        (click)="onClick()"
    >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-template #customLoadingTemplate>
        <div class="custom-class">
            <h3>Loading...</h3>
        </div>
    </ng-template>
    <div class="signup-area">
        <div class="container-fluid">
            <div class="my-container">
                <div class="row">
                    <div class="col-lg-12">
                        Thank you for submitting your CV. We will review it and
                        get back to you.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="mb-3">
        <div class="btn-group" style="float: right" (click)="onClick()">
            <label
                class="btn signup-btn"
                style="background-color: #275228; color: white"
                tabindex="0"
                role="button"
                data-cy="continue"
                >Continue</label
            >
        </div>
    </div>
</div>
