<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Pricelist</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="pb-70">
    <div class="container p-0">
        <div class="row">
            <div class="col-lg-12"><br /></div>
            <div class="col-lg-6">
                <ul style="list-style-type: none">
                    <li
                        class="pointHand"
                        [ngClass]="{ my_class: selected === x.image }"
                        (click)="callme(x.image)"
                        *ngFor="let x of promos"
                    >
                        {{ x.title }}
                    </li>
                    <!--li class="pointHand" (click)="callme('Aesthetics_Updated Prices copy.jpg');">Aesthetic Center
                    </li>
                    <li class="pointHand" (click)="callme('Bone Densitometry_Updated Prices copy.jpg');">Bone Mineral
                        Densitometry</li>
                    <li class="pointHand" (click)="callme('CARDIAC REHAB_Updated Prices copy.jpg');">Cardiac
                        Rehabilitation</li>
                    <li class="pointHand" (click)="callme('Cardiology_Updated Prices copy.jpg');">
                        Cardiology
                    </li>
                    <li class="pointHand" (click)="callme('CATHLAB_Updated Prices copy.jpg');">
                        Catheterization
                        Laboratory</li>
                    <li class="pointHand" (click)="callme('Center for Sleep Disorder_Updated Prices copy.jpg');">
                        Center for Sleep Disorders</li>
                    <li class="pointHand" (click)="callme('CT SCAN_Updated Prices copy.jpg');">
                        Computed Tomography
                        Scan (CT-Scan)</li>
                    <li class="pointHand" (click)="callme('Dietary_Updated Prices copy.jpg');">
                        Food & Nutrition
                        Services</li>
                    <li class="pointHand" (click)="callme('ECU_Updated Prices copy.jpg');">
                        Executive Health Unit</li>
                    <li class="pointHand" (click)="callme('Endoscopy_Updated Prices copy.jpg');">
                        Endoscopy</li>
                    <li class="pointHand" (click)="callme('Eye Institue_Updated Prices copy.jpg');">Eye Institute
                    </li>
                    <li class="pointHand" (click)="callme('General Radiology_Updated Prices copy.jpg');">General
                        Radiology</li>
                    <li class="pointHand" (click)="callme('Hema-Onco_Updated Prices copy.jpg');">
                        Hematology -
                        Oncology</li>
                    <li class="pointHand" (click)="callme('Laboratory_Updated Prices copy.jpg');">Laboratory</li>
                    <li class="pointHand" (click)="callme('Mammo_Updated Prices copy.jpg');">
                        Mammography</li>
                    <li class="pointHand" (click)="callme('MRI_Updated Prices copy.jpg');">
                        Magnetic Resonance Imaging
                        (MRI)</li>
                    <li class="pointHand" (click)="callme('Neurophysiology_Updated Prices copy.jpg');">
                        Neurophysiology</li>
                    <li class="pointHand" (click)="callme('Nuclear Medicine_Updated Prices copy.jpg');">Nuclear
                        Medicine</li>
                    <li class="pointHand" (click)="callme('Outpatient_Updated Prices copy.jpg');">Outpatient</li>
                    <li class="pointHand" (click)="callme('Pulmonology_Updated Prices copy.jpg');">Pulmonology</li>
                    <li class="pointHand" (click)="callme('Rad-Onco_Updated Prices copy.jpg');">
                        Radiation Oncology
                    </li>
                    <li class="pointHand" (click)="callme('Rehab Medicine_Updated Prices copy.jpg');">Rehabilitation
                        Medicine</li>
                    <li class="pointHand" (click)="callme('Renal_Updated Prices copy.jpg');">
                        Renal Services</li>
                    <li class="pointHand" (click)="callme('TVS_Updated Prices copy.jpg');">
                        Transvaginal Sonography
                        (TVS)</li>
                    <li class="pointHand" (click)="callme('Ultrasound_Updated Prices copy.jpg');">Ultrasound</li>
                    <li class="pointHand" (click)="callme('Wellness_Updated Prices copy.jpg');">
                        Wellness Center</li-->
                </ul>
            </div>
            <div class="col-lg-6">
                <img
                    id="imageid"
                    [src]="img"
                    class="img-fluid testborder"
                    name="edit-save"
                    alt="Edit"
                />
            </div>
        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <!--div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                        <p>World class, state-of-the-art facilities, paired with an efficient and compassionate medical
                            staff dedicated to bringing
                            you a comprehensive, cost-efficient patient experience designed for your specific needs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h4>Emergency Call</h4>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</section>
