import { Component, OnInit, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-privacy",
    templateUrl: "./privacy.component.html",
    styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
    constructor(private modalService: BsModalService, private router: Router) {}
    public event = new EventEmitter();
    ngOnInit(): void {}

    onAccept() {
        this.event.emit("accept");

        this.modalService.hide();
    }

    onDecline() {
        this.event.emit("decline");
        this.modalService.hide();
    }

    onClick(reason: string) {
        this.modalService.hide(reason);
    }
}
