import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { AppointmentComponent } from "./components/pages/appointment/appointment.component";
import { DepartmentsComponent } from "./components/pages/departments/departments.component";
import { TestimonialsComponent } from "./components/website/patient-visitors-guide/testimonials/testimonials.component";
import { SignupComponent } from "./components/pages/signup/signup.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { TermsConditionComponent } from "./components/pages/terms-condition/terms-condition.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ServicesComponent } from "./components/pages/services/services.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { DoctorDetailsComponent } from "./components/pages/doctor-details/doctor-details.component";
import { DoctorComponent } from "./components/pages/doctor/doctor.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AboutChhcComponent } from "./components/pages/about-chhc/about-chhc.component";
import { AboutChhmComponent } from "./components/pages/about-chhm/about-chhm.component";

import { AdmissionProcedureComponent } from "./components/website/patient-visitors-guide/admission-procedure/admission-procedure.component";
import { DischargeProcedureComponent } from "./components/website/patient-visitors-guide/discharge-procedure/discharge-procedure.component";
import { AdvanceDirectivesComponent } from "./components/website/patient-visitors-guide/advance-directives/advance-directives.component";
import { MedicalRecordsComponent } from "./components/website/patient-visitors-guide/medical-records/medical-records.component";
import { PatientRightsResponsibilitiesComponent } from "./components/website/patient-visitors-guide/patient-rights-responsibilities/patient-rights-responsibilities.component";
import { PharmacyInfoComponent } from "./components/website/patient-visitors-guide/pharmacy-info/pharmacy-info.component";
import { PricesComponent } from "./components/website/patient-visitors-guide/prices/prices.component";
import { OthersComponent } from "./components/website/patient-visitors-guide/others/others.component";
import { PromosPackagesComponent } from "./components/website/whats-new/promos-packages/promos-packages.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonModule } from "@angular/common";
//import { HomeComponent } from "./components/admin/home/home.component";
import { AuthInterceptor } from "./services/auth.interceptor";
//import { CKEditorModule } from "ckeditor4-angular";
//import { PromosPackagesEditorComponent } from "./components/admin/promos-packages-editor/promos-packages-editor.component";
//import { ListsComponent } from "./components/admin/approver/lists/lists.component";
//import { AdminNavComponent } from "./components/admin/admin-nav/admin-nav.component";
import { RouterModule } from "@angular/router";
import { ModalModule } from "ngx-bootstrap/modal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
//import { HomeEditorComponent } from "./components/admin/home-editor/home-editor.component";

import { ResidencyTrainingComponent } from "./components/pages/residency-training/residency-training.component";
import { FellowshipTrainingComponent } from "./components/pages/fellowship-training/fellowship-training.component";
//import { ResidencyEditorComponent } from "./components/admin/residency-editor/residency-editor.component";
//import { FellowshipEditorComponent } from "./components/admin/fellowship-editor/fellowship-editor.component";
import { ContactUsComponent } from "./components/pages/contact-us/contact-us.component";
import { CareersComponent } from "./components/pages/careers/careers.component";
//import { CareersEditorComponent } from "./components/admin/careers-editor/careers-editor.component";
import { NgxPaginationModule } from "ngx-pagination";
import { CarouselModule } from "ngx-bootstrap/carousel";
//import { AdmissionProcedureEditorComponent } from "./components/admin/admission-procedure-editor/admission-procedure-editor.component";
// import { DischargeProcedureEditorComponent } from "./components/admin/discharge-procedure-editor/discharge-procedure-editor.component";
//import { AdvanceDirectivesEditorComponent } from "./components/admin/advance-directives-editor/advance-directives-editor.component";
// import { MedicalRecordEditorComponent } from "./components/admin/medical-record-editor/medical-record-editor.component";
// import { PatientRightsResponsibilitiesEditorComponent } from "./components/admin/patient-rights-responsibilities-editor/patient-rights-responsibilities-editor.component";
// import { PharmacyInfoEditorComponent } from "./components/admin/pharmacy-info-editor/pharmacy-info-editor.component";
// import { OthersEditorComponent } from "./components/admin/others-editor/others-editor.component";
/* import { AboutchhceditorComponent } from "./components/admin/aboutchhceditor/aboutchhceditor.component";
import { AboutchhmeditorComponent } from "./components/admin/aboutchhmeditor/aboutchhmeditor.component";
import { PriceListEditorComponent } from "./components/admin/price-list-editor/price-list-editor.component";
import { PageHeaderEditorComponent } from "./components/admin/page-header-editor/page-header-editor.component"; */
import { BsDropdownConfig } from "ngx-bootstrap/dropdown";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
// import { AboutusEditorComponent } from "./components/admin/aboutus-editor/aboutus-editor.component";

//import { ListComponent } from "./components/approver/list/list.component";
// import { ApproverNavComponent } from "./components/admin/approver-nav/approver-nav.component";
//import { ResidencyApproverComponent } from "./components/approver/residency-approver/residency-approver.component";
import { DeptHelperComponent } from "./others/dept-helper/dept-helper.component";
import { SubspecHelperComponent } from "./others/subspec-helper/subspec-helper.component";
import { AfterFeedbackComponent } from "./components/modal/after-feedback/after-feedback.component";
// import { HomePageEditorComponent } from "./components/admin/home-page-editor/home-page-editor.component";
//import { CareersApproverComponent } from "./components/approver/careers-approver/careers-approver.component";

import { EcuComponent } from "./components/pages/writeup/ecu/ecu.component";
// import { ServicesEditorComponent } from "./components/admin/services-editor/services-editor.component";
import { EcuPlansComponent } from "./components/pages/ecu-plans/ecu-plans.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NgxCaptchaModule } from "ngx-captcha";
import { ListOfServicesComponent } from "./pages/list-of-services/list-of-services.component";
import { CmeComponent } from "./pages/cme/cme.component";
import { PrivacyComponent } from "./components/pages/privacy/privacy.component";
import { SpecialtySelectionComponent } from "./components/modal/specialty-selection/specialty-selection.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
// import { ResidencyEditorMComponent } from "./components/admin/residency-editor-m/residency-editor-m.component";
import { ResidencyTrainingMandaueComponent } from "./components/pages/residency-training-mandaue/residency-training-mandaue.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { VisitationGuidelinesComponent } from "./components/website/visitation-guidelines/visitation-guidelines.component";
import { IrbComponent } from "./components/pages/irb/irb.component";
import { PromosViewComponent } from "./components/website/promos-view/promos-view.component";
import { MasRiseComponent } from "./components/pages/events/mas-rise/mas-rise.component";
import { RndSummitComponent } from "./components/pages/events/rnd-summit/rnd-summit.component";
import { SponsorsComponent } from "./components/pages/events/sponsors/sponsors.component";
import { ChhHrComponent } from "./components/pages/events/chh-hr/chh-hr.component";
import { SharedModule } from "./components/layouts/shared.module";
import { RoomCebuComponent } from "./components/pages/room-cebu/room-cebu.component";
import { RoomMandaueComponent } from "./components/pages/room-mandaue/room-mandaue.component";
import { InsuranceComponent } from "./components/pages/insurance/insurance.component";
import { SpinnerV1Component } from "./components/spinner-v1/spinner-v1.component";
import { SuccessComponent } from "./components/pages/careers/modal/success/success.component";
import { EventsComponent } from './components/website/whats-new/events/events.component';

@NgModule({
    declarations: [
        AppComponent,
        SpinnerV1Component,
        HomeOneComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        AboutComponent,
        AppointmentComponent,
        DepartmentsComponent,
        TestimonialsComponent,
        SignupComponent,
        LoginComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent,
        FaqComponent,
        ErrorComponent,
        ServicesComponent,
        ServicesDetailsComponent,
        DoctorDetailsComponent,
        DoctorComponent,
        BlogComponent,
        BlogDetailsComponent,
        ContactComponent,
        ComingSoonComponent,
        AboutChhcComponent,
        AboutChhmComponent,
        AdmissionProcedureComponent,
        DischargeProcedureComponent,
        AdvanceDirectivesComponent,
        MedicalRecordsComponent,
        PatientRightsResponsibilitiesComponent,
        PharmacyInfoComponent,
        PricesComponent,
        OthersComponent,
        PromosPackagesComponent,

        //AdminNavComponent,
        // HomeEditorComponent,

        ResidencyTrainingComponent,
        FellowshipTrainingComponent,
        // ResidencyEditorComponent,
        // FellowshipEditorComponent,
        ContactUsComponent,
        CareersComponent,
        // CareersEditorComponent,
        //AdmissionProcedureEditorComponent,
        // DischargeProcedureEditorComponent,
        //AdvanceDirectivesEditorComponent,
        // MedicalRecordEditorComponent,
        // PatientRightsResponsibilitiesEditorComponent,
        // PharmacyInfoEditorComponent,
        // OthersEditorComponent,
        // AboutchhceditorComponent,
        // AboutchhmeditorComponent,
        // PriceListEditorComponent,
        // PageHeaderEditorComponent,
        // AboutusEditorComponent,

        //ListComponent,
        // ApproverNavComponent,
        //CKEditorModuleResidencyApproverComponent,
        DeptHelperComponent,
        SubspecHelperComponent,
        AfterFeedbackComponent,
        // HomePageEditorComponent,
        //CareersApproverComponent,

        EcuComponent,
        // ServicesEditorComponent,
        EcuPlansComponent,

        ListOfServicesComponent,
        CmeComponent,
        PrivacyComponent,
        SpecialtySelectionComponent,
        // ResidencyEditorMComponent,
        ResidencyTrainingMandaueComponent,
        VisitationGuidelinesComponent,
        IrbComponent,
        PromosViewComponent,
        MasRiseComponent,
        RndSummitComponent,
        SponsorsComponent,
        ChhHrComponent,
        RoomCebuComponent,
        RoomMandaueComponent,
        InsuranceComponent,
        SuccessComponent,
        EventsComponent,
    ],
    imports: [
        SharedModule,
        NgSelectModule,
        AccordionModule.forRoot(),
        NgxCaptchaModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        BrowserModule,
        NgxPaginationModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000",
        }),

        RouterModule,
        AppRoutingModule,
        CarouselModule.forRoot(),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: BsDropdownConfig,
            useValue: { isAnimated: true, autoClose: true },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
