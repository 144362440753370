<page-title pageTitle="Careers" [bacg]="bacg"></page-title>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center"></div>
    </div>
</div>
<div class="location-area">
    <div class="container">
        <div class="row location-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-location-pin"></i>
                    <h3>Location</h3>
                    <ul>
                        <li>
                            Don Mariano Cui Street, Corner J. Llorente Street,
                            Brgy. Capitol Site, Cebu City, Cebu, Philippines
                            6000
                        </li>
                        <li><br /></li>
                        <li>
                            Mantawi Int'l Drive, City South Economic
                            Administrative Zone, Mandaue City, Cebu, Philippines
                            6014
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-message"></i>
                    <h3>Email</h3>
                    <ul>
                        <li>info_cebu@chonghua.com.ph</li>
                        <li><br /><br /></li>
                        <li>info_mandaue@chonghua.com.ph</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-call"></i>
                    <h3>Phone</h3>
                    <ul>
                        <li>+63 (32) 255-8000</li>
                        <li><br /><br /></li>
                        <li>+63 (32) 233-8000</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="drop-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 p-0">
                <div class="drop-item drop-img">
                    <div class="drop-left">
                        <h2>Drop your message for any info or question.</h2>
                        <span style="color: Red" *ngIf="nameError"
                            >*Name is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="emailError"
                            >*Email is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="phoneError"
                            >*Phone Number is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="subjectError"
                            >*Subject is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="messageError"
                            >*Message is Required<br
                        /></span>
                        <span
                            style="color: Red"
                            *ngIf="error != ''"
                            [innerHTML]="error"
                        ></span>
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            class="form-control"
                                            placeholder="Name"
                                            [(ngModel)]="name"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            class="form-control"
                                            placeholder="Email"
                                            [(ngModel)]="eemail"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="phone_number"
                                            class="form-control"
                                            placeholder="Phone"
                                            [(ngModel)]="phone"
                                        />
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            name="msg_subject"
                                            class="form-control"
                                            placeholder="Subject"
                                            [(ngModel)]="subject"
                                        />
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <textarea
                                            name="message"
                                            class="form-control"
                                            cols="30"
                                            rows="5"
                                            placeholder="Your Message"
                                            [(ngModel)]="message"
                                        ></textarea>
                                    </div>
                                </div>
                                <ngx-recaptcha2
                                    siteKey="6LcZ5HcjAAAAAMGlbzzV8s9UYfujBytGMFybHwTI"
                                    (success)="handleSuccess($event)"
                                    useGlobalDomain="false"
                                >
                                </ngx-recaptcha2>
                                <div class="col-md-12 col-lg-12">
                                    <button
                                        type="submit"
                                        class="drop-btn"
                                        (click)="send()"
                                        [disabled]="!isSuccess"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div
                    class="speciality-item speciality-right speciality-right-two speciality-right-three"
                    style="
                        background-image: url(assets/img/contactus.png) !important;
                    "
                >
                    <img src="" alt="Contact" />

                    <!--div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+63 (32) 255-8000</p>
                    </div-->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-map-area">
    <div id="map">
        <div class="mapouter">
            <div class="gmap_canvas">
                <iframe
                    class="gmap_iframe"
                    width="100%"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=chong hua hospital&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe
                ><a href="https://mcpenation.com/">Minecraft Website</a>
            </div>
            <style>
                .mapouter {
                    position: relative;
                    text-align: right;
                    width: 100%;
                    height: 400px;
                }

                .gmap_canvas {
                    overflow: hidden;
                    background: none !important;
                    width: 100%;
                    height: 400px;
                }

                .gmap_iframe {
                    height: 400px !important;
                }
            </style>
        </div>
    </div>
</div>
