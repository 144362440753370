<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Institutional Review Board</h2>
                <ul>
                    <li><a>Chong Hua Hospital</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="hospital-area pb-70">
    <div class="container">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape" />
        </div>

        <div class="row m-0 align-items-center">
            <div class="col-lg-12"><br /></div>
            <div class="col-lg-6 pointHand" (click)="show('sop')">
                <h2 style="color: blue">Standard Operating Procedure</h2>
            </div>
            <div class="col-lg-6 pointHand" (click)="show('Downloadable')">
                <h2 style="color: blue">Downloadable Forms</h2>
            </div>
        </div>
        <div class="row m-0 align-items-center">
            <div class="col-lg-12"><br /></div>

            <div class="col-lg-10 offset-lg-1" *ngIf="clickedData == 'sop'">
                <!--b
                    ><a href="assets/downloads/SOP v7.pdf" target="_BLANK"
                        >Download Institutional Review Board Standard Operating
                        Procedure</a
                    >
                </b-->
                <b>Chapter 1 - IRB Structure and Composition</b><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 1 - IRB Structure and Composition/1.1 Ethical Framework and Constitution of the IRB_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >1.1 - Ethical Framework and Constitution of the IRB</a
                ><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 1 - IRB Structure and Composition/1.2 Selection of Independent Consultants_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >1.2 - Selection of Independent Consultants</a
                ><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 1 - IRB Structure and Composition/1.3 Training Personnel and Ethics Committee Members_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >1.3 - Training Personnel and Ethics Committee Members</a
                ><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 1 - IRB Structure and Composition/1.4 Confidentiality or Conflict of Interest Agreement_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >1.4 - Confidentiality or Conflict of Interest Agreement</a
                ><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 1 - IRB Structure and Composition/1.5 Incentives for IRB Members and Consultants_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >1.5 - Incentives for IRB Members and Consultants</a
                ><br />
                <b>Chapter 2 - Types of Review</b><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 2 - Types of Review/2.1 Expedited Review_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >2.1 - Expedited Review</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 2 - Types of Review/2.2 Full Board Review_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >2.2 - Full Board Review</a
                ><br />
                <b>Chapter 3 - Initial Review</b><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 3 - Initial Review/3.1 Management of Protocols Submissions_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >3.1 - Management of Protocols Submissions</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 3 - Initial Review/3.2 Use of Study Assessment Form_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >3.2 - Use of Study Assessment Form</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 3 - Initial Review/3.3 Review of Medical Device Study_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >3.3 - Review of Medical Device Study</a
                ><br />
                <b>Chapter 4 - Continuing Review</b><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 4 - Continuing Review/4.1 Review of Final Report_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >4.1 - REVIEW OF FINAL REPORT</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 4 - Continuing Review/4.2 Study Protocol Amendment_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >4.2 - Study Protocol Amendment</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 4 - Continuing Review/4.3 Review of Serious Adverse Event (SAE) Reports_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >4.3 - Review of Serious Adverse Event (SAE) Reports</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 4 - Continuing Review/4.4 Management of Protocol Deviation and Violation_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >4.4 - Management of Protocol Deviation and Violation</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 4 - Continuing Review/4.5 Management of Study Termination_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >4.5 - Management of Study Termination</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 4 - Continuing Review/4.6 SOP on Protocol Continuing Review_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >4.6 - SOP on Protocol Continuing Review</a
                ><br />
                <b>Chapter 5 - Meetings</b><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 5 - Meetings/5.1 Preparation and Conduct of Meeting_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >5.1 - Preparation and Conduct of Meeting</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 5 - Meetings/5.2 Emergency Meetings and other matters_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >5.2 - Emergency Meetings and other matters</a
                ><br />
                <b
                    >Chapter 6 - Documentation and Management of Files and
                    Archiving</b
                ><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 6 - Documentation and Management of Files and Archiving/6.1 Preparation of the Minutes of the IRB Meeting_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >6.1 - Preparation of the Minutes of the IRB Meeting</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 6 - Documentation and Management of Files and Archiving/6.2 Communicating IRB Decisions Incoming Outgoing Communications_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >6.2 - Communicating IRB Decisions Incoming Outgoing
                    Communications</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 6 - Documentation and Management of Files and Archiving/6.3 Management of the Active Files_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >6.3 - Management of the Active Files</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 6 - Documentation and Management of Files and Archiving/6.4 Archiving and Retrieval of Documents_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >6.4 - Archiving and Retrieval of Documents</a
                ><br />

                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 6 - Documentation and Management of Files and Archiving/6.5 Maintenance of Confidentiality of Study Files and IRB Documents_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >6.5 - Maintenance of Confidentiality of Study Files and IRB
                    Documents</a
                ><br />
                <b>Chapter 7 - Site Monitoring Visits</b><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 7 - Site Monitoring Visits/7.1 Site Monitoring Visits_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >7.1 -Site Monitoring Visits</a
                ><br />

                <b>Chapter 8 - Response to Research Participants Request</b
                ><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 8 - Response to Research Participants Request/8.1 Response to Research Participants Request_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >8.1 - Response to Research Participants Request</a
                ><br />
                <b>Chapter 9 - Writing and Revising SOPs</b><br />
                &nbsp;&nbsp;<a
                    href="assets/downloads/Chapter 9 - Writing, & Revising SOPs/9.1 Writing, Reviewing, Distributing and Amending SOP for IRB_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >9.1 - Writing, Reviewing, Distributing and Amending SOP for
                    IRB</a
                ><br />

                <a
                    href="assets/downloads/Chapter 10 - Glossary/10 Glossary.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    ><b>10 - Glossary</b></a
                ><br />
            </div>
            <div
                class="col-lg-10 offset-lg-1"
                *ngIf="clickedData == 'Downloadable'"
            >
                <a
                    href="assets/downloads/Application Forms Residents Study/Application Form (1 of 4)_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >APPLICATION FORM (for RESIDENTS STUDY) 1 of 4</a
                ><br />
                <a
                    href="assets/downloads/Application Forms Residents Study/Application Form (2 of 4)_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >APPLICATION FORM (for RESIDENTS STUDY) 2 of 4</a
                ><br />
                <a
                    href="assets/downloads/Application Forms Residents Study/Application Form (3 of 4)_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >APPLICATION FORM (for RESIDENTS STUDY) 3 of 4</a
                ><br />
                <a
                    href="assets/downloads/Application Forms Residents Study/Application Form (4 of 4)_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >APPLICATION FORM (for RESIDENTS STUDY) 4 of 4</a
                ><br />

                <a
                    href="assets/downloads/Application Forms Sponsor Initiated Study/Application Form (1 of 4)_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >APPLICATION FORM (for SPONSOR INITIATED STUDY) 1 of 4</a
                ><br />
                <a
                    href="assets/downloads/Application Forms Sponsor Initiated Study/Application Form (2 of 4)_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >APPLICATION FORM (for SPONSOR INITIATED STUDY) 2 of 4</a
                ><br />
                <a
                    href="assets/downloads/Application Forms Sponsor Initiated Study/Application Form (3 of 4)_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >APPLICATION FORM (for SPONSOR INITIATED STUDY) 3 of 4</a
                ><br />
                <a
                    href="assets/downloads/Application Forms Sponsor Initiated Study/Application Form (4 of 4)_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >APPLICATION FORM (for SPONSOR INITIATED STUDY) 4 of 4</a
                ><br />
                <a
                    href="assets/downloads/HRP-IRB-017_ICF STANDARD TEMPLATE FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >ICF STANDARD TEMPLATE FORM</a
                ><br />
                <a
                    href="assets/downloads/HRP-IRB-017_INVESTIGATORS BROCHURE STANDARD TEMPLATE FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >INVESTIGATORS BROCHURE STANDARD TEMPLATE FORM</a
                ><br />
                <a
                    href="assets/downloads/HRP-IRB-017_PROTOCOL AMENDMENT STANDARD TEMPLATE FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >PROTOCOL AMENDMENT STANDARD TEMPLATE FORM</a
                ><br />
                <a
                    href="assets/downloads/HRP-IRB-017_PROTOCOL AMENDMENT SUBMISSION FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >PROTOCOL AMENDMENT SUBMISSION FORM</a
                ><br />
                <a
                    href="assets/downloads/HRP-IRB-018_CONTINUING REVIEW APPLICATION FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >CONTINUING REVIEW APPLICATION FORM</a
                ><br />
                <a
                    href="assets/downloads/HRP-IRB-020_END OF STUDY REPORT FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >END OF STUDY REPORT FORM</a
                ><br />
                <a
                    href="assets/downloads/HRP-IRB-021_PROTOCOL DEVIATION-VIOLATION RECORD FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >PROTOCOL DEVIATION-VIOLATION RECORD FORM</a
                ><br />
                <a
                    href="assets/downloads/HRP-IRB-024_SERIOUS ADVERSE EVENT REPORT FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >SERIOUS ADVERSE EVENT REPORT FORM</a
                ><br />

                <a
                    href="assets/downloads/HRP-IRB-025_UNEXPECTED ADVERSE EVENT REPORT FORM_new logo.pdf"
                    target="_BLANK"
                    style="cursor: pointer"
                    >UNEXPECTED ADVERSE EVENT REPORT FORM</a
                ><br />
            </div>
        </div>
    </div>
</section>
