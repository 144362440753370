<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Admission Procedure</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape" />
        </div>

        <div class="row m-0 align-items-center">
            <div class="col-lg-6 offset-lg-3">
                <br />
                <div [innerHtml]="description"></div>
                <!--div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 offset-lg-1">
                                <img
                                    class="img-fluid"
                                    src="../../../../../assets/img/Room Reservation guidelines _v2.jpg"
                                />
                            </div>
                        </div>
                    </div>
                </div-->
                <!--div class="hospital-item ">
                    <br />
    
                    <p>
                        <b>Step 1</b><br />
                        Patients proceed to the Triage Area for evaluation and assessment, or to the treatment area for
                        emergency management.
                        Patients with Direct-to-Room orders (within twenty-four (24) hours of issuance) from their
                        Attending Physicians may
                        proceed directly to Admission. please click HERE
                        <br /><br />
                        <b>Step 2</b><br />
                        The patient or patient's significant other should fill out the Patient Data Sheet and submit it
                        to Admission for
                        processing, partial payment and issuance of Admission Kit.<br /><br />

                        Important Admission Reminders<br />
                        Health Maintenance Organization (HMO) insurance or company covered hospitalization should have a
                        Letter of Authorization
                        (LOA) faxed to +63(032) 256 3140, but the original LOA shall be required upon discharge. Please
                        call our HMO/Insurance
                        coordinator at local 76109 for any concerns. A
                        refundable amount is added to your bill for items borrowed from the Admitting Section (e.g. TV
                        remote control / Digibox
                        (SkyCable) Remote controls).
                    </p>

                </div-->
            </div>
        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <!--div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                        <p>World class, state-of-the-art facilities, paired with an efficient and compassionate medical
                            staff dedicated to bringing
                            you a comprehensive, cost-efficient patient experience designed for your specific needs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</section>
