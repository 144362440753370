<div class="loading-template">
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-12 pointHand">Anesthesiology</div>
                <div class="col-md-12 pointHand">
                    Family and Community Medicine
                </div>
                <div class="col-md-12 pointHand">
                    Internal Medicine
                    <div class="row">
                        <div class="col-md-6">
                            <option value="med-cardiology">
                                &nbsp;&nbsp;-Cardiology
                            </option>
                            <option value="med-dermatology">
                                &nbsp;&nbsp;-Dermatology
                            </option>
                            <option value="med-endocrinology">
                                &nbsp;&nbsp;-Endocrinology
                            </option>
                            <option value="med-gastroenterology">
                                &nbsp;&nbsp;-Gastroenterology
                            </option>
                            <option value="med-hematology">
                                &nbsp;&nbsp;-Hematology
                            </option>
                            <option value="med-infectious diseases">
                                &nbsp;&nbsp;-Infectious Diseases
                            </option>
                        </div>
                        <div class="col-md-6">
                            <option value="med-nephrology">
                                &nbsp;&nbsp;-Nephrology
                            </option>
                            <option value="med-neurology">
                                &nbsp;&nbsp;-Neurology
                            </option>
                            <option value="med-oncology">
                                &nbsp;&nbsp;-Oncology
                            </option>
                            <option value="med-pulmonary">
                                &nbsp;&nbsp;-Pulmonary and Critical Care
                            </option>
                            <option value="med-rehabilitation">
                                &nbsp;&nbsp;-Rehabilitation Medicine
                            </option>
                            <option value="med-rheumatology">
                                &nbsp;&nbsp;-Rheumatology
                            </option>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 pointHand">
                    &nbsp;&nbsp;Obstetrics & Gynecology
                </div>
                <div class="col-md-12 pointHand">&nbsp;&nbsp;Orthopedics</div>
                <div class="col-md-12 pointHand">&nbsp;&nbsp;Pathology</div>
                <div class="col-md-12 pointHand">&nbsp;&nbsp;Pediatrics</div>
                <div class="col-md-12 pointHand">&nbsp;&nbsp;Radiology</div>
                <div class="col-md-12 pointHand">&nbsp;&nbsp;Surgery</div>
            </div>
        </div>
    </div>
</div>
