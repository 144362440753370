<div class="about-area" style="padding-top: 20px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Cathay-Drug-Logo-1024x791.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="col-lg-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <iframe
                                    src="https://drive.google.com/file/d/1vJYELmB4nKNIg27-RZzV-HLyK17Bfap0/preview"
                                    width="100%"
                                    height="100%"
                                    allow="never"
                                ></iframe>
                            </div>
                            <div class="col-lg-6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
1
<div class="about-area" style="padding-top: 20px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/FAS LOGO.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="col-lg-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <iframe
                                    src="https://drive.google.com/file/d/1G0ZGQy5I17HF9djAy_R0Y3fYdMCqSZrR/preview"
                                    width="100%"
                                    height="100%"
                                    allow="never"
                                ></iframe>
                            </div>
                            <div class="col-lg-6">
                                <iframe
                                    src="https://drive.google.com/file/d/1KCqgnd5VMaXiPVNJEBt7QbPaLnlTe3vb/preview"
                                    width="100%"
                                    height="100%"
                                    allow="never"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

3

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Roche Company Logo.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/11rN-2l3SZH-DaUhSoSXXMyycrLFVhXYx/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1ddKAY8i0TEQ_7fvHuddbolpCm_gWw-t-/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1lenlMeorerJCY5sNlha2iPQ4Ja_4ut7V/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1TQYcL0Hx_WFC3c1Q3fPgqhsW7o3aRvZ-/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1lenlMeorerJCY5sNlha2iPQ4Ja_4ut7V/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1ot1O2XqPNrg-aL_l97yQFkdMCx6V8QYr/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <!-- 
                            
                        
                        
                        
                        
                        -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
2
<div class="about-area" style="padding-top: 20px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/siemens-global-vector-logo.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="col-lg-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <iframe
                                    src="https://drive.google.com/file/d/1FiN06AY1L0B0CrVTB-0UpwZ61iyzpuR6/preview"
                                    width="100%"
                                    height="100%"
                                    allow="never"
                                ></iframe>
                            </div>
                            <div class="col-lg-6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Unilab Logo.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1KloDR03mEApTmI14dUE2guFs4MEqVT86/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1uDwqIjQ9ssXYbRoLiGYt2XACvwffhC6w/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1UqWeFkRevpy-mOJ4GZpvhy2O28Bp0G6y/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1uWnHbFAfI758bPgFc1SHoQuaDW3VW-Hi/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1cJrxLTdRsf47GV5vXCdWOo78Qwp97YRA/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/13mjzfr24e0TKOiGpT4UYNtRZ8Bti1Lb3/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1rXMywYI8C-0llHR1g-ColAedwPBlVd-Q/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1ICzjExQutUCaI9GET7N1K0kXg1FGc7HJ/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1KvY4z98ZorYrk02GuqeLYnSBlSsAV2X_/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/advance abilities.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1ErSo2PCexh8Z5tVEQM2x0cRgw7aaYTNt/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1oJruoZ4bawx8nTguBC14cl7M6fw1kstN/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/12AWof4UjIVgmMWZmnD72XpwvcNSn9TE4/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1K-EXrlfNIiYV0u9Rk0FEkPFjlnq65o2Q/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/GMS.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1">
                <img
                    src="../../../../../assets/img/mas-rise/GMS BANNER.jpg"
                    alt="About"
                />
            </div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1oW11BONp8LbAAsl4L6c4H2evYgpj0NZ9/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/natrapharm logo.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1HcMWTfyE-Q_x6lM5wz3iFWtPB6SEKaD5/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/16LHT8LUEgAJnfkcOCb1gerKLefvyJNZF/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Intermed Pharma.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Shimadzu Logo (1).png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1-FIf_Ro-hjJP_F8lpoFeho1QKzcjRTdM/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/12PIoDQjyZ7xar9oYcqpedfn8a4VHGfrs/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Zemed logo 2 (1).png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1rYUMT0g3N9MxVvY930FIJX9f6PCVDBOV/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1XsP8JvVhJBFIbnGIrWpWDdzV367t9jxH/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/116vM7Mutgwsr84RU77r8dFwMS62CL8sg/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1uEGpHCL75ex1j9roNG1Im011Z_OR96qG/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Marsman Logo.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1cHjCUOaj8psOUpd3QdqfzHANUYulGqqa/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Silver Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Biosite Logo.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1">
                <img
                    src="../../../../../assets/img/mas-rise/BIOSITE Full Page AD.png"
                    alt="About"
                />
            </div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1fW8TIAxXvnubM8DeFMgVA5IzwPU6izGd/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Silver Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/MRL-Logo-Marketing.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/JPEG FLYER.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/1PAGEDAD WORD.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1EreBMn06Qap4Hk7jP-hbVh21dK-qurVe/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/102EoYmwJUukCf7Cfjq2kFYKFpFSGXPAD/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Silver Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/tamiga.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/1 Product List .PNG"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/2 Product List.PNG"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Sannovex logo.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1UMoEg6okRRFMtNCbKHfjeDLImDC8Qjyz/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Unilab-logo.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1AT2O3ARiWZ3XINqh7FfJbTnntojWMun0/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/17OduZCp-aWH2zLaJHE_IUPvLtpesMjRe/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1VQ56omieZsS46GLLQjGfx4NFxtmyx3pQ/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1BNK_e-po1X-oOgk8AGjPeqqL678VwPSr/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1wA2lgA0wlqSoEN7R3WHE2t3nXb_-VvVM/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1utWwO6Ythit-FCTGs3cjpCIuUinU8AQF/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Zemed logo 2.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1rzwnIQzAItvUPCsrYw5cGnoqe_MokQua/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1fed9Hral3R6NUEW6PjSVr8x2FLwUBKna/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1zWm_Mc_4XV-Aka0aChWwdz9cPKBTzRLl/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/16hOOyaN5ue_r9qdrbg_5qprMUVKZPQk-/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1i77rcoOZwDSiTf9W4rLZ5Dyhu3apbn64/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1z273NQ0oRihsIOmDDueoBZgQA8bXXi3x/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Cathay Drug.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1Ws4ZocGalFRNZgEU0f8zX4qg-G0YL0hC/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Gold Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/IDS Med.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1qa-HjUzh4Lqr9_X4twrVUpys7A8uNLZP/preview"
                                width="100%"
                                height="70%"
                                allow="never"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Silver Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/download.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Silver Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/NMPC Logo High Res.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->
