<app-page-title1 pageTitle="Pharmacy Info Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="description"
                                        [ngModelOptions]="{ standalone: true }"
                                    ></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        (click)="startUpload()"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
