<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>EHU Plans</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Executive Health Unit</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="hospital-area pb-70">
    <div class="container p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape" />
        </div>

        <div class="row m-0 align-items-center">
            <div class="col-lg-12 col-12">
                <br />
                <div>
                    <tabset>
                        <tab *ngFor="let x of promos">
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan(x.attributes.plan)"
                                    [ngClass]="class1(x.attributes.plan)"
                                    >{{ x.attributes.plan }}</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <br />
                                    <!--h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        {{ x.attributes.plan }}
                                    </h5-->

                                    <b>{{ x.attributes.name }}</b
                                    ><br /><br />
                                </div>
                                <div class="col-lg-6">
                                    <div
                                        [innerHTML]="x.attributes.details"
                                    ></div>
                                    <div
                                        [innerHTML]="x.attributes.specialexam"
                                    ></div>
                                    <div
                                        [innerHTML]="x.attributes.contact"
                                    ></div>
                                </div>
                                <div class="col-lg-6">
                                    <img
                                        [src]="url + pricelist[0].image"
                                        alt="Big Image"
                                        id="img_big"
                                    />
                                </div>
                            </div>
                        </tab>

                        <!--tab>
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan('1')"
                                    [ngClass]="class1('1')"
                                    >EHU Plan I</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        EHU Plan I
                                    </h5>

                                    <b>Basic Health Check-Up</b>
                                </div>
                                <div class="col-lg-6">
                                    <strong>Laboratory Studies</strong>
                                    <ul class="">
                                        <li>
                                            Complete Blood Count (CBC w/
                                            Differential)
                                        </li>
                                        <li>Glycosylated Hemoglobin HBA1c</li>
                                        <li>Glucose Fasting</li>
                                        <li>Creatinine, Serum</li>
                                        <li>Urea Nitrogen (BUN)</li>
                                        <li>Uric Acid, Serum</li>
                                        <li>Alkaline Phosphatase</li>
                                        <li>
                                            Electrolytes
                                            <ul>
                                                <li>Sodium, Serum</li>
                                                <li>Potassium, Serum</li>
                                            </ul>
                                        </li>
                                        <li>Protein, Total with A/G ratio</li>
                                        <li>
                                            Bilirubin, Total, Direct and
                                            Indirect
                                        </li>
                                        <li>
                                            Lipid Panel
                                            <ul>
                                                <li>Cholesterol (Total)</li>
                                                <li>
                                                    Cholesterol HDL or Alpha
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol LDL or Beta
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol VLDL or Prebeta
                                                    Lipoprotein
                                                </li>
                                                <li>Triglycerides</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="showonlg"><br /></div>
                                    <ul class="ul-2">
                                        <li>
                                            Serum Glutamate-Pyruvate
                                            Transaminase (SGPT)
                                        </li>
                                        <li>
                                            Serum Glutamic Oxaloacetic
                                            Transaminase (SGOT)
                                        </li>
                                        <li>Calcium, Total</li>
                                        <li>Lactic Dehydrogenase (LDH)</li>
                                        <li>
                                            Gamma-Glutamyl Transpeptidase or
                                            (GGT)
                                        </li>
                                        <li>Urinalysis</li>
                                        <li>Occult Blood Test</li>
                                    </ul>
                                    <strong>Special Examination</strong>
                                    <ul class="ul-2">
                                        <li>Electrocardiogram (ECG)</li>
                                        <li>Chest X-ray PA or AP</li>
                                    </ul>
                                </div>
                            </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan('2')"
                                    [ngClass]="class1('2')"
                                    >EHU Plan II</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        EHU Plan II
                                    </h5>
                                    <b>Cardiovascular Check-Up</b>
                                </div>
                                <div class="col-lg-6">
                                    <strong>Laboratory Studies</strong>
                                    <ul class="ul-2">
                                        <li>
                                            Complete Blood Count (CBC w/
                                            Differential Count)
                                        </li>
                                        <li>Glycosylated Hemoglobin HBA1c</li>
                                        <li>Glucose Fasting</li>
                                        <li>Creatinine, Serum</li>
                                        <li>Blood Urea Nitrogen (BUN)</li>
                                        <li>Uric Acid Serum</li>
                                        <li>Alkaline Phosphatase</li>
                                        <li>
                                            Electrolytes
                                            <ul>
                                                <li>Sodium, Serum</li>
                                                <li>Potassium, Serum</li>
                                            </ul>
                                        </li>
                                        <li>Protein, Total with A/G ratio</li>
                                        <li>Bilirubin, Direct and Indirect</li>
                                        <li>
                                            Lipid Panel
                                            <ul>
                                                <li>Cholesterol (Total)</li>
                                                <li>
                                                    Cholesterol HDL or Alpha
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol LDL or Beta
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol VLDL or Prebeta
                                                    Lipoprotein
                                                </li>
                                                <li>Triglycerides</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Serum Glutamate-Pyruvate
                                            Transaminase (SGPT)
                                        </li>
                                        <li>
                                            Serum Glutamic Oxaloacetic
                                            Transaminase (SGOT)
                                        </li>
                                        <li>Calcium, Total</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="showonlg"><br /></div>
                                    <ul>
                                        <li>Lactic Dehydrogenase (LDH)</li>
                                        <li>
                                            Gamma Glutamyl Transpeptidase or
                                            (GGT)
                                        </li>
                                        <li>Urinalysis</li>
                                        <li>Occult Blood Test</li>
                                        <li>*Blood Typing (A, B, O, Rh)</li>
                                        <li>PSA total</li>
                                    </ul>
                                    <strong>Special Examination</strong>
                                    <ul class="ul-2">
                                        <li>Electrocardiogram (ECG)</li>
                                        <li>Chest X-ray PA or AP</li>
                                        <li>Treadmill Stress Test</li>
                                        <li>
                                            Eye Examination
                                            <ul>
                                                <li>Visual Acuity</li>
                                                <li>Confrontation Test</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Pelvic Exam including PAP smear
                                            (females), OPD
                                        </li>
                                        <li>
                                            Cytopath Study (PAP Smear /
                                            Cervicovaginal
                                        </li>
                                        <li>Simple Spirometry</li>
                                        <li>
                                            ENT (Ear, Nose and Throat)
                                            Examination
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan('3')"
                                    [ngClass]="class1('3')"
                                    >EHU Plan III</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        EHU Plan III
                                    </h5>
                                    <b>
                                        Comprehensive Check-Up w/ Stress Test
                                    </b>
                                </div>
                                <div class="col-lg-6">
                                    <strong>Laboratory Studies</strong>
                                    <ul class="ul-2">
                                        <li>
                                            Complete Blood Count (CBC w/
                                            Differential)
                                        </li>
                                        <li>Glycosylated Hemoglobin HBA1c</li>
                                        <li>Glucose Fasting</li>
                                        <li>Creatinine, Serum</li>
                                        <li>Urea Nitrogen (BUN)</li>
                                        <li>Uric Acid, Serum</li>
                                        <li>Alkaline Phosphatase</li>
                                        <li>
                                            Electrolytes
                                            <ul>
                                                <li>Sodium, Serum</li>
                                                <li>Potassium, Serum</li>
                                            </ul>
                                        </li>
                                        <li>Protein, Total with A/G ratio</li>
                                        <li>
                                            Bilirubin, Total, Direct and
                                            Indirect
                                        </li>
                                        <li>
                                            Lipid Panel
                                            <ul>
                                                <li>Cholesterol (Total)</li>
                                                <li>
                                                    Cholesterol HDL or Alpha
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol LDL or Beta
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol VLDL or Pre-beta
                                                    Lipoprotein
                                                </li>
                                                <li>Triglycerides</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Serum Glutamate-Pyruvate
                                            Transaminase (SGPT)
                                        </li>
                                        <li>
                                            Serum Glutamic Oxaloacetic
                                            Transaminase (SGOT)
                                        </li>
                                        <li>Calcium, Total</li>
                                        <li>Lactic Dehydrogenase (LDH)</li>
                                        <li>
                                            Gamma Glutamyl Transpeptidase or
                                            (GGT)
                                        </li>
                                        <li>Urinalysis</li>
                                        <li>Occult Blood Test</li>
                                        <li>*Blood Typing (A, B, O, Rh)</li>
                                        <li>PSA total</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="showonlg"><br /></div>
                                    <ul class="ul-2">
                                        <li>Special Chemistries</li>
                                        <ul>
                                            <li>
                                                HTSH (Human Thyroid Stimulating
                                                Hormone)
                                            </li>
                                            <li>Alpha-Fetoprotein (AFP) EIA</li>
                                            <li>
                                                Hepatitis B - surface antigen
                                                HBSAG (quantitative)
                                            </li>
                                            <li>Antibody to HBSAG, EIA</li>
                                            <li>
                                                IGM Antibody to Hepatitis A, EIA
                                            </li>
                                            <li>
                                                Antibody to Hepatitis C, EIA
                                                (quantitative)
                                            </li>
                                        </ul>
                                        <li>
                                            Treponema Pallidum
                                            Immunofluorescence
                                        </li>
                                    </ul>
                                    <strong>Special Examination</strong>
                                    <ul class="ul-2">
                                        <li>Electrocardiogram (ECG)</li>
                                        <li>Chest X-ray PA or AP</li>
                                        <li>Treadmill Stress Test</li>
                                        <li>
                                            Eye Examination (Visual Acuity,
                                            Color Test, Confrontation Test,
                                            Fundoscopy)
                                        </li>
                                        <li>
                                            Pelvic Exam including PAP smear
                                            (females), OPD
                                        </li>
                                        <li>
                                            Cytopath Study (PAP Smear /
                                            Cervicovaginal)
                                        </li>
                                        <li>Flexible Sigmoidoscopy</li>
                                        <li>Ultrasound of Whole Abdomen</li>
                                        <li>Simple Spirometry</li>
                                        <li>Carotid Duplex Scan</li>
                                        <li>2-D Echocardiogram w/ Doppler</li>
                                        <li>Mammography, Digital (female)</li>
                                        <li>
                                            ENT (Ear, Nose and Throat)
                                            Examination
                                        </li>
                                    </ul>
                                </div>
                            </div> </tab
                        ><tab>
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan('4')"
                                    [ngClass]="class1('4')"
                                    >EHU Plan IV</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        EHU Plan IV
                                    </h5>
                                    <b
                                        >Comprehensive Check-Up w/o Stress
                                        Test</b
                                    >
                                </div>
                                <div class="col-lg-6">
                                    <strong>Laboratory Studies</strong>
                                    <ul class="ul-2">
                                        <li>
                                            Complete Blood Count (CBC w/
                                            Differential)
                                        </li>
                                        <li>Glycosylated Hemoglobin HBA1c</li>
                                        <li>Glucose Fasting</li>
                                        <li>Creatinine, Serum</li>
                                        <li>Urea Nitrogen (BUN)</li>
                                        <li>Uric Acid, Serum</li>
                                        <li>Alkaline Phosphatase</li>
                                        <li>
                                            Electrolytes
                                            <ul>
                                                <li>Sodium, Serum</li>
                                                <li>Potassium, Serum</li>
                                            </ul>
                                        </li>
                                        <li>Protein, Total with A/G ratio</li>
                                        <li>
                                            Bilirubin, Total, Direct and
                                            Indirect
                                        </li>
                                        <li>
                                            Lipid Panel
                                            <ul>
                                                <li>Cholesterol (Total)</li>
                                                <li>
                                                    Cholesterol HDL or Alpha
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol LDL or Beta
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol VLDL or Pre-beta
                                                    Lipoprotein
                                                </li>
                                                <li>Triglycerides</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Serum Glutamate-Pyruvate
                                            Transaminase (SGPT)
                                        </li>
                                        <li>
                                            Serum Glutamic Oxaloacetic
                                            Transaminase (SGOT)
                                        </li>
                                        <li>Calcium, Total</li>
                                        <li>Lactic Dehydrogenase (LDH)</li>
                                        <li>
                                            Gamma Glutamyl Transpeptidase or
                                            (GGT)
                                        </li>
                                        <li>Urinalysis</li>
                                        <li>Occult Blood Test</li>
                                        <li>*Blood Typing (A, B, O, Rh)</li>
                                        <li>PSA total</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="showonlg"><br /></div>
                                    <ul class="ul-2">
                                        <li>
                                            Special Chemistries
                                            <ul>
                                                <li>
                                                    HTSH (Human Thyroid
                                                    Stimulating Hormone)
                                                </li>
                                                <li>
                                                    Alpha-Fetoprotein (AFP) EIA
                                                </li>
                                                <li>
                                                    Hepatitis B - surface
                                                    antigen HBSAG (quantitative)
                                                </li>
                                                <li>Antibody to HBSAG, EIA</li>
                                                <li>
                                                    IGM Antibody to Hepatitis A,
                                                    EIA
                                                </li>
                                                <li>
                                                    Antibody to Hepatitis C, EIA
                                                    (quantitative)
                                                </li>
                                            </ul>
                                        </li>

                                        <li>Syphilis Antibody Testing</li>
                                    </ul>
                                    <strong>Special Examination</strong>
                                    <ul class="ul-2">
                                        <li>Electrocardiogram (ECG)</li>
                                        <li>Chest X-ray PA or AP</li>
                                        <li>
                                            Eye Examination (Visual Acuity,
                                            Color Test, Confrontation Test,
                                            Fundoscopy)
                                        </li>
                                        <li>
                                            Pelvic Exam including PAP smear
                                            (females), OPD
                                        </li>
                                        <li>
                                            Cytopath Study (PAP Smear /
                                            Cervicovaginal)
                                        </li>
                                        <li>Flexible Sigmoidoscopy</li>
                                        <li>Ultrasound of whole Abdomen</li>
                                        <li>Simple Spirometry</li>
                                        <li>Carotid Duplex Scan</li>
                                        <li>2-D Echocardiogram w/ Doppler</li>
                                        <li>Mammography, Digital (female)</li>
                                        <li>
                                            ENT (Ear, Nose and Throat)
                                            Examination
                                        </li>
                                    </ul>
                                </div>
                            </div> </tab
                        ><tab>
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan('5')"
                                    [ngClass]="class1('5')"
                                    >EHU Plan V</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        EHU Plan V
                                    </h5>
                                    <b>Kidney Check-Up</b>
                                </div>
                                <div class="col-lg-6">
                                    <strong>Laboratory Studies</strong>
                                    <ul class="ul-2">
                                        <li>
                                            Complete Blood Count (CBC w/
                                            Differential)
                                        </li>
                                        <li>Glycosylated Hemoglobin HBA1c</li>
                                        <li>Glucose Fasting</li>
                                        <li>Creatinine, Serum</li>
                                        <li>Urea Nitrogen (BUN)</li>
                                        <li>Uric Acid, Serum</li>
                                        <li>
                                            Electrolytes
                                            <ul>
                                                <li>Sodium, Serum</li>
                                                <li>Potassium, Serum</li>
                                                <li>Chloride, Serum</li>
                                                <li>
                                                    Carbon Dioxide Combining
                                                    Power
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Phosphorus, Inorganic</li>
                                        <li>Protein, Total with A/G ratio</li>
                                        <li>Albumin, Serum</li>
                                        <li>
                                            Lipid Panel
                                            <ul>
                                                <li>Cholesterol (Total)</li>
                                                <li>
                                                    Cholesterol HDL or Alpha
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol LDL or Beta
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol VLDL or Pre-beta
                                                    Lipoprotein
                                                </li>
                                                <li>Triglycerides</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="showonlg"><br /></div>

                                    <ul class="ul-2">
                                        <li>Calcium, Total</li>
                                        <li>Urinalysis</li>
                                        <li>*Blood Typing (A, B, O, Rh)</li>
                                        <li>PSA total</li>
                                        <li>
                                            Special Chemistries
                                            <ul>
                                                <li>
                                                    Hepatitis B - surface
                                                    antigen HBSAG (quantitative)
                                                </li>
                                                <li>
                                                    Antibody to Hepatitis C, EIA
                                                    (quantitative)
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Micro - Albuminuria (Micral - Test)
                                        </li>
                                    </ul>
                                    <strong>Special Examination</strong>
                                    <ul class="ul-2">
                                        <li>Electrocardiogram (ECG)</li>
                                        <li>Chest X-ray PA or AP</li>
                                        <li>Ultrasound of Kidneys</li>
                                    </ul>
                                </div>
                            </div> </tab
                        ><tab>
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan('6')"
                                    [ngClass]="class1('6')"
                                    >EHU Plan VI</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        EHU Plan VI
                                    </h5>
                                    <b>Diabetes Profile</b>
                                </div>
                                <div class="col-lg-6">
                                    <strong>Laboratory Studies</strong>
                                    <ul class="ul-2">
                                        <li>
                                            Complete Blood Count (CBC w/
                                            Differential)
                                        </li>
                                        <li>Glycosylated Hemoglobin HBA1c</li>
                                        <li>Glucose Fasting</li>
                                        <li>Creatinine, Serum</li>
                                        <li>Uric Acid, Serum</li>
                                        <li>Protein, Total (24-hr Urine)</li>

                                        <li>
                                            Lipid Panel
                                            <ul>
                                                <li>Cholesterol (Total)</li>
                                                <li>
                                                    Cholesterol HDL or Alpha
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol LDL or Beta
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol VLDL or Pre-beta
                                                    Lipoprotein
                                                </li>
                                                <li>Triglycerides</li>
                                            </ul>
                                        </li>

                                        <li>
                                            Serum Glutamate-Pyruvate
                                            Transaminase (SGPT)
                                        </li>
                                        <li>Urinalysis</li>
                                        <li>*Blood Typing (A, B, O, Rh)</li>
                                        <li>
                                            Micro - Albuminuria (Micral-Test)
                                        </li>
                                        <li>Glucose 2 Hrs, Post Prandial</li>
                                        <li>Microalbuminm, urine (24 Hours)</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="showonlg"><br /></div>

                                    <strong>Special Examination</strong>
                                    <ul class="ul-2">
                                        <li>Electrocardiogram (ECG)</li>
                                        <li>
                                            Eye Examination (Visual Acuity,
                                            Color Test, Confrontation Test,
                                            Fundoscopy)
                                        </li>
                                        <li>Ultrasound of Kidneys</li>
                                        <li>Carotid Duplex Scan</li>
                                        <li>
                                            Arterial Duplex Scan Lower
                                            Extremities with Segmental P.S.
                                        </li>
                                        <li>2-D Echocardiogram w/ Doppler</li>
                                        <li>
                                            Nerve Conduction
                                            Velocity-Electromyogram (NCV - EMG)
                                        </li>
                                    </ul>
                                </div>
                            </div> </tab
                        ><tab>
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan('7')"
                                    [ngClass]="class1('7')"
                                    >EHU Plan VII</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        EHU Plan VII
                                    </h5>
                                    <b>Women's Health Package</b>
                                </div>
                                <div class="col-lg-6">
                                    <strong>Laboratory Studies</strong>
                                    <ul class="ul-2">
                                        <li>
                                            Complete Blood Count (CBC w/
                                            Differential)
                                        </li>
                                        <li>Glucose Fasting</li>
                                        <li>Creatinine, Serum</li>
                                        <li>Uric Acid, Serum</li>
                                        <li>Alkaline Phosphatase</li>
                                        <li>Protein, Total (24-hr Urine)</li>
                                        <li>
                                            Lipid Panel
                                            <ul>
                                                <li>Cholesterol (Total)</li>
                                                <li>
                                                    Cholesterol HDL or Alpha
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol LDL or Beta
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol VLDL or Pre-beta
                                                    Lipoprotein
                                                </li>
                                                <li>Triglycerides</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Serum Glutamic Oxaloacetic
                                            Transaminase (SGOT)
                                        </li>
                                        <li>Urinalysis</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="showonlg"><br /></div>

                                    <ul class="ul-2">
                                        <li>
                                            Special Chemistries
                                            <ul>
                                                <li>
                                                    Hepatitis B - surface
                                                    antigen HBSAG (quantitative)
                                                </li>
                                                <li>Antibody to HBSAG, EIA</li>
                                                <li>
                                                    IGM Antibody to Hepatitis A,
                                                    EIA
                                                </li>
                                                <li>
                                                    Antibody to Hepatitis C, EIA
                                                    (Quantitative)
                                                </li>
                                                <li>
                                                    Antibody to HBE Antigen, EIA
                                                </li>
                                                <li>HBE Antigen EIA</li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <strong>Special Examination</strong>
                                    <ul class="ul-2">
                                        <li>Electrocardiogram (ECG)</li>
                                        <li>Chest X-Ray PA or AP</li>
                                        <li>
                                            Pelvic Exam including PAP smear
                                            (females), OPD
                                        </li>
                                        <li>
                                            Cytopath Study (PAP Smear /
                                            Cervicovaginal)
                                        </li>
                                        <li>Mammography, Digital (female)</li>
                                        <li>
                                            Total Body Scan (Bone Densinometry)
                                        </li>
                                        <li>Trans-Vaginal Sonography</li>
                                        <li>Ultrasound of Upper Abdomen</li>
                                    </ul>
                                </div>
                            </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span
                                    (click)="ecuplan('8')"
                                    [ngClass]="class1('8')"
                                    >EHU Plan VIII</span
                                >
                            </ng-template>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5
                                        style="
                                            padding-top: 5px;
                                            padding-bottom: 5px;
                                        "
                                    >
                                        EHU Plan VIII
                                    </h5>
                                    <b>Sports Cardiology Package</b>
                                </div>
                                <div class="col-lg-6">
                                    <strong>Laboratory Studies</strong>
                                    <ul class="ul-2">
                                        <li>
                                            Complete Blood Count (CBC w/
                                            Differential Count)
                                        </li>
                                        <li>Glucose Fasting</li>
                                        <li>Creatinine, Serum</li>
                                        <li>
                                            Electrolytes
                                            <ul>
                                                <li>Potassium, Serum</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Lipid Panel
                                            <ul>
                                                <li>Cholesterol (Total)</li>
                                                <li>
                                                    Cholesterol HDL or Alpha
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol LDL or Beta
                                                    Lipoprotein
                                                </li>
                                                <li>
                                                    Cholesterol VLDL or Pre-beta
                                                    Lipoprotein
                                                </li>
                                                <li>Triglycerides</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Serum Glutamate-Pyruvate
                                            Transaminase (SGPT)
                                        </li>
                                        <li>Urinalysis</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="showonlg"><br /></div>

                                    <div class="group_of_br">
                                        <div class="_custom_height_10"></div>
                                    </div>
                                    <strong>Special Examination</strong>
                                    <ul class="ul-2">
                                        <li>Electrocardiogram (ECG)</li>
                                        <li>Chest X-Ray PA or AP</li>
                                        <li>Treadmill Stress-Echo</li>
                                    </ul>
                                </div>
                            </div></tab
                        -->
                    </tabset>
                </div>
            </div>
            <div class="col-lg-9 col-12"></div>
        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <!--div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                        <p>World class, state-of-the-art facilities, paired with an efficient and compassionate medical
                            staff dedicated to bringing
                            you a comprehensive, cost-efficient patient experience designed for your specific needs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</section>
