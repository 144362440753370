import { ThisReceiver } from "@angular/compiler";
import { Component, OnInit, ViewChild } from "@angular/core";

import { GeneralService } from "src/app/services/general.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";

@Component({
    selector: "app-residency-editor",
    templateUrl: "./residency-editor.component.html",
    styleUrls: ["./residency-editor.component.scss"],
})
export class ResidencyEditorComponent implements OnInit {
    description = "<p>Hello, world!</p>";
    id;
    constructor(private genServ: GeneralService) {
        this.mycontent = `<p>My html content</p>`;
    }
    addSlider = {
        data: {
            residencycolumn: "",
        },
    };
    ngOnInit(): void {
        // https://ckeditor.com/cke4/builder
        this.ckeConfig = {
            extraPlugins:
                "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
                "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
                "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
                "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
                "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
                "indentblock,indentlist,justify,link,list,liststyle,magicline," +
                "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
                "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
                "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
                "tabletools,templates,toolbar,undo,wsc,wysiwygarea",
        };
        this.get();
    }

    get() {
        //console.log("123312");
        this.genServ.get("cebu-residencies").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data.data[0].id);
                this.id = data.data[0].id;
                //console.log(data.data[0].attributes.residencycolumn);
                this.description = data.data[0].attributes.residencycolumn;
            },
        });
    }
    config: any;
    startUpload() {
        this.addSlider.data.residencycolumn = this.description;
        //console.log(this.addSlider);

        this.genServ
            .put("cebu-residencies", this.addSlider, this.id)
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (data: any) => {
                    //console.log(data);
                },
            });
    }
    name = "ng2-ckeditor";
    ckeConfig: any;
    mycontent: string;
    log: string = "";
    @ViewChild("myckeditor") ckeditor: any;
    onEditorChange(event) {
        //console.log(event);
    }

    onChange(event: any): void {
        //console.log(event);
        //console.log(this.mycontent);
        //this.log += new Date() + "<br />";
    }
    /* description = "<p>Hello, world!</p>";
    id;
    constructor(
        private genServ: GeneralService,
        private funcServ: FunctionsService
    ) {
        this.mycontent = `<p>My html content</p>`;
    }
    addSlider = {
        data: {
            residencycolumn: "",
            approver_id1: null,
            approver_id1_status: false,
            approver_id2: null,
            approver_id2_status: false,
        },
    };
    ngOnInit(): void {
        // https://ckeditor.com/cke4/builder
        this.ckeConfig = {
            extraPlugins:
                "easyimage,dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
                "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
                "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
                "filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo," +
                "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
                "indentblock,indentlist,justify,link,list,liststyle,magicline," +
                "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
                "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
                "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
                "tabletools,templates,toolbar,undo,wsc,wysiwygarea",
        };
        this.get();

        this.config.toolbar = [["TextColor", "BGColor"]];
    }

    jsonData;
    get() {
        let jsonData;
        this.jsonData = [];
        this.genServ.adminGet("cebu-residencies").subscribe({
            complete: () => {
                function comp(a, b) {
                    return (
                        new Date(a.attributes.createdAt).getTime() -
                        new Date(b.attributes.createdAt).getTime()
                    );
                }

                //console.log(this.jsonData.sort(comp));
                jsonData.forEach((element) => {
                    element.attributes._createdAt =
                        this.funcServ.convertDatetoMMDDYYYY(
                            element.attributes.createdAt
                        ) +
                        " " +
                        this.funcServ.getFormatAMPM(
                            element.attributes.createdAt
                        );

                    this.jsonData.push(element);
                });
                //console.log(this.jsonData);
            },
            error: (error) => {},
            next: (data: any) => {
                jsonData = data.data;
                ////console.log(data.data[0].id);
                this.id = data.data[0].id;
                ////console.log(data.data[0].attributes.residencycolumn);
                this.description = data.data[0].attributes.residencycolumn;
            },
        });
    }
    config: any;
    startUpload() {
        this.addSlider.data.residencycolumn = this.description;
        if (localStorage.getItem("approver_id1") != undefined) {
            this.addSlider.data.approver_id1 = Number(
                localStorage.getItem("approver_id1")
            );
        }
        if (localStorage.getItem("approver_id2") != undefined) {
            this.addSlider.data.approver_id2 =
                localStorage.getItem("approver_id2");
        }

        this.genServ.add("cebu-residencies", this.addSlider).subscribe({
            complete: () => {
                this.get();
            },
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data);
            },
        });
    }
    name = "ng2-ckeditor";
    ckeConfig: any;
    mycontent: string;
    log: string = "";
    @ViewChild("myckeditor") ckeditor: any;
    onEditorChange(event) {
        ////console.log(event);
    }

    onChange(event: any): void {
        ////console.log(event);
        ////console.log(this.mycontent);
        //this.log += new Date() + "<br />";
    }

    choose(y) {
        this.id = y.id;
        this.description = y.attributes.residencycolumn;
    }
    test(y, x) {
        let cssClass = "";
        if (y) {
            cssClass += "italicMe";
        }
        if (x == this.id) {
            cssClass += " boldMe";
        }
        return cssClass;
    }
    removeList(y) {
        //console.log(y);
        this.genServ.delete("cebu-residencies", y.id).subscribe({
            complete: () => {
                this.get();
            },
            error: (error) => {},
            next: (data: any) => {},
        });
    }*/
}
