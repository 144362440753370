import { Component, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-ecu-plans",
    templateUrl: "./ecu-plans.component.html",
    styleUrls: ["./ecu-plans.component.scss"],
})
export class EcuPlansComponent implements OnInit {
    constructor(private general: GeneralService) {}

    ngOnInit(): void {
        this.getSlider();
        this.getPrices();
    }
    isSelected = "1";
    ecuplan(plan) {
        this.isSelected = plan;
    }
    url = "https://strapi.chonghua.com.ph";
    class1(class1) {
        if (class1 == this.isSelected) {
            return "boldMe colorGreen";
        } else {
            return "colorGreen";
        }
    }
    promos;
    getSlider() {
        this.general.get("ecu-catalogs").subscribe({
            complete: () => {
                console.log(this.promos);
            },
            error: (error) => {},
            next: (data: any) => {
                this.promos = data.data;
            },
        });
    }
    pricelist;
    getPrices() {
        let xyz = [];
        function compareStrings(a, b) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase();
            b = b.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
        }
        this.general
            .get("price-lists?filters[title][$eq]=EXECUTIVE HEALTH UNIT")
            .subscribe({
                complete: () => {
                    this.pricelist = [];
                    this.pricelist = xyz.sort(function (a, b) {
                        return compareStrings(a.link, b.link);
                    });
                    console.log(this.pricelist);
                },
                error: (error) => {},
                next: (data: any) => {
                    console.log(data);

                    this.pricelist = data.data;
                    this.pricelist.forEach((element) => {
                        xyz.push(element.attributes);
                    });
                },
            });
    }
}
