import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-privacy",
    templateUrl: "./privacy.component.html",
    styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
    constructor(private modalService: BsModalService, private router: Router) {}

    ngOnInit(): void {}
    onClick() {
        this.modalService.hide();
    }
}
