<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>1st MAS Postgraduate Course</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Whats New</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-2">
                <table class="table">
                    <tbody>
                        <tr>
                            <td
                                class="pointHand"
                                [ngClass]="{ my_class: isChosen === '0' }"
                            >
                                <a
                                    href="https://drive.google.com/file/d/1Rhjhape6Z4pCJperd8rLF352KWMgqlKk/view"
                                    target="_BLANK"
                                    >Program</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="pointHand"
                                (click)="chosen('1')"
                                [ngClass]="{ my_class: isChosen === '1' }"
                            >
                                Poster
                            </td>
                        </tr>

                        <tr>
                            <td
                                class="pointHand"
                                [ngClass]="{ my_class: isChosen === '0' }"
                            >
                                <a
                                    href="https://forms.office.com/pages/responsepage.aspx?id=TcKz-O1IsU2LsIh9leLOKjGEM_h5m-9DpS1IJeBnzdBUQVpRM0M1MDlJWDZYTjJVMjVVOEpSRDZaUy4u"
                                    target="_BLANK"
                                    >Pre-Test</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="pointHand"
                                [ngClass]="{ my_class: isChosen === '0' }"
                            >
                                <a
                                    href="https://forms.office.com/r/bgJsU5K6yS"
                                    target="_BLANK"
                                    >Post-Test</a
                                >
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="pointHand"
                                [ngClass]="{ my_class: isChosen === '0' }"
                            >
                                <a
                                    href="https://forms.office.com/r/de63rTGVwk"
                                    target="_BLANK"
                                >
                                    Evaluation</a
                                >
                            </td>
                        </tr>

                        <tr
                            class="pointHand"
                            (click)="chosen('2')"
                            [ngClass]="{ my_class: isChosen === '2' }"
                        >
                            <td>Sponsors</td>
                        </tr>
                        <tr
                            class="pointHand"
                            (click)="chosen('3')"
                            [ngClass]="{ my_class: isChosen === '3' }"
                        >
                            <td>CHH Human Resources</td>
                        </tr>
                        <!--tr>
                            <td>Guidelines</td>
                        </tr>
                        <tr>
                            <td>Registration</td>
                        </tr>

                        <tr>
                            <td>Pre Test</td>
                        </tr>
                        <tr>
                            <td>Evaluation Day 1</td>
                        </tr-->
                    </tbody>
                </table>
            </div>
            <ng-container *ngIf="isChosen == '1'">
                <div class="col-lg-5">
                    <div class="about-item">
                        <div class="about-lef">
                            <img
                                src="../../../../../assets/img/events/masrise.png"
                                alt="About"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="about-item about-right">
                        <!--img src="assets/img/home-one/home-bg5.png" alt="About"-->
                        <h2>1st MAS Postgraduate Course</h2>
                        <div>
                            The Medical Ancillary Services Division of Chong Hua
                            Hospital will be having its 1st Postgraduate Course
                            via Zoom on August 10-11, 2023, with the theme,
                            “Future-Proofing Healthcare: Towards a Sustainable,
                            Resilient, Innovative, and Safe Quality Health
                            Service Delivery.” It is open to Doctors, Nurses,
                            Allied Healthcare Professionals, and Students.
                            <br /><br />This postgraduate program is intended to
                            prepare healthcare professionals with the
                            information and skills necessary to future-proof
                            healthcare systems in terms of sustainability,
                            resilience, innovation, and safety in the delivery
                            of quality healthcare. To maintain the
                            sustainability of healthcare systems, healthcare
                            professionals must be proactive in adjusting to
                            emerging trends and problems due to the quickly
                            changing healthcare environment. <br /><br />This
                            course will examine the fundamental concepts and
                            guiding principles of future-proofing healthcare
                            systems, such as sustainability, resilience,
                            innovation, and safety. Participants will gain
                            knowledge of the most recent healthcare trends and
                            technologies, as well as implementation strategies
                            for these advances. In addition, they will
                            comprehend the significance of safety culture and
                            practices in the delivery of quality healthcare.
                            <br /><br />Participants will engage in a learning
                            experience that fosters critical thinking and
                            problem-solving abilities through interactive
                            lectures, case studies, and group discussions. In
                            addition, they will get the chance to network and
                            cooperate with healthcare experts from a variety of
                            disciplines and locations
                        </div>
                        <br /><br /><br /><br /><br /><br /><br /><br />
                    </div></div
            ></ng-container>
            <ng-container *ngIf="isChosen == '2'">
                <div class="col-lg-10"><app-sponsors></app-sponsors></div
            ></ng-container>
            <ng-container *ngIf="isChosen == '3'">
                <div class="col-lg-10"><app-chh-hr></app-chh-hr></div
            ></ng-container>
        </div>
        <div class="row">
            <div class="col-lg-6"></div>
        </div>
    </div>
</div>

<!--div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/FAS LOGO.jpg"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="col-lg-12">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="about-item">
                                    <div class="about-lef">
                                        <img
                                            src="../../../../../assets/img/mas-rise/FAS Brochures QR Code.jpg"
                                            alt="About"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="about-item">
                                    <div class="about-lef">
                                        <img
                                            src="../../../../../assets/img/mas-rise/FAS Instrumens QR Code.jpg"
                                            alt="About"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1wzL960WwymUyI0HGjCCCbq0EgZOXEjSq/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1k9k2fkdFdId7m812qrHi9-QRxcWyx7Zz/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-4">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Unilab-logo.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-8"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1AT2O3ARiWZ3XINqh7FfJbTnntojWMun0/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/17OduZCp-aWH2zLaJHE_IUPvLtpesMjRe/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1VQ56omieZsS46GLLQjGfx4NFxtmyx3pQ/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1BNK_e-po1X-oOgk8AGjPeqqL678VwPSr/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1wA2lgA0wlqSoEN7R3WHE2t3nXb_-VvVM/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1utWwO6Ythit-FCTGs3cjpCIuUinU8AQF/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Platinum Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/Zemed logo 2.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6"></div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1rzwnIQzAItvUPCsrYw5cGnoqe_MokQua/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1fed9Hral3R6NUEW6PjSVr8x2FLwUBKna/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1zWm_Mc_4XV-Aka0aChWwdz9cPKBTzRLl/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/16hOOyaN5ue_r9qdrbg_5qprMUVKZPQk-/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1i77rcoOZwDSiTf9W4rLZ5Dyhu3apbn64/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                        <div class="col-lg-4" style="height: 350px">
                            <iframe
                                src="https://drive.google.com/file/d/1z273NQ0oRihsIOmDDueoBZgQA8bXXi3x/preview"
                                width="100%"
                                height="70%"
                                allow="autoplay"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><h1>Silver Sponsor:</h1></div>
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-lef">
                        <img
                            src="../../../../../assets/img/mas-rise/download.png"
                            alt="About"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->
