<!--div class="page-title-area " [ngStyle]="{'background-image':' url(' + bacg+ ')'}">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Careers</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Careers</li>
                </ul>
            </div>
        </div>
    </div>
</div-->
<page-title pageTitle="Careers" [bacg]="bacg"></page-title>
<div class="video-wrap">
    <div class="container-fluid">
        <div class="row doctor-search-wrap1">
            <div
                class="col-6 col-lg-2"
                style="padding-bottom: 20px"
                *ngFor="let xxyyzz of uniqueNames"
            >
                <div
                    class="pointHand boldGreen"
                    [ngClass]="colorBG(xxyyzz)"
                    (click)="change(xxyyzz)"
                >
                    {{ xxyyzz }}
                </div>
            </div>
        </div>
    </div>
    <!--div class="container-fluid">
        <ul
            class="video-nav nav nav-pills"
            id="pills-tab"
            role="tablist"
            style="margin-top: 0 !important"
        >
            <li class="nav-item video-nav-item">
                <button
                    class="nav-link active"
                    id="pills-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="true"
                    (click)="change('Medical Ancillary Services')"
                >
                    Medical Ancillary
                </button>
            </li>

            <li class="nav-item video-nav-item">
                <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="true"
                    (click)="change('Human Resources')"
                >
                    Human Resources
                </button>
            </li>
            <li class="nav-item video-nav-item">
                <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="true"
                    (click)="change('Finance')"
                >
                    Finance
                </button>
            </li>
            <li class="nav-item video-nav-item">
                <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="true"
                    (click)="change('Internal Audit')"
                >
                    Internal Audit
                </button>
            </li>
            <li class="nav-item video-nav-item">
                <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="true"
                    (click)="change('Information Technology')"
                >
                    Information Technology
                </button>
            </li>
            <li class="nav-item video-nav-item">
                <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="true"
                    (click)="change('Nursing Services Office')"
                >
                    Nursing Services Office
                </button>
            </li>
            <li class="nav-item video-nav-item">
                <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="true"
                    (click)="change('General Services')"
                >
                    General Services
                </button>
            </li>
            <li class="nav-item video-nav-item">
                <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="true"
                    (click)="change('Operational Excellence')"
                >
                    Operational Excellence
                </button>
            </li>
        </ul>
    </div-->
</div>
<div class="blog-details-area pt-100" id="target-div">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="blog-details-item">
                    <div class="blog-details-category">
                        <ul>
                            <li class="pointHand" *ngFor="let x of careerList">
                                <a (click)="showJob(x)"
                                    ><span
                                        [ngClass]="
                                            color(x.attributes.link_title)
                                        "
                                        >{{ x.attributes.job_title }}</span
                                    ><br /><span style="font-size: 12px">{{
                                        x.attributes.short_Desc
                                    }}</span>
                                    <!--span class="title-span">{{x.attributes.type}}</span-->
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row" *ngIf="jobTitle1 == ''">
                    <div class="col-lg-8 offset-lg-2">
                        <!--img src="assets/img/In progress-rafiki.png" /-->
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <!--h3>Apply for this position:</h3-->

                            <div class="container" *ngIf="hsselected">
                                <b>Easy Apply</b> &nbsp;
                                <label for="fileInput"
                                    >Choose CV (PDF or Word):</label
                                >
                                <form
                                    (ngSubmit)="onSubmit(myForm)"
                                    style="padding-top: 10px"
                                    #myForm="ngForm"
                                >
                                    <input
                                        type="email"
                                        class="form-control"
                                        placeholder="Applicant Name"
                                        [(ngModel)]="applicantname"
                                        [ngModelOptions]="{ standalone: true }"
                                    />

                                    <div
                                        class="form-group"
                                        style="padding-top: 20px"
                                    >
                                        <input
                                            type="file"
                                            id="fileInput"
                                            (change)="onFileSelected($event)"
                                            accept=".pdf,.doc,.docx"
                                        />
                                    </div>

                                    <div style="padding-top: 5px">
                                        <ngx-recaptcha2
                                            siteKey="6LcZ5HcjAAAAAMGlbzzV8s9UYfujBytGMFybHwTI"
                                            (success)="handleSuccess($event)"
                                            useGlobalDomain="false"
                                        >
                                        </ngx-recaptcha2>
                                        <div>
                                            <button
                                                [disabled]="!isSuccess"
                                                type="submit"
                                                class="btn button-custom"
                                            >
                                                Upload
                                            </button>
                                            <spinnerV1
                                                *ngIf="isLoading"
                                            ></spinnerV1>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="padding-left: 20px" *ngIf="jobTitle1 != ''">
                    <!--h3>Apply for this position:</h3-->
                    <hr />
                    <b style="font-size: 18px !important">{{ jobTitle1 }}</b>
                    <p>{{ category }}<br />{{ type }}<br />{{ short_Desc }}</p>

                    <p [innerHTML]="qualifications"></p>
                    <br />
                    <p [innerHTML]="contact"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12"><br /></div>
        </div>
    </div>
</div>
