import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-careers-editor",
    templateUrl: "./careers-editor.component.html",
    styleUrls: ["./careers-editor.component.scss"],
})
export class CareersEditorComponent implements OnInit {
    jobTitle1;
    jobTitleLink;
    category;
    type;
    qualifications;
    contact;
    short_Desc;
    addCareers = {
        data: {
            job_title: "",
            link_title: "",
            category: "",
            type: "",
            qualification: "",
            contact: "",
            approver_id1: null,
            approver_id1_status: false,
            short_Desc: "",
        },
    };
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        //console.log("CAREERS");

        this.activatedRoute.data.subscribe((userData) => {
            //console.log(userData);
            //console.log(userData.userData[0].user.type);
        });
        this.bacg = "";
        if (localStorage.getItem("approver_id1") != undefined) {
            ////console.log("approver_id1");

            this.addCareers.data.approver_id1 = Number(
                localStorage.getItem("approver_id1")
            );
        }
        ////console.log(this.addCareers);
        this.getCareer();
        this.getBanner();
    }

    forJobTItleLink() {
        let inString = this.jobTitle1.toLowerCase();
        let outString;

        outString = inString.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
            ""
        );
        outString = inString.split(" ").join("-");
        this.jobTitleLink = outString;
        ////console.log("jobTitle", this.jobTitle1);
        ////console.log("jobTitleLink", this.jobTitleLink);
    }

    addCareer() {
        this.isUpdatemeCLicked = false;
        this.addCareers.data.job_title = this.jobTitle1;
        this.addCareers.data.link_title = this.jobTitleLink;
        this.addCareers.data.category = this.category;
        this.addCareers.data.type = this.type;
        this.addCareers.data.qualification = this.qualifications;
        this.addCareers.data.contact = this.contact;
        this.addCareers.data.short_Desc = this.short_Desc;

        ////console.log(this.addCareers);
        this.general.add("careers", this.addCareers).subscribe({
            complete: () => {
                this.jobTitle1 = "";
                this.jobTitleLink = "";
                this.category = "";
                this.type = "";
                this.qualifications = "";
                this.contact = "";
                this.getCareer();
            },
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data);
            },
        });
    }
    updateCareer() {
        this.addCareers.data.job_title = this.jobTitle1;
        this.addCareers.data.link_title = this.jobTitleLink;
        this.addCareers.data.category = this.category;
        this.addCareers.data.type = this.type;
        this.addCareers.data.qualification = this.qualifications;
        this.addCareers.data.contact = this.contact;
        this.addCareers.data.short_Desc = this.short_Desc;
        this.general
            .put("careers", this.addCareers, this.selectedId)
            .subscribe({
                complete: () => {
                    this.isUpdatemeCLicked = false;
                    this.jobTitle1 = "";
                    this.jobTitleLink = "";
                    this.category = "";
                    this.type = "";
                    this.qualifications = "";
                    this.contact = "";
                    this.short_Desc = "";
                    this.getCareer();
                },
                error: (error) => {
                    this.isUpdatemeCLicked = false;
                },
                next: (data: any) => {
                    ////console.log(data);
                },
            });
    }
    isUpdatemeCLicked: boolean = false;
    selectedId;
    updateMe(id, x) {
        //console.log(id);
        //console.log(x);

        this.jobTitle1 = "";
        this.jobTitleLink = "";
        this.category = "";
        this.type = "";
        this.qualifications = "";
        this.contact = "";
        this.short_Desc = "";
        this.selectedId = id;
        this.isUpdatemeCLicked = true;
        ////console.log(id);
        ////console.log(x);
        this.jobTitle1 = this.addCareers.data.job_title =
            x.attributes.job_title;
        this.jobTitleLink = this.addCareers.data.link_title =
            x.attributes.link_title;
        this.category = this.addCareers.data.category = x.attributes.category;
        this.type = this.addCareers.data.type = x.attributes.type;
        this.qualifications = this.addCareers.data.qualification =
            x.attributes.qualification;
        this.contact = this.addCareers.data.contact = x.attributes.contact;
        this.short_Desc = this.addCareers.data.short_Desc =
            x.attributes.short_Desc;
        ////console.log(this.addCareers);

        /**/
    }
    careerList;
    getCareer() {
        this.general
            .get("careers?pagination[pageSize]=99999&sort=job_title:ASC")
            .subscribe({
                complete: () => {},
                error: (error) => {},
                next: (data: any) => {
                    ////console.log(data.data);

                    this.careerList = data.data;

                    ////console.log(this.careerList);
                },
            });
    }
    /* START HERE */
    imglinkBig;
    img_hashBig;
    img_idBig;
    progress;
    progress1;
    message;
    message1;
    toUploadBigFile;
    isUploadStatus: boolean = false;
    formData;
    /*
    imgCount;
    bigImgID;
    img_extBig;
    imglinkSmall;
    img_hashSmall;
    img_idSmall;
    img_extSmall;
    
    imageDetails;
    */
    selectbigImg(files) {
        this.toUploadBigFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_big")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
        this.uploadFileBig(this.toUploadBigFile);
    }

    public uploadFileBig = (files) => {
        let fileToUpload = files.target.files[0];
        let fileName: string =
            "PageBanner-" + this.funcServ.generateYMDHMS(new Date());
        let fileExtension: string = fileToUpload.name
            .split("?")[0]
            .split(".")
            .pop();
        //////console.log(fileExtension);

        if (fileExtension) {
            this.formData = new FormData();
            this.formData.append(
                "files",
                fileToUpload,
                fileName + "." + fileExtension
            );
            this.http
                .post(environment.apiRouterUrl + "api/upload", this.formData, {
                    headers: {},
                    reportProgress: true,
                    observe: "events",
                })
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress)
                            this.progress = Math.round(
                                (100 * event.loaded) / event.total
                            );
                        else if (event.type === HttpEventType.Response) {
                            this.message = "Upload success.";
                        }
                        if (event.body != undefined) {
                            this.isUploadStatus = false;
                            this.imglinkBig = event.body[0].url;
                            this.img_idBig = event.body[0].id;
                            this.img_hashBig = event.body[0].hash;

                            this.addToBanner();
                        } else {
                            this.isUploadStatus = true;
                        }
                    },
                    (error) => {},
                    () => {}
                );
        }
    };
    addBanner = {
        data: {
            imgID: "",
            images: "",
            img_hash: "",
            owner: "",
        },
    };
    addToBanner() {
        //page - banners;
        this.addBanner.data.imgID = this.img_idBig;
        this.addBanner.data.images = this.imglinkBig;
        this.addBanner.data.img_hash = this.img_hashBig;
        this.addBanner.data.owner = "Careers";
        ////console.log(this.addBanner);

        this.general.add("page-banners", this.addBanner).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data);
            },
        });
    }
    bannerImage;
    bannerImageId;
    bannerDBId;
    bacg = "";
    link = "https://strapi.chonghua.com.ph/uploads/";
    getBanner() {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data.data);
                this.bannerImage = data.data;
                this.bannerImageId = this.bannerImage[0].attributes.imgID;
                this.bannerDBId = this.bannerImage[0].id;
                //console.log(this.bannerImageId);

                this.bannerImage.filter((x) => x.attributes.owner == "Careers");
                this.bacg =
                    "https://strapi.chonghua.com.ph" +
                    this.bannerImage[0].attributes.images;
            },
        });
    }
    removeCover() {
        //console.log(this.bannerImageId);
        //console.log(this.bannerDBId);
        this.general.delete("page-banners", this.bannerDBId).subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });
        this.general.delete("upload/files", this.bannerImageId).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });
    }
    delete(x) {
        //console.log(x);

        this.general.delete("careers", x).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });
    }

    colorBG(x) {
        if (x) {
            return "greenMe";
        } else {
            return "redMe";
        }
    }
}
