import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-page-header-editor",
    templateUrl: "./page-header-editor.component.html",
    styleUrls: ["./page-header-editor.component.scss"],
})
export class PageHeaderEditorComponent implements OnInit {
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone
    ) {}
    imglinkBig;
    img_hashBig;
    img_idBig;
    progress;
    progress1;
    message;
    message1;
    toUploadBigFile;
    isUploadStatus: boolean = false;
    formData;
    selectedID;
    ngOnInit(): void {
        this.getBanner();
    }
    selectbigImg(files) {
        this.toUploadBigFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_big")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
        this.uploadFileBig(this.toUploadBigFile);
    }
    owner;
    public uploadFileBig = (files) => {
        let fileToUpload = files.target.files[0];
        let fileName: string =
            "PageBanner-" + this.funcServ.generateYMDHMS(new Date());
        let fileExtension: string = fileToUpload.name
            .split("?")[0]
            .split(".")
            .pop();
        //////console.log(fileExtension);

        if (fileExtension) {
            this.formData = new FormData();
            this.formData.append(
                "files",
                fileToUpload,
                fileName + "." + fileExtension
            );
            this.http
                .post(environment.apiRouterUrl + "api/upload", this.formData, {
                    headers: {},
                    reportProgress: true,
                    observe: "events",
                })
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress)
                            this.progress = Math.round(
                                (100 * event.loaded) / event.total
                            );
                        else if (event.type === HttpEventType.Response) {
                            this.message = "Upload success.";
                        }
                        if (event.body != undefined) {
                            this.isUploadStatus = false;
                            this.imglinkBig = event.body[0].url;
                            this.img_idBig = event.body[0].id;
                            this.img_hashBig = event.body[0].hash;

                            if (this.isAdd) {
                                this.addToBanner();
                            } else {
                                this.updateBanner();
                            }
                        } else {
                            this.isUploadStatus = true;
                        }
                    },
                    (error) => {},
                    () => {}
                );
        }
    };
    checkDataStatus(y) {
        this.selectedID = y.id;
        //console.log(this.selectedID);

        let xyz = this.banners.filter((x) => x.id == y.id);
        this.funcServ.isEmptyObject(xyz);
        if (!this.funcServ.isEmptyObject(xyz)) {
            this.isAdd = false;
        }
        //console.log(this.isAdd);
    }
    addToBanner() {
        //console.log("Add Banner");

        //page - banners;
        this.addBanner.data.imgID = this.img_idBig;
        this.addBanner.data.images = this.imglinkBig;
        this.addBanner.data.img_hash = this.img_hashBig;
        this.addBanner.data.owner = this.owner;
        ////console.log(this.addBanner);

        this.general.add("page-banners", this.addBanner).subscribe({
            complete: () => {
                this.owner = "";
                this.ngOnInit();
            },
            error: (error) => {},
            next: (data: any) => {
                ////console.log(data);
            },
        });
    }
    updateBanner() {
        //console.log("Update Banner");

        //page - banners;
        this.addBanner.data.imgID = this.img_idBig;
        this.addBanner.data.images = this.imglinkBig;
        this.addBanner.data.img_hash = this.img_hashBig;
        this.addBanner.data.owner = this.owner;
        ////console.log(this.addBanner);

        this.general
            .put("page-banners", this.addBanner, this.selectedID)
            .subscribe({
                complete: () => {
                    this.owner = "";
                    this.ngOnInit();
                },
                error: (error) => {},
                next: (data: any) => {
                    ////console.log(data);
                },
            });
    }
    uploadBanner() {}
    addBanner = {
        data: {
            imgID: "",
            images: "",
            img_hash: "",
            owner: "",
        },
    };
    bannerImage;
    bannerImageId;
    bannerDBId;
    bacg = "";
    link = "https://strapi.chonghua.com.ph/uploads/";
    banners;
    getBanner() {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.banners = data.data;
            },
        });
    }
    removeCover() {
        //console.log(this.bannerImageId);
        //console.log(this.bannerDBId);

        /*this.general.delete("page-banners", this.bannerDBId).subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });*/
        this.general.delete("upload/files", this.bannerImageId).subscribe({
            complete: () => {
                this.bannerImageId = null;
                this.bannerDBId = null;
                this.bacg = null;
                this.ngOnInit();
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });
    }
    selected;
    isAdd: boolean = true;
    select(y) {
        this.checkDataStatus(y);

        this.selected = y.attributes.owner;
        this.bannerImageId = y.attributes.imgID;
        this.bannerDBId = y.id;
        this.bacg = "https://strapi.chonghua.com.ph" + y.attributes.images;
    }
}
