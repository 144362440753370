import { Component, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-insurance",
    templateUrl: "./insurance.component.html",
    styleUrls: ["./insurance.component.scss"],
})
export class InsuranceComponent implements OnInit {
    constructor(private general: GeneralService) {}

    ngOnInit(): void {
        this.getSlider();
    }
    right;
    left;
    promos;
    url = "https://strapi.chonghua.com.ph";
    getSlider() {
        this.general.get("insurance-catalogs").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                console.log(data);

                this.promos = data.data;
                this.promos.forEach((element) => {
                    this.right = element.attributes.right;
                    this.left = element.attributes.left;
                });
            },
        });
    }
}
