import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cme',
  templateUrl: './cme.component.html',
  styleUrls: ['./cme.component.scss']
})
export class CmeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
