import { Component, NgZone, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";

@Component({
    selector: "app-doctor-details",
    templateUrl: "./doctor-details.component.html",
    styleUrls: ["./doctor-details.component.scss"],
})
export class DoctorDetailsComponent implements OnInit {
    constructor(
        private genServ: GeneralService,
        public router: Router,
        private sanitizer: DomSanitizer,
        private ngZone: NgZone,
        private general: GeneralService,
        private activatedRoute: ActivatedRoute
    ) {}
    selectedDoctors;
    mdCode;
    selected;

    searchDoctor;
    ngOnInit(): void {
        this.mdCode = this.activatedRoute.snapshot.params.licno;
        let link = "gw/pub/doctorinfodetail?drCode=" + this.mdCode;

        this.genServ.getapi(link).subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.selectedDoctors = JSON.parse(
                    "[" + JSON.stringify(data) + "]"
                );
                console.log(this.selectedDoctors[0].doctorCode);
                this.getClinic(this.selectedDoctors[0].doctorCode);
            },
        });
        // this.selected = JSON.parse(localStorage.getItem("selectedDoctor"));
    }
    clinicList: any = null;
    getClinic(drCode) {
        //console.log(drCode);

        this.genServ.getClinic(drCode).subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.clinicList = data;
                //////console.log(data);
                this.getBanner(
                    this.selectedDoctors[0].specialization,
                    this.selectedDoctors[0].expertise,
                    this.selectedDoctors[0].deptCode
                );
            },
        });
    }
    bacg;
    bannerImage;
    getBanner(specialization, expertise, deptCode) {
        this.general.get("page-banners?pagination[pageSize]=99999").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                let asdasdasd;
                this.bannerImage = data.data;
                //console.log(deptCode);

                if (specialization == "ophthalmology") {
                    asdasdasd = this.bannerImage.filter(
                        (x) => x.attributes.owner == specialization
                    );
                } else {
                    asdasdasd = this.bannerImage.filter(
                        (x) => x.attributes.owner == deptCode
                    );
                }
                //console.log(asdasdasd);

                /*
                if (deptCode == "MED") {
                    asdasdasd = this.bannerImage.filter(
                        (x) => x.attributes.owner == deptCode
                    );
                    //console.log(asdasdasd);
                } else if (specialization == "Surgery") {
                    asdasdasd = this.bannerImage.filter(
                        (x) => x.attributes.owner == specialization
                    );
                } else {
                    asdasdasd = this.bannerImage.filter(
                        (x) => x.attributes.owner == expertise
                    );
                }*/
                ////////console.log(asdasdasd);

                this.ngZone.run(() => {
                    this.bacg =
                        "https://strapi.chonghua.com.ph" +
                        asdasdasd[0].attributes.images;
                    ////////console.log(this.bacg);
                });
            },
        });
    }
}
