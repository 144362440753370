<!--page-title [pageTitle]="title"></page-title-->
<!--div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Our Hospital Services</h2>
   
            </div>
        </div>
    </div>
</div-->
<section class="services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h2>Cebu</h2>
                <br />
                <b>Residency Traning Program</b>
                <ul>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-anesthesia-cebu"
                    >
                        Department of Anesthesiology
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-family-medicine-cebu-residency-training-program"
                    >
                        Department of Family and Community Medicine
                    </li>
                    <li
                        class="pointHand"
                        style="padding-top: 10px"
                        routerLink="/data/residency-internal-medicine-cebu"
                    >
                        Department of Internal Medicine
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-obstetrics-and-gynecology-cebu"
                    >
                        Department of Obstetrics and Gynecology
                    </li>
                    <li style="padding-top: 10px" class="pointHand">
                        Department of Orthopedics
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-patho-cebu"
                    >
                        Department of Pathology, Section of Anatomic Pathology
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-patho-cebu"
                    >
                        Department of Pathology, Section of Clinical Pathology
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-pedia-cebu"
                    >
                        Department of Pediatrics
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-radiology-cebu-residency-training-program"
                    >
                        Department of Radiology
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-surgery-cebu-residency-training-program"
                    >
                        Department of Surgery
                    </li>
                </ul>
                <b>Fellowship Traning Program</b>
                <ul>
                    <li>
                        Department of Internal Medicine
                        <ul>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-internal-medicine-cardiology-fellowship"
                            >
                                Section of Cardiology - Fellowship Training
                                Program in Cardiology
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-internal-medicine-echocardiography-fellowship"
                            >
                                Section of Cardiology - Fellowship Training
                                Program in Echocardiography
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-internal-medicine-endocrinology-fellowship"
                            >
                                Section of Endocrinology, Diabetes and
                                Metabolism - Fellowship Training Program in
                                Endocrinology
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-internal-medicine-gastroenterology-fellowship"
                            >
                                Section of Gastroenterology - Fellowship
                                Training Program in Gastroenterology
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-internal-medicine-medical-oncology"
                            >
                                Section of Medical Oncology - Fellowship
                                Training Program in Oncology
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-internal-medicine-nephrology-fellowship"
                            >
                                Section of Nephrology - Fellowship Training
                                Program in Nephrology
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-internal-medicine-neurology-fellowship"
                            >
                                Section of Neurology - Fellowship Training
                                Program in Neurology
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-internal-medicine-pulmonoary-fellowship"
                            >
                                Section of Pulmonary Medicine and Pulmonary
                                Critical Care - Fellowship Training Program in
                                Pulmonology (Adult)
                            </li>
                        </ul>
                    </li>
                    <li>
                        Department of Orthopedics
                        <ul>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-orthopaedics-adult-reconstruction-fellowship"
                            >
                                Section of Adult Hip/ Knee Reconstruction -
                                Fellowship Training Program in Adult Hip/ Knee
                                Reconstruction
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-orthopedics-spine-fellowship"
                            >
                                Section of Orthopedic Spine - Fellowship
                                Training Program in Orthopedic Spine
                            </li>
                        </ul>
                    </li>
                    <li>
                        Department of Pediatrics
                        <ul>
                            <li style="padding-top: 10px" class="pointHand">
                                Section of Pulmonology - Fellowship Training
                                Program in Pulmonology (Pediatrics)
                            </li>
                        </ul>
                    </li>
                    <li>
                        Department of Radiology
                        <ul>
                            <li style="padding-top: 10px" class="pointHand">
                                Fellowship Training Program in CT-MRI
                            </li>
                            <li
                                style="padding-top: 10px"
                                class="pointHand"
                                routerLink="/data/fellowship-radiology-interventional-radiology-fellowship"
                            >
                                Fellowship Training Program in Interventional
                                Radiology
                            </li>
                            <li style="padding-top: 10px" class="pointHand">
                                Fellowship Training Program in Ultrasonography
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <h2>Mandaue</h2>
                <br />
                <b>Residency Training Program</b>
                <ul>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-anesthesia-mandaue"
                    >
                        Department of Anesthesiology
                    </li>
                    <li style="padding-top: 10px" class="pointHand">
                        Department of Internal Medicine
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-obstetrics-and-gynecology-mandaue-residency-training-program"
                    >
                        Department of Obstetrics and Gynecology
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-pedia-mandaue"
                    >
                        Department of Pediatrics
                    </li>
                    <li
                        style="padding-top: 10px"
                        class="pointHand"
                        routerLink="/data/residency-surgery-mandaue-residency-training-program"
                    >
                        Department of Surgery
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
