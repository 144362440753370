<div class="container-fluid">
    <div class="row">
        <div class="col-12" *ngIf="!isDesktop"><br /><br /><br /><br /></div>

        <div class="col-12"><img [src]="bacg" /></div>
        <div class="col-12" *ngIf="!isDesktop"><br /></div>
    </div>
</div>
<!--
<div
    *ngIf="!isDefault"
    class="container-fluid page-title-area1 img-responsive"
    [ngStyle]="{ 'background-image': ' url(' + bacg + ')' }"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item1">
                <h2>{{ pageTitle }}</h2>
            </div>
        </div>
    </div>
</div>

<div
    *ngIf="isDefault"
    class="page-title-area2"
    [ngStyle]="{ 'background-image': ' url(' + bacg + ')' }"
>
    <br />
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item2">
                <h2>{{ pageTitle }}</h2>
            </div>
        </div>
    </div>
</div>
-->
