import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AdminRoutingModule } from "./admin-routing.module";
import { CKEditorModule } from "ckeditor4-angular";
import { AdmissionProcedureEditorComponent } from "./admission-procedure-editor/admission-procedure-editor.component";
import { SharedModule } from "../layouts/shared.module";
import { HomeComponent } from "./home/home.component";
import { PromosPackagesEditorComponent } from "./promos-packages-editor/promos-packages-editor.component";

import { AdminNavComponent } from "./admin-nav/admin-nav.component";
import { HomeEditorComponent } from "./home-editor/home-editor.component";
import { ResidencyEditorComponent } from "./residency-editor/residency-editor.component";
import { FellowshipEditorComponent } from "./fellowship-editor/fellowship-editor.component";
import { CareersEditorComponent } from "./careers-editor/careers-editor.component";
import { AdvanceDirectivesEditorComponent } from "./advance-directives-editor/advance-directives-editor.component";
import { DischargeProcedureEditorComponent } from "./discharge-procedure-editor/discharge-procedure-editor.component";
import { MedicalRecordEditorComponent } from "./medical-record-editor/medical-record-editor.component";
import { PatientRightsResponsibilitiesEditorComponent } from "./patient-rights-responsibilities-editor/patient-rights-responsibilities-editor.component";
import { PharmacyInfoEditorComponent } from "./pharmacy-info-editor/pharmacy-info-editor.component";
import { OthersEditorComponent } from "./others-editor/others-editor.component";
import { AboutchhceditorComponent } from "./aboutchhceditor/aboutchhceditor.component";
import { AboutchhmeditorComponent } from "./aboutchhmeditor/aboutchhmeditor.component";
import { PriceListEditorComponent } from "./price-list-editor/price-list-editor.component";
import { PageHeaderEditorComponent } from "./page-header-editor/page-header-editor.component";
import { AboutusEditorComponent } from "./aboutus-editor/aboutus-editor.component";
import { ApproverNavComponent } from "./approver-nav/approver-nav.component";
import { HomePageEditorComponent } from "./home-page-editor/home-page-editor.component";
import { ServicesEditorComponent } from "./services-editor/services-editor.component";
import { ResidencyEditorMComponent } from "./residency-editor-m/residency-editor-m.component";
import { FormsModule } from "@angular/forms";
import { CareersApproverComponent } from "../approver/careers-approver/careers-approver.component";
import { ResidencyApproverComponent } from "../approver/residency-approver/residency-approver.component";
import { ListComponent } from "../approver/list/list.component";
import { NewsEditorComponent } from "./news-editor/news-editor.component";
import { PatienteducationEditorComponent } from "./patienteducation-editor/patienteducation-editor.component";
import { EcueditorComponent } from "./ecueditor/ecueditor.component";
import { RoomcatalogEditorComponent } from './roomcatalog-editor/roomcatalog-editor.component';
import { HmoeditorComponent } from './hmoeditor/hmoeditor.component';

@NgModule({
    declarations: [
        AdmissionProcedureEditorComponent,
        HomeComponent,
        PromosPackagesEditorComponent,
        AdminNavComponent,
        HomeEditorComponent,
        ResidencyEditorComponent,
        FellowshipEditorComponent,
        CareersEditorComponent,
        AdvanceDirectivesEditorComponent,
        DischargeProcedureEditorComponent,
        MedicalRecordEditorComponent,
        PatientRightsResponsibilitiesEditorComponent,
        PharmacyInfoEditorComponent,
        OthersEditorComponent,
        AboutchhceditorComponent,
        AboutchhmeditorComponent,
        PriceListEditorComponent,
        PageHeaderEditorComponent,
        AboutusEditorComponent,
        ApproverNavComponent,
        HomePageEditorComponent,
        ServicesEditorComponent,
        ResidencyEditorMComponent,
        AdminNavComponent,
        CareersApproverComponent,
        ListComponent,
        ResidencyApproverComponent,
        NewsEditorComponent,
        PatienteducationEditorComponent,
        EcueditorComponent,
        RoomcatalogEditorComponent,
        HmoeditorComponent,
    ],
    imports: [
        CommonModule,
        AdminRoutingModule,
        CKEditorModule,
        SharedModule,
        FormsModule,
    ],
})
export class AdminModule {}
/*
import { AboutchhceditorComponent } from "./components/admin/aboutchhceditor/aboutchhceditor.component";
import { AboutchhmeditorComponent } from "./components/admin/aboutchhmeditor/aboutchhmeditor.component";
import { PriceListEditorComponent } from "./components/admin/price-list-editor/price-list-editor.component";
import { PageHeaderEditorComponent } from "./components/admin/page-header-editor/page-header-editor.component";
*/
