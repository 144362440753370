<div class="container">
    <div class="row">
        <div class="col-lg-12" *ngIf="!isDesktop" style="padding-top: 10px">
            <br /><br />
        </div>
        <div class="col-lg-12" *ngIf="!isDesktop"><br /></div>
    </div>
</div>
<div class="container-fluid">
    <carousel [showIndicators]="false">
        <slide *ngFor="let x of promos">
            <img
                src="{{ url + x.attributes.bigImages }}"
                style="display: block; width: 100%"
            />

            <div
                class="carousel-captionssss"
                *ngIf="x.attributes.title == 'Telemed'"
            >
                <a href="https://pxi.chonghua.com.ph/" target="_blank"
                    ><span
                        class="btn"
                        style="background-color: #275228; color: white"
                    >
                        Learn More
                    </span></a
                >
            </div>
            <div
                class="carousel-captionssss"
                *ngIf="x.attributes.title == 'EHU'"
            >
                <a href="/services-details/executive-health-unit"
                    ><span
                        class="btn"
                        style="background-color: #275228; color: white"
                    >
                        Learn More
                    </span></a
                >
            </div>
            <div
                class="carousel-captionssss"
                *ngIf="x.attributes.title == 'PXI'"
            >
                <a target="_BLANK" href="https://pxi.chonghua.com.ph/"
                    ><span
                        class="btn"
                        style="background-color: #275228; color: white"
                    >
                        Learn More
                    </span></a
                >
            </div>
        </slide>
    </carousel>
</div>
<div class="counter-area" *ngIf="isDesktop">
    <div class="container">
        <div class="row">
            <div class="col-12"><br /><br /><br /><br /><br /></div>
        </div>
    </div>
</div>
<div class="counter-area">
    <div class="container">
        <div class="row counter-bg">
            <div
                class="col-6 col-md-6 col-lg-6"
                *ngFor="let x1 of getIntroDataValue"
            >
                <div class="counter-item">
                    <i class="{{ x1.attributes.icon }}"></i>
                    <h3 class="counter">{{ x1.attributes.counter }}</h3>
                    <p>{{ x1.attributes.title }}</p>
                </div>
            </div>
            <!--div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">113</h3>
                    <p>Year Experience</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">25000</span>+</h3>
                    <p>Patients Annually</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">3,179</h3>
                    <p>Doctors, Nurses, & Support Staff</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">1010</h3>
                    <p>Patients Beds</p>
                </div>
            </div-->
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="{{ abouturl }}" alt="About" />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="hospital-item hospital-right">
                    <div class="section-title">
                        <h2>{{ addDoc.data.title }}</h2>
                    </div>

                    <p [innerHTML]="addDoc.data.aboutData"></p>
                    <!--ul>
                        <li><i class="icofont-check-circled"></i> Browse our Website</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                    </ul-->
                    <!--a class="hospital-btn" routerLink="/about">Know More</a-->

                    <div class="btn-group" dropdown>
                        <button
                            id="button-basic"
                            dropdownToggle
                            type="button"
                            class="btn hospital-btn1 dropdown-toggle"
                            aria-controls="dropdown-basic"
                        >
                            Learn more<span class="caret"></span>
                        </button>
                        <ul
                            id="dropdown-basic"
                            *dropdownMenu
                            class="dropdown-menu"
                            role="menu"
                            aria-labelledby="button-basic"
                        >
                            <li role="menuitem">
                                <a class="dropdown-item" href="/about"
                                    >About Us</a
                                >
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" href="/about_chhc"
                                    >Chong Hua Hospital</a
                                >
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" href="/about_chhm"
                                    >Chong Hua Hospital Mandaue</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Hospital Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div>
                    <p>
                        Here at Chong Hua Hospital, we offer the most
                        comprehensive health care services at affordable costs.
                    </p>
                </div>
            </div>
            <div
                class="col-6 col-lg-3 wow animate__animated animate__fadeInUp"
                *ngFor="let reels of ourServices"
                [attr.data-wow-delay]="reels.delay"
            >
                <a [routerLink]="reels.link">
                    <div class="doctor-item chhgreen pointHand">
                        <div class="doctor-top">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 offset-md-3">
                                        <img
                                            src="assets/icon/{{
                                                reels.photoName
                                            }}"
                                            alt="Doctor"
                                            class="img-fluid"
                                            style="padding-top: 20px"
                                        />
                                    </div>
                                </div>
                            </div>

                            <!--a routerLink="/appointment">Get Appointment</a-->
                        </div>

                        <div class="doctor-bottom">
                            <h3 class="chhgreen customH3">{{ reels.name }}</h3>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<section class="expertise-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expertise</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12">
                <div>
                    <p>
                        World class, state-of-the-art facilities, paired with an
                        efficient and compassionate medical staff dedicated to
                        bringing you a comprehensive, cost-efficient patient
                        experience designed for your specific needs.
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="row">
                        <div
                            class="col-6 col-lg-6 wow animate__animated animate__fadeInUp"
                            data-wow-delay=".3s"
                        >
                            <a>
                                <div class="expertise-inner">
                                    <i class="icofont-doctor-alt"></i>
                                    <h3>Certified Doctors</h3>
                                    <!--p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit.
                                    </p-->
                                </div>
                            </a>
                        </div>

                        <div
                            class="col-6 col-lg-6 wow animate__animated animate__fadeInUp"
                            data-wow-delay=".5s"
                        >
                            <a>
                                <div class="expertise-inner">
                                    <i class="icofont-stretcher"></i>
                                    <h3>Emergency and Critical Care</h3>
                                    <!--p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit.
                                    </p-->
                                </div>
                            </a>
                        </div>

                        <div
                            class="col-6 col-lg-6 wow animate__animated animate__fadeInUp"
                            data-wow-delay=".3s"
                        >
                            <a>
                                <div class="expertise-inner">
                                    <i class="icofont-hospital"></i>
                                    <h3>State-of-the-Art Facilities</h3>
                                    <!--p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit.
                                    </p-->
                                </div>
                            </a>
                        </div>

                        <div
                            class="col-6 col-lg-6 wow animate__animated animate__fadeInUp"
                            data-wow-delay=".5s"
                        >
                            <a>
                                <div class="expertise-inner">
                                    <i class="icofont-certificate-alt-1"></i>
                                    <h3>Training Programs</h3>
                                    <!--p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit.
                                    </p-->
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="expertise-right">
                        <img src="assets/img/withid.png" alt=" Expertise" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="doctors-area ptb-100 pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Doctors</h2>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div>
                    <p>
                        What makes Chong Hua the best hospital for you is our
                        team of renowned experts across different specialties
                        who work together to give you the care you deserve. It
                        is home to some of the best specialists in the country.
                    </p>
                </div>
            </div>
            <div
                class="col-6 col-lg-2 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
                *ngFor="let x of doctorsList"
            >
                <div
                    class="doctor-item chhgreen pointHand"
                    (click)="redirect(x)"
                >
                    <div class="doctor-top">
                        <img
                            src="assets/icon/{{ x.deptCode }}.png"
                            alt="Doctor"
                            class="img-fluid"
                        />
                        <dept-helper [reCeiver]="x.deptCode"></dept-helper>
                        <br />
                        <!--subspec-helper
                            *ngIf="
                                x.deptCode == 'MED' ||
                                x.deptCode == 'PED' ||
                                x.deptCode == 'SURA' ||
                                x.deptCode == 'SURP' ||
                                x.deptCode == 'ORTH'
                            "
                            [subspecialty]="x.expertise"
                        ></subspec-helper-->
                        <!--a routerLink="/appointment">Get Appointment</a-->
                    </div>

                    <div class="doctor-bottom paddingDoctor">
                        <b class="chhgreen" *ngIf="x.doctorsName != null"
                            ><a>{{ x.doctorsName }}</a></b
                        >
                        <b class="chhgreen" *ngIf="x.doctorsName == null"
                            ><a>{{ x.firstName }} {{ x.lastName }}, M.D.</a></b
                        >
                        <!--dept-helper [reCeiver]="x.deptCode"></dept-helper-->
                    </div>
                </div>
            </div>
        </div>

        <div class="doctor-btn">
            <a routerLink="/doctors">See All</a>
        </div>
    </div>
</section>

<section class="blog-area pb-70">
    <div class="container">
        <div class="section-title"></div>
    </div>
</section>
