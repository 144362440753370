import { Component, Input, OnInit } from "@angular/core";
@Component({
    selector: "subspec-helper",
    templateUrl: "./subspec-helper.component.html",
    styleUrls: ["./subspec-helper.component.scss"],
})
export class SubspecHelperComponent implements OnInit {
    @Input() subspecialty: string;
    @Input() smallFont: boolean = true;
    constructor() {}

    ngOnInit(): void {
        if (this.subspecialty == "Pediatrics") {
            this.subspecialty = "";
        }
        if (this.subspecialty == "N/A") {
            this.subspecialty = "";
        }
        if (this.subspecialty != null) {
            if (
                this.subspecialty.includes("/") ||
                this.subspecialty.includes("\n") ||
                this.subspecialty.includes("\r")
            ) {
                this.subspecialty = this.subspecialty.replace(/\n/g, "<br />");
            }

            console.log(this.subspecialty);
        }
    }
}
