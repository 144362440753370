<div class="page-title-area page-title-four ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Patient Rights and Responsibilities</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape">
        </div>

        <div class="row m-0 align-items-center">


            <div class="col-lg-6 offset-lg-3">
                <div>
                    <br />
                    <div class="" [innerHtml]="description"></div>
                </div>

                <!--div>
                    <br />
     
                    CHONG HUA HOSPITAL aims to provide you with safe and quality care. As a patient in this hospital, it
                    is important for
                    you to know that you have RIGHTS AND RESPONSIBILITIES that will enable you to participate in
                    receiving quality
                    healthcare.<br /><br />
                    <h4>Communication and Information</h4>
                    <br />
                    <p><b>Your Rights</b></p>
                    <ul>
                        <li>To be informed of the names of the doctors, nurses and health team members directly involved
                            in your
                            care.</li>
                        <li>To request additional information on the doctor/s responsible for your care.</li>
                        <li>To be informed of the diagnosis, planned course of treatment, procedures & expected results.
                        </li>
                        <li>To be informed of the risks, benefits and treatment/diagnostic alternatives, the
                            consequences should
                            one refuse.</li>
                        <li>To be informed of pain and pain relief measures.</li>
                        <li>To be informed of care options when the hospital care is not indicated.</li>
                        <li>To be informed of doctor’s fee before any medical care</li>

                    </ul>
                    <br />
                    <p><b>Your Responsibilities</b></p>
                    <ul>
                        <li>To provide complete and accurate patient information including but not limited to personal,
                            social
                            and medical
                            information.</li>
                        <li>To provide a copy of their advance directive if intended to apply during admission.</li>
                    </ul><br />
                    <h4>Access and Support</h4>
                    <br />
                    <p><b>Your Rights</b></p>
                    <ul>
                        <li>To receive respectful and compassionate care in a safe setting regardless of age, race.
                            religion, national origin,
                            gender/sexual orientation, physical or mental disability.</li>
                        <li>To be provided with attention when you request for help, with the understanding that other
                            patients may have more urgent
                            needs.</li>
                        <li>To be provided with attention from a Patient Service representative in resolving complaints.
                        </li>
                        <li>To be provided with assistance on ethical issues by contacting an Ethics Committee member.
                        </li>
                        <li>To be provided with a copy of all your medical records according to the CHH Medical Records
                            policy.</li>
                        <li>To request information and a summarized list of your hospital charges.</li>

                    </ul>
                    <br />
                    <p><b>Your Responsibilities</b></p>
                    <ul>
                        <li>To abide by all hospital rules and regulations.</li>
                    </ul><br />
                    <h4>Respect and Dignity</h4>
                    <br />
                    <p><b>Your Rights</b></p>
                    <ul>
                        <li>To privacy and confidentiality of all records except as otherwise provided by law.</li>
                        <li>To privacy, to the extent consistent with providing adequate medical care.</li>
                        <li>To be free from the use of seclusion or restraints in any form unless clinically required.
                        </li>

                    </ul>
                    <br />
                    <p><b>Your Responsibilities</b></p>
                    <ul>
                        <li>To treat hospital staff, other patients and visitors with courtesy and respect.</li>
                        <li>To pay hospital charges and physicians’ fees in a timely manner.</li>
                    </ul><br />
                    <h4>Empowerment and Choice</h4>
                    <br />
                    <p><b>Your Rights</b></p>
                    <ul>
                        <li>To decide about your plan of care before and after treatment.</li>
                        <li>To seek a second opinion without fear of compromise to one’s care within or outside the
                            organization.</li>
                        <li>To decide about advance directives.
                        </li>
                        <li>To decide about tissue/organ donation.</li>
                        <li>To decide to refuse treatment to the extent permitted by law.</li>
                        <li>To discharge from the hospital against the advice of your doctor.</li>
                        <li>To decline to participate or to withdraw from a research study without compromising one’s
                            hospital care.</li>
                        <li>To verify the accuracy of your hospital charges and physicians’ professional fees.</li>

                    </ul>
                    <br />
                    <p><b>Your Responsibilities</b></p>
                    <ul>
                        <li>To ask your doctors about your diagnosis or treatment.</li>
                        <li>To ask your doctors about pain management.</li>
                    </ul><br />
                    <h4>Safety and Security</h4>
                    <br />
                    <p><b>Your Rights</b></p>
                    <ul>
                        <li>To be safe and secure during one’s medical admission in the hospital.</li>

                    </ul>
                    <br />
                    <p><b>Your Responsibilities</b></p>
                    <ul>
                        <li>To leave all your valuables at home.</li>
                        <li>To bring only necessary items for your care in this facility.</li>
                    </ul>

                </div-->
            </div>

        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <!--div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                        <p>World class, state-of-the-art facilities, paired with an efficient and compassionate medical
                            staff dedicated to bringing
                            you a comprehensive, cost-efficient patient experience designed for your specific needs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h4>Emergency Call</h4>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</section>