<div class="page-title-area page-title-four ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Discharge Procedure</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape">
        </div>

        <div class="row m-0 align-items-center">


            <div class="col-lg-6 offset-lg-3">
                <div>
                    <br />
                    <div [innerHtml]="description"></div>
                    <!--h4>Discharge Procedure
                        The discharge process may be initiated early during your hospitalization:</h4>
                    <p>
                        <br />
                        <b>Step 1</b><br />
                        Go to the Nurses' Station, verify that your doctor has given the "May go home" order and obtain
                        the following, as
                        applicable.
                    </p>
                    <ol>
                        <li>Discharge Instructions</li>
                        <li>Medical Records (Certified True Copy)</li>
                        <li>PhilHealth Forms</li>
                    </ol>

                    <p>
                        <b>Step 2</b><br />
                        Return TV and Digibox remote controls to the Billing Section.<br />

                        <b>Step 3</b><br />
                        For PhilHealth members, proceed to the Billing Section together with completed and fully
                        accomplished forms.
                    </p>
                    <ol>
                        <li>PHIC Form 1 accompanied by the employer</li>
                        <li>PHIC Form 2 signed by the attending physicians</li>
                        <li>PHIC Form 3 (duly accomplished)</li>
                        <li>PHIC Form 4 (for maternity cases only)</li>
                    </ol>

                    <p>
                        For incomplete PhilHealth requirements, you have to settle your account at the Billing Section
                        in full. You can file
                        your PhilHealth requirements after the discharge for direct refund from PhilHealth at a later
                        date.
                        <br /><br />

                    </p>
                    <h4>For non-PhilHealth members, proceed to the Billing Section (Ground Floor, Bldg. A) for
                        computation of the final hospital
                        bill.</h4>
                    <ol>
                        <li>Senior Citizen's discount, if applicable, may be granted upon full payment and presentation
                            of
                            an identification card
                            or document that establishes that patient is a citizen of the Republic of the Philippines
                            and is
                            at least sixty (60)
                            years old.</li>
                        <li>For personal accounts, please pay the hospital bill and professional fees at the cashier.
                            Step 4</li>
                    </ol>
                    <p>
                        A gate pass shall be given to you by the Billing Officer or cashier upon your discharge.

                        Present this gatepass (first copy) to the nurse-on-duty upon check-out and surrender to the
                        security guard (second copy)
                        upon leaving the hospital.
                        Important Discharge Reminders
                    </p>
                    <ol>
                        <li>Discharge cut - off time is at 12:00 noon. A fractional amount shall be charged to the
                            account</li>
                        <li>if discharged after the
                            cut-off time</li>
                        <li>Please make arrangements with the Billing Section if you want to check out after the cut-off
                            time.</li>
                        <li>All major credit card payments are accepted.</li>
                        <li>For an updated Statement of Account, you can request for it from the Billing Section.</li>
                        <li>ATM Terminals are available at the Ground floor, Building C.</li>
                    </ol-->


                </div>
            </div>

        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">

        </div>
    </div>
</section>