<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Careers Approver</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Careers</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<app-admin-nav></app-admin-nav>
<hr />
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <span
                    class="btn btn-warning"
                    (click)="chooseType(false)"
                    [ngClass]="{
                        my_class: approvedUnapproved === false
                    }"
                    >UnApproved</span
                >
            </div>
            <div class="col-lg-6">
                <span
                    class="btn btn-success"
                    (click)="chooseType(true)"
                    [ngClass]="{
                        my_class: approvedUnapproved === true
                    }"
                    >Approved</span
                >
            </div>
            <div class="col-lg-12"><br /></div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4">
                <div class="container">
                    <div class="">
                        <div
                            class="row service-item-custom pointHand"
                            *ngFor="let x of careerList"
                        >
                            <div
                                class="col-lg-9"
                                style="top: 0"
                                (click)="updateMe(x.id, x)"
                            >
                                <span
                                    [ngClass]="
                                        colorBG(
                                            x.attributes.approver_id1_status
                                        )
                                    "
                                    >{{ x.attributes.job_title }}</span
                                >
                            </div>
                            <div class="col-lg-3">
                                <span
                                    *ngIf="!approvedUnapproved"
                                    class="btn btn-warning"
                                    (click)="approve(x)"
                                    >Approve</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="service-item-custom pointHand">
                    Job Title :
                    {{ jobTitle1 }}
                    <span *ngIf="short_Desc != null"
                        >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        {{ short_Desc }}</span
                    ><br />
                    Department : {{ category }}<br />
                    Type : {{ type }}<br />

                    <p [innerHTML]="qualifications"></p>
                    <b>Application Requirements:</b>
                    <p [innerHTML]="contact"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-kg-12"><br /><br /><br /></div>
        </div>
    </div>
</div>
