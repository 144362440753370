<app-admin-nav></app-admin-nav>

<div class="pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3">
                <div
                    *ngFor="let y of jsonData"
                    [ngClass]="test(y.attributes.approver_id1_status, y.id)"
                >
                    <div class="pointHand" (click)="choose(y)">
                        <span *ngIf="y.id == id">-></span>
                        {{ y.attributes._createdAt }} &nbsp;&nbsp;&nbsp;<!--span
                            *ngIf="!y.attributes.approver_id1_status"
                            (click)="removeList(y)"
                            >Delete</span
                    --><span
                            *ngIf="y.attributes.approver_id1_status"
                            (click)="removeList(y)"
                            >(approved)</span
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div [innerHtml]="description"></div>
            </div>
            <div class="col-lg-1">
                <button
                    type="button"
                    class="btn btn-warning"
                    *ngIf="!isSelectedApproved"
                    (click)="approve()"
                >
                    Approve
                </button>
            </div>
        </div>
    </div>
</div>
