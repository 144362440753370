import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-page-title1",
    templateUrl: "./page-title1.component.html",
    styleUrls: ["./page-title1.component.scss"],
})
export class PageTitle1Component implements OnInit {
    @Input() pageTitle: string;
    constructor() {}

    ngOnInit(): void {}
}
