import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-about-chhm",
    templateUrl: "./about-chhm.component.html",
    styleUrls: ["./about-chhm.component.scss"],
})
export class AboutChhmComponent implements OnInit {
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone,
        private sanitizer: DomSanitizer
    ) {}
    id;
    promos;
    title;
    title1;
    content;
    description;
    imglinkBig;
    img_idBig;
    url = "https://strapi.chonghua.com.ph";
    ngOnInit(): void {
        this.getSlider();
    }
    getSlider() {
        this.general.get("about-chhms").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);

                this.ngZone.run(() => {
                    this.id = data.data[0].id;
                    this.promos = data.data;
                    //console.log(data.data[0].attributes);
                    this.title = this.sanitizer.bypassSecurityTrustHtml(
                        data.data[0].attributes.title
                    );
                    this.title1 = data.data[0].attributes.title;

                    this.content = this.sanitizer.bypassSecurityTrustHtml(
                        data.data[0].attributes.content
                    );
                    this.description = this.sanitizer.bypassSecurityTrustHtml(
                        data.data[0].attributes.description
                    );
                    this.imglinkBig = data.data[0].attributes.images;
                    this.img_idBig = data.data[0].attributes.imageID;

                    //console.log(this.imglinkBig);
                });
            },
        });
    }
}
