<app-page-title1 pageTitle="Page Banner Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div _ngcontent-bjb-c16="" class="blog-item">
                <div _ngcontent-bjb-c16="" class="blog-bottom">
                    <div class="col-lg-4">
                        Owner:<input
                            type="text"
                            class="form-control"
                            placeholder="Owner"
                            [(ngModel)]="owner"
                            [ngModelOptions]="{ standalone: true }"
                        /><br />

                        Page Cover:
                        <input
                            type="file"
                            name="files"
                            accept="image/*"
                            #file
                            placeholder="Choose file"
                            (change)="selectbigImg($event)"
                            style="display: none"
                        />
                        <button
                            type="button"
                            class="btn btn-warning"
                            (click)="file.click()"
                        >
                            Upload
                        </button>
                        <span class="upload" *ngIf="progress > 0">
                            {{ progress }}%
                        </span>
                        <span class="upload" *ngIf="message">
                            {{ message }}
                        </span>
                    </div>

                    <div class="col-lg-12"><br /></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-10 col-sm-10">
                <img src="" alt="Big Image" id="img_big" [src]="bacg" />
                <div class="col-lg-4" *ngIf="bacg != ''">
                    <button
                        type="button"
                        class="btn btn-danger"
                        (click)="removeCover()"
                    >
                        Remove
                    </button>
                </div>
            </div>
            <div class="col-lg-2 col-sm-2">
                <b>List of banners uploaded</b>
                <div
                    *ngFor="let y of banners"
                    (click)="select(y)"
                    [ngClass]="{ my_class: selected === y.attributes.owner }"
                    class="pointHand"
                >
                    {{ y.attributes.owner }}
                </div>
            </div>
        </div>

        <div class="row"></div>

        <section class="blog-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div
                        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
                        data-wow-delay=".3s"
                    >
                        <br />
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
