<!--page-title [pageTitle]="title"></page-title-->
<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>{{ title1 }}</h2>
                <!--ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul-->
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img [src]="url + imglinkBig" alt="About" />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <h2 [innerHTML]="title1"></h2>
                    <p [innerHTML]="description"></p>

                    <!--ul>
                        <li><i class="icofont-check-circled"></i> Browse Our Website</li>
                        <li><i class="icofont-check-circled"></i> Choose Service</li>
                        <li><i class="icofont-check-circled"></i> Send Messege</li>
                    </ul>
                    <a routerLink="/about">Know More</a-->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-right" [innerHTML]="content"></div>
                <!--div class="about-item about-right">

                    <h2>Clinical Departments</h2>

                    <ul>

                        <li><i class="icofont-check-circled"></i> Anesthesiology</li>
                        <li><i class="icofont-check-circled"></i> Emergency and Outpatient Services</li>
                        <li><i class="icofont-check-circled"></i> Family Medicine</li>
                        <li><i class="icofont-check-circled"></i> Internal Medicine</li>
                        <li><i class="icofont-check-circled"></i> Obstetrics and Gynecology</li>
                        <li><i class="icofont-check-circled"></i> Opthalmology</li>
                        <li><i class="icofont-check-circled"></i> Orthopaedics</li>
                        <li><i class="icofont-check-circled"></i> Pathology</li>
                        <li><i class="icofont-check-circled"></i> Pediatrics </li>
                        <li><i class="icofont-check-circled"></i> Radiology</li>
                        <li><i class="icofont-check-circled"></i> Surgery</li>



                    </ul>
                    <h2>Institutes/Center</h2>

                    <ul>

                        <li><i class="icofont-check-circled"></i> Eye Institute</li>
                        <li><i class="icofont-check-circled"></i> Heart Institute</li>

                    </ul>

                </div-->
            </div>
            <div class="col-lg-7">
                <div class="container-fluid">
                    <div class="row location-wrap">
                        <div class="col-sm-6 col-lg-4">
                            <div class="location-item">
                                <a
                                    href="https://www.google.com/maps/place/Chong+Hua+Hospital/@10.3101613,123.8850079,16.04z/data=!4m10!1m2!2m1!1schong+hua+hospital!3m6!1s0x33a9994e8260ae77:0xbb0002f4fcebb8e7!8m2!3d10.3101043!4d123.8911886!15sChJjaG9uZyBodWEgaG9zcGl0YWySAQhob3NwaXRhbOABAA!16s%2Fg%2F1tm_zrsr"
                                    target="_BLANK"
                                >
                                    <i class="icofont-location-pin"></i>
                                    <h3>Location</h3>
                                    <ul>
                                        <li>
                                            Don Mariano Cui Street, Corner J.
                                            Llorente Street, Brgy. Capitol Site,
                                            Cebu City, Cebu, Philippines 6000
                                        </li>
                                    </ul>
                                </a>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <div class="location-item">
                                <a href="mailto:info@chonghua.com.ph">
                                    <i class="icofont-ui-message"></i>
                                    <h3>Email</h3>
                                    <ul>
                                        <li>info@chonghua.com.ph</li>
                                    </ul>
                                </a>
                            </div>
                        </div>

                        <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                            <div class="location-item">
                                <a href="tel:+63 (32) 255-8000">
                                    <i class="icofont-ui-call"></i>
                                    <h3>Phone</h3>
                                    <ul>
                                        <li>+63 (32) 255-8000</li>
                                    </ul>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contact-map-area">
                    <div id="map">
                        <div class="mapouter">
                            <div class="gmap_canvas">
                                <iframe
                                    src="https://maps.google.com/maps?width=600&amp;height=500&amp;hl=en&amp;q=10.310021870836033, 123.89117835459382&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                    width="400"
                                    height="600"
                                    style="border: 0"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                            <style>
                                .mapouter {
                                    position: relative;
                                    text-align: right;
                                    width: 100%;
                                    height: 400px;
                                }

                                .gmap_canvas {
                                    overflow: hidden;
                                    background: none !important;
                                    width: 100%;
                                    height: 400px;
                                }

                                .gmap_iframe {
                                    height: 400px !important;
                                }
                            </style>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--div class="counter-area counter-bg counter-area-four">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">850</h3>
                    <p>Patients Beds</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">25000</span>+</h3>
                    <p>Happy Patients</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">750</h3>
                    <p>Doctors & Nurse</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">18</h3>
                    <p>Year Experience</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Child Care</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>More Stuff</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Enough Lab</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>24 Hour Doctor</h3>
                                    <p>Lorem ipsum dolor sit amet, is consectetur adipiscing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Services</span>
            <h2>Our Hospital Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="testimonial-wrap">
            <h2>What our patient say</h2>

            <div class="testimonial-slider owl-theme owl-carousel">
                <div class="testimonial-item">
                    <img src="assets/img/home-three/7.png" alt="Testimonial">
                    <h3>John Mic</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate
                        dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia
                        optio, quas quis rem non reprehenderit.</p>
                </div>

                <div class="testimonial-item">
                    <img src="assets/img/home-three/8.png" alt="Testimonial">
                    <h3>Adam Smith</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate
                        dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia
                        optio, quas quis rem non reprehenderit.</p>
                </div>

                <div class="testimonial-item">
                    <img src="assets/img/home-three/9.png" alt="Testimonial">
                    <h3>Jac Jacson</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate
                        dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia
                        optio, quas quis rem non reprehenderit.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Blogs</span>
            <h2>Our latest blogs</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a>
                        </h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section-->
