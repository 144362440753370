import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-home-page-editor",
    templateUrl: "./home-page-editor.component.html",
    styleUrls: ["./home-page-editor.component.scss"],
})
export class HomePageEditorComponent implements OnInit {
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService
    ) {}

    ngOnInit(): void {
        this.introData = {
            data: {
                icon: "",
                counter: "",
                title: "",
                sort: "",
            },
        };
        this.getIntroData();
    }
    introData = {
        data: {
            icon: "",
            counter: "",
            title: "",
            sort: "",
        },
    };
    getIntroDataValue = [];
    getIntroData() {
        let getIntroDataValue;
        this.general.get("home-intros?sort=sort:ASC").subscribe({
            complete: () => {
                this.getIntroDataValue = getIntroDataValue;
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);

                getIntroDataValue = data.data;
            },
        });
    }
    saveIntroData() {
        //console.log(this.introData);
        this.general.add("home-intros", this.introData).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });
    }
    deleteIntro(x) {
        //console.log();
        this.general.delete("home-intros", x.id).subscribe({
            complete: () => {
                this.ngOnInit();
            },
            error: (error) => {
                //console.log(error);
            },
            next: (data: any) => {
                //console.log(data);
            },
        });
    }
}
