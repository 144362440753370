import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, NgZone, OnInit } from "@angular/core";
import { GeneralService } from "src/app/services/general.service";
import { PromosService } from "src/app/services/promos.service";
import { FunctionsService } from "src/app/shared/functions/functions.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-aboutchhmeditor",
    templateUrl: "./aboutchhmeditor.component.html",
    styleUrls: ["./aboutchhmeditor.component.scss"],
})
export class AboutchhmeditorComponent implements OnInit {
    title;
    content;
    description;
    progress;
    message;
    toUploadBigFile;
    formData;
    isUploadStatus;
    imglinkBig = "";
    img_idBig;
    img_hashBig;
    addDoc = {
        data: {
            title: "",
            description: "",
            content: "",
            images: "",
            imageHash: "",
            imageID: "",
        },
    };
    constructor(
        private funcServ: FunctionsService,
        private promoService: PromosService,
        private http: HttpClient,
        private general: GeneralService,
        private ngZone: NgZone
    ) {}

    ngOnInit(): void {
        this.getSlider();
    }
    promos;
    getSlider() {
        this.general.get("about-chhms").subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);

                this.ngZone.run(() => {
                    this.id = data.data[0].id;
                    this.promos = data.data;
                    //console.log(data.data[0].attributes);
                    this.title = data.data[0].attributes.title;
                    this.content = data.data[0].attributes.content;
                    this.description = data.data[0].attributes.description;
                    this.imglinkBig = data.data[0].attributes.images;
                    this.img_idBig = data.data[0].attributes.imageID;
                });
            },
        });
    }
    selectbigImg(files) {
        this.toUploadBigFile = files;
        var reader = new FileReader();
        reader.onload = function (e: any) {
            document
                .querySelector("#img_big")
                .setAttribute("src", e.target.result);
        };
        reader.readAsDataURL(files.target.files[0]);
    }
    startUpload() {
        this.uploadFileBig(this.toUploadBigFile);
    }
    public uploadFileBig = (files) => {
        if (files == null) {
            this.addFile();
        } else {
            let fileToUpload = files.target.files[0];
            let fileName: string =
                "AboutChhm-" + this.funcServ.generateYMDHMS(new Date());
            let fileExtension: string = fileToUpload.name
                .split("?")[0]
                .split(".")
                .pop();
            ////console.log(fileExtension);

            if (fileExtension) {
                this.formData = new FormData();
                this.formData.append(
                    "files",
                    fileToUpload,
                    fileName + "." + fileExtension
                );
                this.http
                    .post(
                        environment.apiRouterUrl + "api/upload",
                        this.formData,
                        {
                            headers: {},
                            reportProgress: true,
                            observe: "events",
                        }
                    )
                    .subscribe(
                        (event: any) => {
                            if (event.type === HttpEventType.UploadProgress)
                                this.progress = Math.round(
                                    (100 * event.loaded) / event.total
                                );
                            else if (event.type === HttpEventType.Response) {
                                this.message = "Upload success.";
                            }
                            if (event.body != undefined) {
                                ////console.log("EVENT RESPONSE");

                                //console.log(event.body);
                                this.isUploadStatus = false;
                                this.imglinkBig = event.body[0].url;
                                this.img_idBig = event.body[0].id;
                                this.img_hashBig = event.body[0].hash;

                                this.addFile();
                            } else {
                                //////console.log("error");
                                this.isUploadStatus = true;
                            }
                        },
                        (error) => {
                            //////console.log(error);
                        },
                        () => {}
                    );
            }
        }
    };
    id;
    addFile() {
        this.addDoc.data.title = this.title;
        this.addDoc.data.content = this.content;
        this.addDoc.data.description = this.description;
        this.addDoc.data.imageHash = this.img_hashBig;
        this.addDoc.data.imageID = this.img_idBig;
        this.addDoc.data.images = this.imglinkBig;
        //console.log(this.addDoc);
        this.general.put("about-chhms", this.addDoc, this.id).subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                //console.log(data);
            },
        });
        /*this.general.add("about-chhcs", this.addDoc).subscribe({
            complete: () => {
                this.toUploadBigFile = "";
            },
            error: (error) => {
                //console.log(error);
            },
            next: (data: any) => {
                //console.log(data);

                this.title = "";
                this.content = "";
                this.description = "";
            },
        });*/
    }
    url = "https://strapi.chonghua.com.ph";
    remove() {
        //console.log(this.id);
        //console.log(this.img_idBig);
        this.general.delete("upload/files", this.img_idBig).subscribe({
            complete: () => {},
            error: (error) => {},
            next: (data: any) => {
                this.addDoc.data.title = this.title;
                this.addDoc.data.content = this.content;
                this.addDoc.data.description = this.description;
                this.addDoc.data.imageHash = "";
                //this.addDoc.data.imageID = "";
                this.addDoc.data.images = "";
                //console.log(this.addDoc);
                this.general
                    .put("about-chhms", this.addDoc, this.id)
                    .subscribe({
                        complete: () => {},
                        error: (error) => {},
                        next: (data: any) => {
                            //console.log(data);
                        },
                    });
            },
        });
    }
}
