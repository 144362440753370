<div class="page-title-area page-title-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Contact Us</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Contact us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="location-area">
    <div class="container">
        <div class="row location-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-location-pin"></i>
                    <h3>Location</h3>
                    <p>2108-267 Road Quadra,Toronto, Victiria Canada</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-message"></i>
                    <h3>Email</h3>
                    <ul>
                        <li>hello@opvix.com</li>
                        <li>emailexample@name.com</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-call"></i>
                    <h3>Phone</h3>
                    <ul>
                        <li>+07 5554 3332 322</li>
                        <li>+07 5554 3332 322</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="drop-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 p-0">
                <div class="drop-item drop-img">
                    <div class="drop-left">
                        <h2>Drop your message for any info or question.</h2>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" placeholder="Name">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="email" name="email" class="form-control" placeholder="Email">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" class="form-control" placeholder="Phone">
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" class="form-control" placeholder="Subject">
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-md-12 col-lg-12">
                                    <button type="submit" class="drop-btn">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div class="speciality-item speciality-right speciality-right-two speciality-right-three">
                    <img src="assets/img/home-two/bg4.jpg" alt="Contact">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-map-area">
    <div id="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.72810813924!2d-0.24168018863114898!3d51.528771840455114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sbd!4v1589770854202!5m2!1sen!2sbd"></iframe>
    </div>
</div>