<app-page-title1 pageTitle="About Us Editor"></app-page-title1>
<app-admin-nav></app-admin-nav>
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="signup-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    Title
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Page Title"
                                        [(ngModel)]="addDoc.data.title"
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>
                            <div class="col-lg-8">
                                Description
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="addDoc.data.aboutData"
                                        [ngModelOptions]="{ standalone: true }"
                                    ></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row">
                                    <div class="col-lg-12">
                                        Image:<br />
                                        <input
                                            type="file"
                                            name="files"
                                            accept="image/*"
                                            #file
                                            placeholder="Choose file"
                                            (change)="selectbigImg($event)"
                                            style="display: none"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-warning"
                                            (click)="file.click()"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                    <div class="col-lg-12">
                                        <span
                                            class="upload"
                                            *ngIf="progress > 0"
                                        >
                                            {{ progress }}%
                                        </span>
                                        <span class="upload" *ngIf="message">
                                            {{ message }}
                                        </span>
                                    </div>
                                    <div class="col-lg-12">
                                        <img
                                            src=""
                                            alt="Big Image"
                                            id="img_big"
                                        />

                                        <img
                                            [src]="url + addDoc.data.aboutImage"
                                            alt="Big Image"
                                            id="img_big"
                                        />
                                    </div>
                                    <div class="col-lg-12">
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            (click)="remove()"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                Content
                                <div class="form-group">
                                    <ckeditor
                                        data="<p>Hello, world!</p>"
                                        [(ngModel)]="
                                            addDoc.data.visionMissionData
                                        "
                                        [ngModelOptions]="{ standalone: true }"
                                    >
                                    </ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row">
                                    <div class="col-lg-12">
                                        Image small:<br />
                                        <input
                                            type="file"
                                            name="files"
                                            accept="image/*"
                                            #file2
                                            placeholder="Choose file"
                                            (change)="selectsmallImg($event)"
                                            style="display: none"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-warning"
                                            (click)="file2.click()"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                    <div class="col-lg-12">
                                        <span
                                            class="upload"
                                            *ngIf="progress > 0"
                                        >
                                            {{ progress }}%
                                        </span>
                                        <span class="upload" *ngIf="message">
                                            {{ message }}
                                        </span>
                                    </div>
                                    <div class="col-lg-12">
                                        <img
                                            src=""
                                            alt="Big Image"
                                            id="img_small"
                                        />

                                        <img
                                            [src]="
                                                url +
                                                addDoc.data.visionMissionImage
                                            "
                                            alt="Big Image"
                                            id="img_small"
                                        />
                                    </div>
                                    <div class="col-lg-12">
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            (click)="removesmall()"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12"><br /></div>

                            <div class="col-lg-12"><br /><br /><br /></div>
                            <div class="col-lg-12">
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                        (click)="startUpload()"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="text-center"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
