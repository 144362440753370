<div class="page-title-area page-title-four ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Advance Directives</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Patient & Visitors Guide</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="hospital-area pb-70">
    <div class="container-fluid p-0">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape">
        </div>

        <div class="row m-0 align-items-center">


            <div class="col-lg-6 offset-lg-3">
                <br />
                <div class="hospital-item" [innerHtml]="description">

                </div>


                <!--div class="hospital-item ">
                    <br />
             
                    <p>
                        To preserve the patient's dignity and rights, Chong Hua Hospital has made available to patients
                        who choose to sign an
                        advance directive or living will, legal documents which will direct the attending physician and
                        CHH staff regarding your
                        preferred intensity of care, including orders regarding life-sustaining treatment.
                    </p>

                </div-->
            </div>
        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <!--div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Speciality</span>
                        <h2>Our Expertise</h2>
                        <p>World class, state-of-the-art facilities, paired with an efficient and compassionate medical
                            staff dedicated to bringing
                            you a comprehensive, cost-efficient patient experience designed for your specific needs.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</section>