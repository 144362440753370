<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Approver</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>About</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<app-admin-nav></app-admin-nav>
<hr />
<approver-nav></approver-nav>
